import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { useEffect, useState } from 'react';

// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import StepperBottomRow from '../onboard/StepperBottomRow';
import DocPreview, { LoadingPreview } from '../../components/_dashboard/user/profile/doc-templates/DocPreview';
import { fileUpload } from '../../socket';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));


const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
        color:'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
        color:'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
        color:'black',
    },
  },

  '& .MuiInputBase-input': {
    color: 'black',

  },
});



// ----------------------------------------------------------------------

export default function VerifyCode({handleCards, toast, jobdata, handleNext, handleExtras, nextSlide, updateToken, heightsChecked, handleEWPCard, stepperHandlers}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = React.useRef(null);
  let [name, setName] = useState('');
  let [issue_date, setIssueDate] = useState('');
  let [expiry_date, setExpiryDate] = useState('');
  let [type, setType] = useState('');
  let [license_number, setLicenseNumber] = useState('');
  const [showError, setShowError] = useState(false);
  let [cards, setCards] = useState([]);
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);



  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id){
      setCards(jobdata.ewpCards);
    }

  },[]);

  const handleSubmit = e=>{

    if(cards.length>0){
      handleCards(cards);
    }else{
      toast('warning', 'Please add at least one card!')
    }
  }

  const addCard = e=>{
    if(expiry_date){
	  if(!file){
		  toast('warning','please select a valid document copy!');
		  return;
	  }
		// if(issue_date=='Invalid Date'){
		// 	toast('warning', 'Please select a valid issue date');
		// 	return;
		// }
		if(expiry_date=='Invalid Date'){
			toast('warning', 'Please select a valid expiry date');
			return;
		}
      let newCard = {
        // name,
        // type,
        // license_number,
        file: file,
        // issue_date: new Date(issue_date).toISOString().split('T')[0],
        expiry_date: new Date(expiry_date).toISOString().split('T')[0],
      }
      let newCards = [...cards, newCard];
      setCards(newCards);
      handleEWPCard(newCard);
      if(heightsChecked){
        nextSlide();
      }else{

        if(jobdata.isEmployee || jobdata.isSupervisor){
          // its the last slide for employee
          handleNext();
          updateToken({
            isBuilder: jobdata.isBuilder,
            isSubcontractor: jobdata.isSubcontractor,
            isEmployee: jobdata.isEmployee,
            isSupervisor: jobdata.isSupervisor
          })
        }

        // if(jobdata.isSubcontractor){
        //   handleExtras()
        // }

      }
      setType('');
      setName('');
      setLicenseNumber('');
      // setRtoNumber('');
      setExpiryDate('');
      setIssueDate('');
    }else{
      if(jobdata.ewpCards.length==0){
        toast('warning', 'Please fill all the fields!');
		setShowError(true);
      }else{
        if(heightsChecked){
          nextSlide();
        }else{

          if(jobdata.isEmployee || jobdata.isSupervisor){
            // its the last slide for employee
            handleNext();
            updateToken({
              isBuilder: jobdata.isBuilder,
              isSubcontractor: jobdata.isSubcontractor,
              isEmployee: jobdata.isEmployee,
              isSupervisor: jobdata.isSupervisor
            })
          }

          // if(jobdata.isSubcontractor){
          //   handleExtras()
          // }

        }
      }
    }

  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }

  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }

  return (
    <div>
            <Typography variant="h4" sx={{ mb: 3 }}>
             Add your EWP card
            </Typography>
            {
              cards.length>0?(
                <Typography sx={{ color: 'text.secondary', mb: 4 }}>
                  {cards.length} card added
                </Typography>
              ):null
            }

      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>

          <Paper
            sx={{
             
              width: 1,
              position: 'relative',
              
            }}
            
          >

        <Grid container sx={{ width: '100%', padding:'10px' }}>
          <Grid item xs={2} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/EWPA-LOGO-${isLight ? 'light' : 'dark'}.png`}

            />
          </Grid>

          <Grid item>
             <Typography sx={{ marginTop:'6px', marginLeft:'10px' }}>EWP OPERATOR LICENCE</Typography>
                
              <Box sx={{ mt:4, ml:2 }} >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker inputFormat="dd-MM-yyyy" 
                      openTo="day"
                      views={['day','month','year']}
                      // label="Year, month and date"
                      label="Expiry Date"
                      value={expiry_date}
                      onChange={(newValue) => {
                      setExpiryDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>
              </Box>
                  
          </Grid>
        </Grid>
          </Paper>





      </Stack>
      <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />
        <Grid container spacing={1}>

            <Grid item xs={12} >
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                {
                    fileLoading?(
                      <LoadingPreview />
                    ):null
                  }
                  {
                    file?(
                      <DocPreview fileLoading={fileLoading} file={file} />
                    ):null
                  }
                <Button onClick={handleFile} fullWidth size="large" variant="outlined" sx={{ mt: 1 }}>Upload EWP Card</Button>
              </Box>
            </Grid>

          </Grid>

      {/* <Box sx={{ mt: 3 }}>
        <Button size="small" onClick={addCard} startIcon={<Icon icon={plusFill} />} >
          Save card
        </Button>
      </Box> */}
        <StepperBottomRow {...stepperHandlers} handleSubmit={addCard} />
    </div>
  );
}
