import React from 'react';
import { useEffect } from 'react';
import {
    Card,
    Stack,
    Box,
    Typography,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    Dialog,
    TextField,
    Button,
    FormControl, MenuItem,Select, InputLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import closeFill from '@iconify/icons-eva/close-fill';
import CheckoutForm from './checkoutForm';
const MembershipModal = ({handleStartMembership, open, loading, toggleModal}) => {
  
    return (
    <React.Fragment>
        <Dialog  open={open} onClose={()=> toggleModal(false)} >
            <DialogTitle>Mange your membership</DialogTitle>
            <DialogContent>
                {/* <DialogContentText sx={{ mb:2 }}>
                Manage your membership details
                </DialogContentText> */}
                You currently don't have any active memberhsip, please start your memberhsip to continue using the site!
                
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={()=>}>Cancel</Button> */}
                <LoadingButton onClick={handleStartMembership} type="button" color="primary" loading={loading}>
                    Start membership
                </LoadingButton>
            </DialogActions>
        </Dialog> 
    </React.Fragment>
    );
};

export default MembershipModal;