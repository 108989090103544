import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { useEffect, useState } from 'react';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import StepperBottomRow from '../onboard/StepperBottomRow';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));


const tradingSection = {
  display: 'flex',
}
const tradingStyle={
  width: '80%',
  marginRight: '5px'
}
// ----------------------------------------------------------------------

export default function VerifyCode({handleCompany, jobdata, handleNext, toast, stepperHandlers}) {
  let [companyName, setCompanyName] = useState('');
  let [tradingName, setTradingName] = useState('');
  const [showError, setShowError] = useState(false);


  // useEffect(()=>{
  //   // console.log('got company jobdata: ',jobdata);
  //   if(jobdata && jobdata.id){
  //     if(jobdata.company_name) setCompanyName(jobdata.company_name);
  //     if(jobdata.trading_name) setTradingName(jobdata.trading_name);
  //   }
  // },[]);
  useEffect(()=>{
    // console.log('got company jobdata: ',jobdata);
    if(jobdata && jobdata.id){
      if(jobdata.company_name) setCompanyName(jobdata.company_name);
      if(jobdata.trading_name) setTradingName(jobdata.trading_name);
    }
  },[jobdata]);

  const handleSubmit = e=>{
    if(companyName.trim() && tradingName.trim()){
      let company_name = companyName.trim();
      let trading_name = tradingName.trim();
      handleCompany({company_name, trading_name})
    }else{
	  setShowError(true);
      toast('warning','please fill all fields!');
    }

  }

  const handleTradingName = e=>{
    if(jobdata && jobdata.id && jobdata.company_name){
      handleCompany({
        company_name: jobdata.company_name,
        trading_name: jobdata.company_name
      });
      setTradingName(jobdata.company_name);
    }
  }
  return (
    <div>
            <Typography variant="h4" gutterBottom>
             What is your company name?
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 4 }}>Enter your registered company name and trading name.</Typography>
            <TextField sx={{ mb: 2 }}
              fullWidth
              value={companyName}
              autoComplete="company"
              error={showError && !companyName}
              onChange={(e)=> setCompanyName(e.target.value)}
              type="text"
              label="Company Name"
            />
            {
              jobdata && jobdata.id && jobdata.trading_name?(
                <TextField
                fullWidth
                value={tradingName}
              	error={showError && !tradingName}
                autoComplete="tradingname"
                onChange={(e)=> setTradingName(e.target.value)}
                type="text"
                label="Trading Name"
              />
              ):(
                <div style={tradingSection}>
                  <TextField
                    style={tradingStyle}
                    value={tradingName}
              		error={showError && !tradingName}
                    autoComplete="tradingname"
                    onChange={(e)=> setTradingName(e.target.value)}
                    type="text"
                    label="Trading Name"
                  />
                  <Button onClick={handleTradingName}>As above</Button>
                </div>
              )
            }


            <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
    </div>
  );
}
