import React from 'react';
import { useEffect } from 'react';
import {
    Card,
    Stack,
    Box,
    Typography,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    Dialog,
    TextField,
    Button,
    FormControl, MenuItem,Select, InputLabel
} from '@material-ui/core';
import closeFill from '@iconify/icons-eva/close-fill';
const JobsiteJoinModal = ({notification, open, toggleModal, }) => {
    if(!notification) notification={}
    return (
    <React.Fragment>
        <Dialog  open={open} onClose={()=> toggleModal(false)} >
            <DialogTitle>Job Site Invitation</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb:2 }}>
                    {notification.first_name + ' ' + notification.last_name} invited you to a job site
                </DialogContentText>
                Accept the swms document before you join the job site
            </DialogContent>
            <DialogActions>
                <Button onClick={()=> toggleModal(false)}>Cancel</Button>
                <Button type="submit">Join</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
    );
};

export default JobsiteJoinModal;