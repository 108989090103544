import React from 'react';
import { useEffect } from 'react';
import {
    Card,
    Stack,
    Box,
    Typography,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    Dialog,
    TextField,
    Button,
    FormControl, MenuItem,Select, InputLabel
} from '@material-ui/core';
import closeFill from '@iconify/icons-eva/close-fill';
import CheckoutForm from './checkoutForm';
const CardModal = ({handleToken, open, toggleModal}) => {
  
    return (
    <React.Fragment>
        <Dialog  open={open} onClose={()=> toggleModal(false)} >
            <DialogTitle>Payment Information</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb:2 }}>
                Let us know your credit/debit card information
                </DialogContentText>
                
                <CheckoutForm handleToken={handleToken} />
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Send Invite</Button>
            </DialogActions> */}
        </Dialog> 
    </React.Fragment>
    );
};

export default CardModal;