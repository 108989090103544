import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListSubheader, ListItemButton } from '@material-ui/core';
import { login, loginOff, loginOk, unreadMessagesCount } from '../socket';
import Badge from '@mui/material/Badge';
// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -8,
    // top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
  },
}));

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object
};

function NavItem({ item, active, isShow, messageCount }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  // console.log('path active: ',item.path, isActiveRoot);
  const { title, path, icon, info, children } = item;
  
  const [open, setOpen] = useState(isActiveRoot);
  // console.log('got nav item: ',item);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
       
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>
              {icon && icon}
          </ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>
      
        

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main'
                          })
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  if(title=='messages'){
    return (
      
            <ListItemStyle
              component={RouterLink}
              to={path}
              sx={{
                ...(isActiveRoot && activeRootStyle)
              }}
            >
              <ListItemIconStyle>
                {icon && icon}
              </ListItemIconStyle>
              {isShow && (
                <>
                  <StyledBadge badgeContent={messageCount} color="primary">
                    <ListItemText disableTypography primary={title} />
                    {info && info}
                  </StyledBadge>
                 
                </>
              )}
            </ListItemStyle>     
      );
  }else{
    return (
      <ListItemStyle
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle)
        }}
      >
        <ListItemIconStyle>
          {icon && icon}
        </ListItemIconStyle>
        {isShow && (
          <>
            <ListItemText disableTypography primary={title} />
            {info && info}
          </>
        )}
      </ListItemStyle>     
    )
  }
  
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, menu, isShow = true, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, exact: true, end: false }, pathname) : false);
  // console.log('got pathname: ',pathname);
  const { subheader, items } = menu;
  // console.log('here is the menu: ',menu);
  var [messageCount, setCount] = useState(0);
  useEffect(()=>{
    login(processLogin)
    unreadMessagesCount(processUnreadCount);
    processLogin(loginOk());

    return ()=>{
     
      loginOff(processLogin);
    }
  },[])

  const processLogin = (login)=>{
		//console.log("login",login)
		if(login.auth){
      unreadMessagesCount();
		}
	}

  const processUnreadCount = data=>{
    // console.log('got unread count: ',data);
    setCount(data.count);
  }


  if(menu){
    return (
      <Box {...other}>
        {/* {navConfig.map((list) => {
          const { subheader, items } = list;
          return (
            <List key={subheader} disablePadding>
              {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
              {items.map((item) => (
                <NavItem key={item.title} item={item} active={match} isShow={isShow} />
              ))}
            </List>
          );
        })} */}
        
        <List key={subheader} disablePadding>
          {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
          {items.map((item) => (
            <NavItem messageCount={messageCount}  key={item.title} item={item} active={match} isShow={isShow} />
          ))}
        </List>
          
      </Box>
    );
  }else{
    return (
      <Box {...other}>
          
      </Box>
    );
  }
  
}
