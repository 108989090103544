import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Button, Typography, Box, Stack } from '@material-ui/core';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
//
import LoadingButton from '@mui/lab/LoadingButton';

import Label from '../../Label';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5)
  }
}));

// ----------------------------------------------------------------------

export default function PriceCardSwms(props) {
  const { subscription, icon, price, caption, role, lists, labelAction, monthly, handleSubmit, loading } = props;

  return (
    <RootStyle>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            $
        </Typography>

        <Typography variant="h2" sx={{ mx: 1 }}>
          {price === 0 ? 'Free' : price}
        </Typography>

        <Typography
        gutterBottom
        component="span"
        variant="subtitle2"
        sx={{
            alignSelf: 'flex-end',
            color: 'text.secondary'
        }}
        >
        {monthly? '/month' : '/year'}
        </Typography>
        
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
          mb:3
        }}
      >
        {caption}
      </Typography>

      {/* <Box sx={{ width: 80, height: 80, mt: 3 }}>{icon}</Box>

      <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{ typography: 'body2', color: item.isAvailable ? 'text.primary' : 'text.disabled' }}
          >
            <Box component={Icon} icon={checkmarkFill} sx={{ width: 20, height: 20 }} />
            <Typography variant="body2">{item.text}</Typography>
          </Stack>
        ))}
      </Stack> */}

        <LoadingButton
            fullWidth
            loading={loading}
            size="large"
            onClick={()=> handleSubmit({monthly})}
            variant="outlined"
        >
            {labelAction}
        </LoadingButton>

      
    </RootStyle>
  );
}
