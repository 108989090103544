import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useState, useEffect, useRef } from 'react';

// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleTradeContract, jobdata, loading, toast}) {
    
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const [value, setValue] = React.useState(null);
  
  const fileInputRef = useRef(null);

  let [provider, setProvider] = useState('');
  let [expiryDate, setExpiryDate] = useState('');
  let [policyNumber, setPolicyNumber] = useState('');
  let [file, setFile] = useState('');
  

  // let [cards, setCards] = useState([]);
  
  

  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id && jobdata.tradeContract){
      if(jobdata.tradeContract.provider) setProvider(jobdata.tradeContract.provider)
      if(jobdata.tradeContract.expiry_date) setExpiryDate(new Date(jobdata.tradeContract.expiry_date))
      if(jobdata.tradeContract.policy_number) setPolicyNumber(jobdata.tradeContract.policy_number)
    }
    
  },[]);

  // const handleSubmit = e=>{
    
  //   if(cards.length>0){
  //     handleLicenses(cards);
  //   }else{
  //     toast('warning', 'Please add at least one card!')
  //   }
  // }

  const handleSubmit = e=>{
    if(provider && expiryDate && policyNumber && file){
      let tradeContract = {
        provider, 
        expiry_date: new Date(expiryDate).toISOString().split('T')[0],
        policy_number: policyNumber, 
        file: file,
        fileName: file.name
      };
      handleTradeContract(tradeContract);
      
    }else{
      toast('warning', 'Please fill all the fields!')
    }
    
  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    setFile(file);
    // console.log('selected file: ',file);
  }

  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }
  return (
    <div>
            <Typography variant="h4" sx={{ mb: 3 }}>
             Complete your Period Trade Contract 
            </Typography>

            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
              <Paper
                sx={{
                  p: 2,
                  width: 1,
                  position: 'relative',
                  border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
                }}
                style={{paddingRight: '40px'}}
              >

                <Grid container spacing={3} sx={{ mb: 2 }}>
                  <Grid item xs={8} >
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                    >
                      <TextField id="b-card-name" value={provider} onChange={e=>setProvider(e.target.value)} label="Insurance Provider" variant="outlined" />
                    </Box>
                    

                    
                  
                  </Grid>

                  <Grid item xs={4} >
                    <Box
                        component="img"
                        alt="icon"
                        src={`/static/icons/PLI-${isLight ? 'light' : 'dark'}.png`}
                        
                    />


                  </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ mb: 2 }}>
                  <Grid item xs={6} >
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                    >
                      <TextField value={policyNumber} onChange={e=>setPolicyNumber(e.target.value)} id="qbcc-n" label="Policy Number" variant="outlined" />
                    </Box>
                  </Grid>

                  <Grid item xs={6} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker inputFormat="dd-MM-yyyy"  
                        label="Expiry Date"
                        value={expiryDate}
                        onChange={(newValue) => {
                          setExpiryDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>

                    </Grid>
                </Grid>
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={handleSelededFile}
                  // accept="image/*,video/*"
                  name="profilePic"
                  ref={fileInputRef}
                  required
                  style={{display:'none'}}
                />
                
                <Grid container spacing={1}>
                
              <Grid item xs={12} >
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  {
                    file && file.name?(
                      <Typography sx={{ color: 'text.secondary', mb: 4 }}>
                        {file.name}
                      </Typography>
                    ):null
                  }
                  <Button onClick={handleFile} fullWidth size="large" variant="outlined" sx={{ mt: 4 }}>Upload Copy</Button>
                </Box>
              </Grid>

            </Grid>
              
                <Typography variant="subtitle2"> </Typography>
              </Paper>
            
            </Stack>
<Box sx={{ pl: 2 , pr: 2}}>
      {
        loading?(
          <Button fullWidth size="large"  variant="contained" sx={{ mt: 6 }}>please wait...</Button>
        ):(
          <Button fullWidth size="large" onClick={handleSubmit} variant="contained" sx={{ mt: 6 }}>Next</Button>
        )
      }
</Box>
    </div>
  );
}
