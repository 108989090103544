import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import AdapterDateFns from '@mui/x-date-pickers/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import LightboxModal from '../../../../../components/LightboxModal';

import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

// components
import Page from '../../../../../components/Page';
import { fData } from '../../../../../utils/formatNumber';
import { doc400 } from '../../../../userImage';
import useSettings from '../../../../../hooks/useSettings';
import DocPreview, { LoadingPreview } from './DocPreview';
import { fileUpload } from '../../../../../socket';
import { FDate } from 'src/constants/defaultValues';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));



// ----------------------------------------------------------------------
// console.log('lib: ',pdfJS);
export default function VerifyCode({doc, toast, isMobile, editable, handleUpdateDoc, handleAddDoc, loading}) {
  const theme = useTheme();
  const canRef = React.useRef();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = React.useRef(null);
  var cardRef = React.useRef();
  let [name, setName] = useState('');
  let [dob, setDob] = useState('');

  let [rtoNumber, setRtoNumber] = useState('');
  let [issueDate, setIssueDate] = useState('');
  let [cardNmber, setCardNumber] = useState('');
  let   [currentFile, setCurrentFile] = useState(null);
  let   [fileOpen, setFileOpen] = useState(false);
  let [cards, setCards] = useState([]);
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);


  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(doc && doc.id){
        setName(doc.name);
        setDob(doc.dob);
        setIssueDate(doc.issue_date);
        setRtoNumber(doc.rto_number);
        setCardNumber(doc.card_number);
    }

  },[doc]);

  const handleSubmit = e=>{

    // if(name && rtoNumber && issueDate && cardNmber){
        // let {id, userId, docType} = doc;
		// console.log('issue date: ',issueDate);
		// if(issueDate=='Invalid Date'){
		// 	toast('warning', 'Please select a valid issue date');
		// 	return;
		// }
        let newCard = {
//             name,
// //             dob: new Date(dob).toISOString().split('T')[0],
//             rto_number: rtoNumber,
//             issue_date: new Date(issueDate).toISOString().split('T')[0],
//             card_number: cardNmber,
            // id, userId, docType
        }
		console.log('new card: ',newCard);
		// return;
        if(file){
          newCard.file = file;
        }

        // setDob('');
        // setName('');
        // setCardNumber('');
        // setRtoNumber('');
        // setIssueDate('');
        
        if(!newCard.file){
          toast('warning',"Please upload the copy of the card!");
          return;
        }

        if(doc && doc.id){
          handleUpdateDoc(newCard);
        }else{
          handleAddDoc(newCard);
        }
    // }else{
    //     toast('warning', 'please fill all the fields!');
    // }


  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    console.log('file: ',file);
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');

  }
  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }

  const handleViewDoc = doc=>{
    setCurrentFile(doc.file);
    setFileOpen(true);
  }

  // console.log('window object: ',window);
  const stackStyle = {};
  if(isMobile){
    stackStyle.maxHeight = '90vh';
    stackStyle.overflowY = 'auto';
  }

  return (
      <div style={stackStyle} >
            {/* <Typography variant="h4" sx={{ mb: 3 }}>
             Add your white/blue card
            </Typography>
            {
              cards.length>0?(
                <Typography sx={{ color: 'text.secondary', mb: 4 }}>
                  {cards.length} card added
                </Typography>
              ):null
            } */}

      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
              p: 2,
              width: 1,
              position: 'relative',
			  color:isLight?'black': 'white',
			  background:theme.palette.background.paper
            }}
          >

        <Grid container spacing={3} >
          <Grid item xs={5} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/QLD-logo-${isLight?'light':'dark'}.png`}
            />
          </Grid>

          <Grid item xs={7} >
             <Typography >Blue Card</Typography>
          </Grid>
        </Grid>


        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />
        {
          editable?(
            <Grid container spacing={1}>

            <Grid item xs={12} >
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                {/* {
                  file && file.name?(
                    <Box sx={{my:2}}>
                      <img style={{borderRadius: '5px'}} src={URL.createObjectURL(file)} />
                    </Box>
                  ):null
                } */}
                {
                  fileLoading?(
                    <LoadingPreview />
                  ):null
                }
                {
                  file?(
                    <DocPreview fileLoading={fileLoading} file={file} />
                  ):null
                }

                <Button onClick={handleFile} fullWidth size="large" variant="outlined">Upload White/Blue Card</Button>
              </Box>
            </Grid>

          </Grid>
          ):null
        }



          </Paper>



      </Stack>
        {/* {
            editable?(
              <LoadingButton fullWidth variant="contained" loading={loading} sx={{mt:2}} onClick={handleSubmit}> {handleUpdateDoc? 'Update':'Add'} </LoadingButton>
            ):
            doc.file?(
              <Box sx={{my:1}}>
                <img style={{borderRadius: '5px'}} src={doc400(doc.file)} />
              </Box>

            ):(
              <Typography sx={{ml:1}}>Copy not provided </Typography>
            )
        } */}
        {
            editable?(
              <LoadingButton fullWidth variant="contained" loading={loading} sx={{mt:2}} onClick={handleSubmit}> {handleUpdateDoc? 'Update':'Add'} </LoadingButton>
            ): <DocPreview file={doc.file} />
        }

    </div>
  );
}
