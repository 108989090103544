import React from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material

import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField, Grid, useMediaQuery, Modal } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import { useEffect, useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import {periodTradeContract} from '../../constants/defaultValues';
import StepperBottomRow from '../onboard/StepperBottomRow';

export default function Phone({ stepperHandlers, handlePhone}){
    let [phone, setPhone] = useState('');
    const handleSubmit = ()=>{
        handlePhone(phone);
    }
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          What is your phone number?
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 4 }}>Enter your details below.</Typography>
          <TextField
            fullWidth
            id="phoneNumber"
            autoComplete="phone"
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            type="number"
            label="Mobile Number"
          />
          <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
                          
      </div>
    )
                      
                          
                        
  }
  