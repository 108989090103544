// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_STORE = '/store';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  TermsOfService: '/TermsOfService',
  PrivacyPolicy : '/PrivacyPolicy',
  contact: '/contact-us',
  faqs: '/faqs',
  timeline: '/timeline',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  documentsIndex: path(ROOTS_DASHBOARD, '/documents'),
  ptcDocument: path(ROOTS_DASHBOARD, '/documents/ptc'),
  oldPtcDocument: path(ROOTS_DASHBOARD, '/documents/old/ptc'),
  whsDocument: path(ROOTS_DASHBOARD, '/documents/whs'),
  oldWhsDocument: path(ROOTS_DASHBOARD, '/documents/old/whs'),
  wirDocument: path(ROOTS_DASHBOARD, '/documents/wir'),
  oldWirDocument: path(ROOTS_DASHBOARD, '/documents/old/wir'),
  swmsDocument:path(ROOTS_DASHBOARD, '/documents/swms'),
  hazardousSubstances:path(ROOTS_DASHBOARD, '/documents/hazardousSubstances'),
  termsAndConditions: path(ROOTS_DASHBOARD, '/documents/termsAndConditions'),
  jobsiteSwms:path(ROOTS_DASHBOARD, '/jobsiteSWMS'),
  constructionTypes:path(ROOTS_DASHBOARD, '/jobsiteSWMS/constructionTypes'),
  poolTypes:path(ROOTS_DASHBOARD, '/jobsiteSWMS/poolTypes'),
  docVerification:path(ROOTS_DASHBOARD, '/verification'),
  verifyCards:path(ROOTS_DASHBOARD, '/verification/cards'),
  verifyLicenses:path(ROOTS_DASHBOARD, '/verification/licenses'),
  verifyInsurances:path(ROOTS_DASHBOARD, '/verification/insurances'),
  verifyWorkcovers:path(ROOTS_DASHBOARD, '/verification/workCovers'),
  verifyEwpcards:path(ROOTS_DASHBOARD, '/verification/ewpCards'),
  verifyWorkingheightscards:path(ROOTS_DASHBOARD, '/verification/workingHeightsCards'),
  verifyTesttag:path(ROOTS_DASHBOARD, '/verification/testTag'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    list2: path(ROOTS_DASHBOARD, '/user/list'),
    list3: path(ROOTS_DASHBOARD, '/user/list'),
    yourteam: path(ROOTS_DASHBOARD, '/user/yourteam'),
    yourbuilders: path(ROOTS_DASHBOARD, '/user/yourbuilders'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    jobsites: path(ROOTS_DASHBOARD, '/jobsites'),
    addjobsite: path(ROOTS_DASHBOARD, '/user/addjobsite'),
    jobdetails: path(ROOTS_DASHBOARD, '/user/jobdetails'),
    jobdetailssubcontractor: path(ROOTS_DASHBOARD, '/user/jobdetails')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  dash: {
    officeadmin: path(ROOTS_DASHBOARD, '/officeadmin'),
    admin: path(ROOTS_DASHBOARD, '/GeneralDashAdmin'),
    builderDash: path(ROOTS_DASHBOARD, '/dashBuilder'),
    checklist: path(ROOTS_DASHBOARD, '/checklist'),
    builder: path(ROOTS_DASHBOARD, '/builder'),
    employee: path(ROOTS_DASHBOARD, '/employee'),
    subcontractor: path(ROOTS_DASHBOARD, '/subcontractor'),
    supervisor: path(ROOTS_DASHBOARD, '/supervisor'),
    storeAdmin: path(ROOTS_DASHBOARD, '/storeAdmin'),
    storeAdminProducts: path(ROOTS_STORE, '/products'),
    storeAdminClaims: path(ROOTS_STORE, '/claims'),
    storeAdminSettings: path(ROOTS_STORE, '/settings'),

  }
};

export const PATH_DOCS = '/auth/login';
