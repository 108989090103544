import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import {useNavigate} from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { useTheme } from '@material-ui/core/styles';

// material
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  TextField,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  useMediaQuery,
  ListItemButton
} from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack5';
import { MIconButton } from '../../components/@material-extend';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import JobsiteJoinModal from './JobsiteJoinModal';
import JobsiteSupervisorDocs from './jobsiteSupervisorDocs';
// utils
import mockData from '../../utils/mock-data';
import { fToNow } from '../../utils/formatTime';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { userAvatar, userAvatar100 } from '../../components/userImage';
import BlueCard from '../../components/_dashboard/user/profile/doc-templates/BlueCard';
import PublicLiabilityInsurance from '../../components/_dashboard/user/profile/doc-templates/PublicLiablityInsurance';
import QBCClicense from '../../components/_dashboard/user/profile/doc-templates/QBCCLicense';
import WorkCover from '../../components/_dashboard/user/profile/doc-templates/WorkCover';
import EWPLicense from '../../components/_dashboard/user/profile/doc-templates/EWPLicense';
import WorkingHeightsLicense from '../../components/_dashboard/user/profile/doc-templates/WorkingHeightsLicense';
import TestAndTag from '../../components/_dashboard/user/profile/doc-templates/TestAndTag';
import { login, loginOk, loginOff, notifications as getNotifications, notifications, updateNotificationCount, markAsRead, getSafetyDoc, verifyDoc, declineCard, getUserDocs, jobsiteDocs, updateSupervisorDocAccept, acceptJoinTeam } from '../../socket';
import { userRoleNames } from 'src/constants/defaultValues';
import { PATH_DASHBOARD } from 'src/routes/paths';
// ----------------------------------------------------------------------
const docTemplates = {
  cards: (props) => <BlueCard {...props} />,
  licenses: props=> <QBCClicense {...props} />,
  workCovers: props=> <WorkCover {...props} />,
  ewpCards: props=> <EWPLicense {...props} />,
  insurances: props=> <PublicLiabilityInsurance {...props} />,
  workingHeightsCards: props=> <WorkingHeightsLicense {...props} />,
  testTag: props=> <TestAndTag {...props} />,
}

const StyledBox = styled('div')(({ theme }) => {
  // console.log('them.pallete: ',theme.palette);
  return ({

    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    background: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: '24px',
    padding: '15px',
    borderRadius: '10px'
  })
});
const joinModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  borderRadius:1,
  boxShadow: 24,
  p: 3,
};

const TITLES = [
  'Your order is placed',
  'Sylvan King',
  'You have new message',
  'You have new mail',
  'Delivery processing'
];

const DESCRIPTIONS = [
  'waiting for shipping',
  'answered to your comment on the Minimal',
  '5 unread messages',
  'sent from Guido Padberg',
  'Your order is being shipped'
];
const modalStyleDesktop = {
  position: 'absolute',
  width: 'calc(100% - 20px)',
  height: 'calc(100% - 20px)',
  bgcolor: '#161C24',
  borderRadius:'10px',
  boxShadow: 24,
  padding: '30px',
  top: '10px',
  left: '10px',

};
const modalStyleMobile = {
  position: 'absolute',
  width: 'calc(100% - 0px)',
  height: 'calc(100% - 0px)',
  bgcolor: '#161C24',
  borderRadius:'0px',
  boxShadow: 24,
  padding: '0px',
  top: '0px',
  left: '0px',

};

const TYPES = ['order_placed', 'friend_interactive', 'chat_message', 'mail', 'order_shipped'];

const AVATARS = [null, mockData.image.avatar(2), null, null, null];

const UNREADS = [true, true, false, false, false];

const MOCK_NOTIFICATIONS = [...Array(5)].map((_, index) => ({
  id: mockData.id(index),
  title: TITLES[index],
  description: DESCRIPTIONS[index],
  avatar: AVATARS[index],
  type: TYPES[index],
  createdAt: mockData.time(index),
  isUnRead: UNREADS[index]
}));

// ----------------------------------------------------------------------


export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [jobsiteId, setJobsiteId] = useState(null);
  const [whsDoc, setWhsDoc] = useState(null);
  const [showAll, setShowall] = useState(false);
  const navigate = useNavigate();
  const [reason, setReason] = useState('');
  const [reasonBox, toggleReasonBox] = useState(false);
  let [loading, setLoading] = useState(false);
  let   [jobsiteOpen, toggleJobsiteModal] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const totalUnRead = notifications.filter((item) => item.seen == 0).length;
  const [currentDoc, setCurrentDoc] = useState(null);
  const [joinModal, toggleJoinModal] = useState(false);
  const [joinTeamModal, toggleJoinTeamModal] = useState(false);
  const [joinUser, setJoinUser] = useState(null);
  const [docModalOpen, toggleDocModal] = useState(false);
  const [supervisorDocsOpen, toggleSupervisorDocsModal] = useState(false);
  const closeDocModal = ()=> toggleDocModal(false);
  const openDocModal = ()=> toggleDocModal(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const toast = (type, message)=>{
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }

  useEffect(()=>{
    login(processLogin)

    getNotifications(processNotifications);
    updateNotificationCount(processNotificationCount);
    markAsRead(processMarkAsRead);
    acceptJoinTeam(processAcceptJoinTeam);


    processLogin(loginOk());

    return ()=>{
      loginOff(processLogin);
      acceptJoinTeam(processAcceptJoinTeam, 'off');
    }
  },[])

  const processLogin = (login)=>{
		//console.log("login",login)
		if(login.auth){
      getNotifications();
		}
	}

  const processAcceptJoinTeam = res=>{
    setLoading(false);
    if(res.success){
      toggleJoinModal(false);
      toggleJoinTeamModal(false);
      setJoinUser(null);
      toast('success', 'Team joined successfully');
      handleClose();
    }else{
      toast('warning', res.msg);
    }

  }

  const processNotificationCount = res=>{
    console.log('updated notifications count: ',res);
    // if(res.success){
    //   notifications.map(noti=>{ noti.seen = 1 });
    //   setNotifications([...notifications])
    // }
  }


  const processSafetyDoc = res=>{
    getSafetyDoc(processSafetyDoc, 'off');
    if(res.success){
      setCurrentDoc(res.data);
      openDocModal();
    }else{
      toast('warning', res.msg);
    }
  }
  const processNotifications = data=>{
    // console.log('got notifications: ',data);
    setNotifications(data);
  }
  const handleOpen = () => {
    setOpen(true);
    updateNotificationCount();
    notifications.map(item=> { item.seen = 1 });
    setNotifications([...notifications]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const processMarkAsRead = res=>{
    // console.log('markAsRead result: ',res);
  }



  const handleMarkAllAsRead = () => {
    var notificationIds = [];
    setNotifications(
      notifications.map((notification) => {
        notificationIds.push(notification.id);
        return ({
        ...notification,
        status: 1
        })}
      )
    );
    // console.log('ids: ',notificationIds);
    markAsRead({notificationIds})
  };
  const updateNotificationStatus = notificationId=>{
    notifications.map(n=>{
      if(n.id==notificationId) n.status = 1;
    });
    setNotifications([...notifications])
  }

  const processVerifyDoc = res=>{
    verifyDoc(processVerifyDoc, 'off');
    setLoading(false);
    // console.log('verifyDoc: ',res);
    if(res.success){
      toast('success', 'Verified successfully');
      closeDocModal();
      setCurrentDoc(null);
      // var docData = res.data;
      // // console.log('teams: ',tems[0]);
      // tems.map(team=>{
      //   if(team.id==docData.teamId){
      //     team && team.employees.map((emp,index)=>{
      //       if(emp.id==docData.userId){
      //         // console.log('found emp: ',emp, docData);
      //         if(emp.docs[realCardNames[docData.docType]]) {
      //           // console.log('found: ',emp);
      //           emp.docs[realCardNames[docData.docType]].data = {...docData};
      //         }
      //       }
      //     })
      //   }

      // })
      // setTeams([...tems]);
      // tems = [...tems];

    }else{
      toast('warning', res.msg);
    }
  }

  const processDeclineCard = res=>{
    declineCard(processDeclineCard, 'off');
    setLoading(false);
    console.log('decline card res: ',res);
    if(res.success){
      toggleReasonBox(false);
      setReason('');
      closeDocModal();
      setCurrentDoc(null);
      toast('success','Message sent to user');

    }else{
      toast('warning',res.msg);
    }
    }

  const handleNotificationClick = noti=>{
    // console.log('got notification: ',noti);
    if(noti.type=='jobsite'){
      // if(noti.status==0){ // show join job site modal
      //   // console.log('open job site modal');
      //   setSelectedNotification(noti);
      //   toggleJobsiteModal(true);
      // }else{
      //   toast('warning', "You already have joined this job site!")
      // }
    }else if(noti.type=='chat'){
      let data = JSON.parse(noti.data);
      markAsRead({notificationIds: [noti.id]});
      updateNotificationStatus(noti.id);
      // console.log('message data: ',data);
      if(data.roomId) {
        navigate(`/dashboard/chat/${data.roomId}`);
        handleClose();
      }
    }else if(noti.type=='docUpdate'){
      let data = JSON.parse(noti.data);
      getSafetyDoc({...data, userId: noti.senderId});
      getSafetyDoc(processSafetyDoc);
    }else if(noti.type=='jobsiteSupervisorDoc'){
      let jId = JSON.parse(noti.data).jobsiteId;
      setJobsiteId(jId);
      let userId = noti.senderId;
      toggleSupervisorDocsModal(true);
      jobsiteDocs(processJobsiteDocs);
      setLoading(true);
      jobsiteDocs({jobsiteId: jId});
    }else if(noti.type=='team'){
      setJoinUser({
        userId: noti.senderId, 
        name: noti.first_name+' '+noti.last_name, 
        roleId: noti.roleId, 
        picture: noti.picture
      });
      toggleJoinModal(true);
    }else if(noti.type=='teamMember'){
      setJoinUser({
        senderId: noti.senderId, 
        name: noti.first_name+' '+noti.last_name, 
        picture: noti.picture, 
        receiverId: noti.receiverId
      });
      toggleJoinTeamModal(true);
      // console.log('this is a join request');
    }
  }

  const openProfile = userId=>{
    toggleJoinModal(false);
    navigate(PATH_DASHBOARD.user.profile+'/'+userId)
  }

  const processJobsiteDocs = res=>{
    jobsiteDocs(processJobsiteDocs, 'off')
    console.log('got user docs: ',res);
    setLoading(false);
    if(res.success){
      if(res.data.wesafeDoc){
        setWhsDoc(res.data.wesafeDoc);
      }else if(res.data.customDoc){
        setWhsDoc(res.data.customDoc);
      }else{
        setWhsDoc([]);
      }
     
    }else{
      toast('warning', res.msg);
    }

  }

  const acceptVerification = currentDoc=>{
    verifyDoc(processVerifyDoc);
    verifyDoc(currentDoc);
    setLoading(true);
  }

  const handleDeclineCard = ()=>{
	// console.log('declining card: ',doc);
	if(currentDoc){
		if(reason.trim()!=""){
      declineCard(processDeclineCard);
			setLoading(true);
			declineCard({...currentDoc, reason});
		}else{
			toast('warning', 'Please Enter a valid reason!');
		}

	}


  }

  const handleSupervisorDocAccept = ()=>{
    console.log('jobsiteId: ',jobsiteId);
    updateSupervisorDocAccept(processUpdateSupervisorSignature);
    updateSupervisorDocAccept({jobsiteId});
    setLoading(true);
  }

  const processUpdateSupervisorSignature = res=>{
    setLoading(false);
    if(res.success){
        toast('success', 'successfully accepted the document');
        toggleSupervisorDocsModal(false);
    }else{
      toast('warning', res.msg);
    }
    // console.log('update supervisor doc accept: ',res);
  }

  const handleAcceptJoinRequest = e=>{
    // console.log('accept join request: ',joinUser)
    if(joinUser){
      acceptJoinTeam(joinUser);
      setLoading(true);
    }else{
      toast('warning', 'Something went wrong! please refresh the page');
    }
  }
  const handleAcceptTeamJoinRequest = e=>{
    // console.log('accept join request: ',joinUser)
    if(joinUser){
      acceptJoinTeam({...joinUser, employerRequest: true});
      setLoading(true);
    }else{
      toast('warning', 'Something went wrong! please refresh the page');
    }
  }


  let oldNotifications = notifications.filter(item=> item.status==1);
  let newNotifications = notifications.filter(item=> item.status==0);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {newNotifications.length} unread notifications
            </Typography>
          </Box>

          {newNotifications.length > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: 'auto' }} >
          {
            newNotifications.length>0?(
              <List
                disablePadding
                subheader={
                  <ListSubheader  sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    New
                  </ListSubheader>
                }
              >
                {/* {
                  <Scrollbar sx={{ height: { xs: 340, sm: 'auto' }}}> */}
                    {
                      newNotifications.map((notification) => (
                        <NotificationItem key={notification.id} handleClick={handleNotificationClick} notification={notification} />
                      ))
                    }

                  {/* </Scrollbar>
                } */}

              </List>
            ):null
          }


          {
            oldNotifications.length==0? null: (
              <List
                disablePadding
                subheader={
                  <ListSubheader  sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    Old
                  </ListSubheader>
                }
              >
                {
                  !showAll? (
                    <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                      {
                        oldNotifications.slice(0,5).map((notification) => (
                          <NotificationItem key={notification.id} handleClick={handleNotificationClick} notification={notification} />
                        ))
                      }
                    </Scrollbar>
                  ):(
                    <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                      {
                        oldNotifications.map((notification) => (
                          <NotificationItem key={notification.id} handleClick={handleNotificationClick} notification={notification} />
                        ))
                      }

                    </Scrollbar>
                  )
                }
              </List>
            )
          }

        </Scrollbar>



        {/* {
          oldNotifications.length==0 || showAll?null:(
            <div>
              <Divider />
              <Box sx={{ p: 1 }}>
                <Button fullWidth disableRipple onClick={()=> setShowall(true)}>
                  View All
                </Button>
            </Box>
            </div>

          )
        } */}

      <Modal
          open={docModalOpen}
          onClose={e=>closeDocModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <StyledBox>
			  <Box sx={{ mb:2 }}>
			  <h4 > Confirm details are correct</h4>
			  </Box>
          {
              currentDoc && docTemplates[currentDoc.docType] && docTemplates[currentDoc.docType]({
                  // currentDocTemplate,
                  doc: currentDoc,
                  editable: false,
                  loading,
                  toast
              })
          }
          <Box sx={{display: 'flex', justifyContent: 'space-around'}} >
            <Button variant="outlined" sx={{mt:1}} onClick={e=>closeDocModal()}>Cancel</Button>
			<LoadingButton variant="outlined" color="warning" loading={loading} sx={{mt:1}} onClick={e=>toggleReasonBox(true)}> Decline </LoadingButton>
            <LoadingButton variant="outlined" loading={loading} sx={{mt:1}} onClick={e=>acceptVerification(currentDoc)}> Accept </LoadingButton>
          </Box>

          </StyledBox>
        </Modal>
        <Modal
          open={reasonBox}
          onClose={e=>toggleReasonBox(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <StyledBox>
		   <Box sx={{ mb:2 }}>
		  		<h4>Please define the reason for declining this card</h4>
		  </Box>
          <TextField
				fullWidth
				multiline
				value={reason}
				onChange={e=>setReason(e.target.value)}
				minRows={5}
				maxRows={10}
				label="Reason"
			  />

		  <LoadingButton variant="outlined" color="warning" fullWidth loading={loading} sx={{mt:1}} onClick={handleDeclineCard}> Decline </LoadingButton>

          </StyledBox>
        </Modal>


      </MenuPopover>



      <JobsiteJoinModal open={jobsiteOpen} toggleModal={toggleJobsiteModal} notification={selectedNotification} />

          <Modal
          open={supervisorDocsOpen}
          onClose={e=>toggleSupervisorDocsModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Box sx={isMobile? modalStyleMobile: modalStyleDesktop}>
            {/* {
              whsDoc?( */}
                <JobsiteSupervisorDocs loading={loading} toast={toast} handleDocAccept={handleSupervisorDocAccept} open={supervisorDocsOpen} toggleModal={toggleSupervisorDocsModal} doc={whsDoc} />
              {/* ):null
            } */}

          </Box>
        </Modal>

      <Modal
        open={joinModal}
        onClose={e=>toggleJoinModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={joinModalStyle}>
          {/* <Typography id="modal-modal-title" variant="h5" sx={{my:3}} component="h2">
            Request to join your team
          </Typography> */}

          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            
            <Avatar sx={{
            width: 96,
            height: 96
          }} alt={joinUser?.name} src={userAvatar100(joinUser?.picture)} />
            <Typography onClick={e=>openProfile(joinUser.userId)} sx={{mt:1, cursor: 'pointer'}}>
              <u>
              {joinUser?.name} ({userRoleNames[joinUser?.roleId]})
              </u>
             
            </Typography>
            <Typography sx={{mt:1}}>
              has requested to join your team
            </Typography>
            
          </Box>
          
            
            
          <Box  sx={{display: 'flex', justifyContent: 'space-around', mt:2}} >
            <Button variant="outlined"  onClick={e=>toggleJoinModal(false)}>Reject</Button>
            <LoadingButton variant="contained" onClick={handleAcceptJoinRequest} type="button" color="primary" loading={loading}>
              Accept
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={joinTeamModal}
        onClose={e=>toggleJoinTeamModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={joinModalStyle}>
          {/* <Typography id="modal-modal-title" variant="h5" sx={{my:3}} component="h2">
            Request to join your team
          </Typography> */}

          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            
            <Avatar sx={{
            width: 96,
            height: 96
          }} alt={joinUser?.name} src={userAvatar100(joinUser?.picture)} />
            <Typography onClick={e=>openProfile(joinUser.userId)} sx={{mt:1, cursor: 'pointer'}}>
              <u>
              {joinUser?.name}
              </u>
             
            </Typography>
            <Typography sx={{mt:1}}>
              has requested you to join his team
            </Typography>
            
          </Box>
          
            
            
          <Box  sx={{display: 'flex', justifyContent: 'space-around', mt:2}} >
            <Button variant="outlined"  onClick={e=>toggleJoinTeamModal(false)}>Reject</Button>
            <LoadingButton variant="contained" onClick={handleAcceptTeamJoinRequest} type="button" color="primary" loading={loading}>
              Accept
            </LoadingButton>
          </Box>
        </Box>
      </Modal>


    </>
  );
}













function renderContent(notification) {
  const {first_name, last_name } = notification;
  let name = first_name + ' ' + last_name;
  let Title = notification.title;
  if(notification.type=='chat') Title = name+ ' ' +Title;
  const title = (
    <Typography variant="subtitle2">
      {Title}
      {/* <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.title)}
      </Typography> */}
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      picture: <img alt={Title} src="/static/icons/ic_notification_package.svg" />,
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      picture: <img alt={Title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      picture: <img alt={Title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      picture: <img alt={Title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  if(notification.type=='jobsite'){
    return {
      picture: <img alt={Title} src={userAvatar(notification.picture)} />,
      title
    };
  }
  return {
    picture: <img alt={Title} src={userAvatar(notification.picture)} />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};


function NotificationItem({ notification, handleClick }) {
  const { picture, title, } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      disableGutters
      // component={RouterLink}
      onClick={()=>handleClick(notification)}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{picture}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(new Date(notification.created))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
