import PropTypes from 'prop-types';
import SunEditor from "suneditor-react";
//
import { toolbarFull, toolbarSimple, toolbarOptions } from './Toolbar';
import SunEditorStyle from './Style';
import "suneditor/dist/css/suneditor.min.css";


// ----------------------------------------------------------------------

Suneditor.propTypes = {
  error: PropTypes.bool,
  sx: PropTypes.object
};

export default function Suneditor({ showToolbar=true, error, sx, ...other }) {
  return (
    <SunEditorStyle
      sx={{
        ...(error && {
          border: (theme) => `solid 1px ${theme.palette.error.main}`
        }),
        ...sx
      }}
    >
      <SunEditor setOptions={toolbarOptions} showToolbar={showToolbar} setDefaultStyle="height: 400px" {...other} />
    </SunEditorStyle>
  );
}
