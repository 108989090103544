import { useState } from 'react';
import { Navigate, useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import Onboarding from '../pages/onboard/OnBoardLayout';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if(isAuthenticated && user && user.id && user.roleId==10 && !pathname.includes('onboard')){
    // 10 is default user who has not completed the onboarding process
    return <Navigate to={'/onboarding'} />;

    // navigate('/onboarding');
    // return  <Onboarding />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
