import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import { useEffect, useState } from 'react';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import StepperBottomRow from '../onboard/StepperBottomRow';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleContactOffice, jobdata,handleNext, toast, stepperHandlers}) {
  let [contactName, setContact] = useState('');
  let [officeNumber, setOffice] = useState('');
  const [showError, setShowError] = useState(false);


  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id){
      if(jobdata.contact_name) setContact(jobdata.contact_name);
      if(jobdata.office_number) setOffice(jobdata.office_number);
    }
  },[]);

  const handleSubmit = e=>{
    // if(jobdata && jobdata.id && jobdata.contact_name && jobdata.office_number){
    //   handleNext();
    // }else{
      let contact_name = contactName.trim();
      let office_number = officeNumber.trim();
      if(contact_name && office_number){
        handleContactOffice({contact_name, office_number});
      }else{
        toast('warning','please fill all the required fields!');
        setShowError(true);
      }
    // }

  }
  return (
    <div>
            <Typography variant="h4" gutterBottom>
             What is your organisation's office number?
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 4 }}>Enter a point of contact, if you are the point of contact then you can provide your information here.</Typography>
            <TextField sx={{ mb: 2 }}
              fullWidth
              autoComplete="pocname"
              type="text"
              error={showError && !contactName}
              value={contactName}
              onChange={e=>setContact(e.target.value)}
              label="Name Of Contact"
            />
            <TextField
              fullWidth
              autoComplete="pocnumber"
              error={showError && !officeNumber}
              type="text"
              value={officeNumber}
              onChange={e=>setOffice(e.target.value)}
              label="Office Number"
            />
            <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
    </div>
  );
}
