import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { useEffect, useState } from 'react';

// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import StepperBottomRow from '../onboard/StepperBottomRow';
import DocPreview, { LoadingPreview } from '../../components/_dashboard/user/profile/doc-templates/DocPreview';
import { fileUpload } from '../../socket';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleCards, toast, jobdata, updateToken, handleExtras, handleNext, handleWorkingHeightsCard, stepperHandlers}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = React.useRef(null);

  let [name, setName] = useState('');
  let [dob, setDob] = useState('');
  let [rtoNumber, setRtoNumber] = useState('');
  let [issueDate, setIssueDate] = useState('');
  let [cardNmber, setCardNumber] = useState('');
  let [soa, setSoa] = useState('');
  let [pin, setPin] = useState('');
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  let [cards, setCards] = useState([]);



  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id){
      setCards(jobdata.workingHeightsCards);
    }

  },[]);

  const handleSubmit = e=>{

    if(cards.length>0){
      handleCards(cards);
    }else{
      toast('warning', 'Please add at least one card!')
    }
  }

  const addCard = e=>{
    // if(name && soa && rtoNumber && issueDate && pin){
	  if(!file){
		  toast('warning','please select a valid document copy!');
		  return;
	  }
      let newCard = {
        // name,
        // soa: soa,
        // rto_number: rtoNumber,
        // issue_date: new Date(issueDate).toISOString().split('T')[0],
        // pin: pin,
        file: file,
        // fileName: file? file.name: ''
      };
      let newCards = [...cards, newCard];
      setCards(newCards);


      handleWorkingHeightsCard(newCard);

      if(jobdata.isEmployee || jobdata.isSupervisor){
        // its the last slide for employee
        handleNext();
        updateToken({
          isBuilder: jobdata.isBuilder,
          isSubcontractor: jobdata.isSubcontractor,
          isEmployee: jobdata.isEmployee,
          isSupervisor: jobdata.isSupervisor
        })
      }

      // if(jobdata.isSubcontractor){
      //   handleExtras();
      // }

      setSoa('');
      setName('');
      setPin('');
      setRtoNumber('');
      setIssueDate('');
    // }else{
    //   if(jobdata.workingHeightsCards.length==0){
    //     toast('warning','Please fill all the fields!');
		// setShowError(true);
    //   }else{

    //     if(jobdata.isEmployee || jobdata.isSupervisor){
    //       // its the last slide for employee
    //       handleNext();
    //       updateToken({
    //         isBuilder: jobdata.isBuilder,
    //         isSubcontractor: jobdata.isSubcontractor,
    //         isEmployee: jobdata.isEmployee,
    //         isSupervisor: jobdata.isSupervisor
    //       })
    //     }
    //   }
    // }

  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }

  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }

  return (
    <div>
            <Typography variant="h4" sx={{ mb: 3 }}>
             Add your working at heights card
            </Typography>
            {
              cards.length>0?(
                <Typography sx={{ color: 'text.secondary', mb: 4 }}>
                  {cards.length} card added
                </Typography>
              ):null
            }

      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
              p: 2,
              width: 1,
              position: 'relative',
              border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
            }}
          >

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={2} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/NRT-logo-${isLight ? 'light' : 'dark'}.png`}

            />
          </Grid>

          <Grid item xs={8} >
             <Typography variant="subtitle1" sx={{ fontSize: 15, textAlign: 'center', marginTop: 1 }}>Competency Card</Typography>
          </Grid>
          <Grid item xs={2} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/DT-logo-${isLight ? 'light' : 'dark'}.png`}

            />
          </Grid>
        </Grid>

        {/* <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={7} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField error={showError && !name} value={name} onChange={e=>setName(e.target.value)} id="b-card-name" label="Full Name" variant="outlined" />
            </Box>
          </Grid>

          <Grid item xs={5} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker inputFormat="dd-MM-yyyy" 
                label="Issue Date"
         		openTo="day"
          		views={['day','month','year']}
                value={issueDate}
				error={showError && !issueDate}
                onChange={(newValue) => {
                  setIssueDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <Typography variant="subtitle1" sx={{ fontSize: 15, textAlign: 'center', marginTop: 1 }}>Has attained competency in:<br />RIIOHS204A Work Safety at Heights</Typography>
            </Box>
               <TextField value={soa} error={showError && !soa} onChange={e=>setSoa(e.target.value)} id="b-card-no" label="SOA" variant="standard" size="small" />

          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
               <TextField value={pin} error={showError && !pin} onChange={e=>setPin(e.target.value)} id="b-card-no" label="PIN" variant="standard" size="small" />
            </Box>
          </Grid>
          <Grid item xs={6} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
               <TextField value={rtoNumber} error={showError && !rtoNumber} onChange={e=>setRtoNumber(e.target.value)} id="b-card-no" label="RTO" variant="standard" size="small" />
            </Box>
          </Grid>

        </Grid> */}
      </Paper>

      </Stack>

      <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />
        <Grid container spacing={1}>

            <Grid item xs={12} >
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
               {
                    fileLoading?(
                      <LoadingPreview />
                    ):null
                  }
                  {
                    file?(
                      <DocPreview fileLoading={fileLoading} file={file} />
                    ):null
                  }
                <Button onClick={handleFile} fullWidth size="large" variant="outlined" sx={{ mt: 1 }}>Upload WAH Card</Button>
              </Box>
            </Grid>

          </Grid>

      {/* <Box sx={{ mt: 3 }}>
        <Button size="small" onClick={addCard} startIcon={<Icon icon={plusFill} />} >
          Save card
        </Button>
      </Box> */}
    <StepperBottomRow {...stepperHandlers} handleSubmit={addCard} />
    </div>
  );
}
