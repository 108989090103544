import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { useEffect, useState } from 'react';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import { checkAbn } from '../../socket';
import '../../assets/styles/carousel.css';
import StepperBottomRow from '../onboard/StepperBottomRow';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleABN, jobdata, handleNext, toast,setLoading, stepperHandlers}) {
  let [companyabn, setCompanyabn] = useState('');

  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id){
      if(jobdata.company_abn) setCompanyabn(jobdata.company_abn);
    }
    checkAbn(processAbn);
  },[jobdata]);

  const processAbn = res=>{
    setLoading(false);
    if(res.success){
      let data = res.data;
      handleABN({
        company_abn: res.abn,
        state: data.AddressState? data.AddressState: null,
        post_code: data.AddressPostcode? data.AddressPostcode: null,
        company_name: data.EntityName? data.EntityName: null
      })
    }else{
      toast('warning', res.msg);
    }
    // console.log('got abn res: ',res);
  }

  const handleSubmit = e=>{
    let company_abn = companyabn.trim();
//     if(jobdata && jobdata.id && jobdata.company_abn){
//       handleNext();
//     }else{
      if(company_abn){
        setLoading(true);
        checkAbn({abn: company_abn})
      }else{

        toast('warning', 'Please enter a valid ABN!');
      }
//     }

    // if(company_abn){
    //   handleABN({company_abn})
    // }
  }
  return (
    <div>
            <Typography variant="h4" gutterBottom>
             What is your company ABN Number?
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 4 }}>Enter your details below.</Typography>
            <TextField
              fullWidth
              autoComplete="company"
              type="text"
              onChange={(e)=> setCompanyabn(e.target.value)}
              value={companyabn}
              label="Company ABN"
            />
            <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
    </div>
  );
}
