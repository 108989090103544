import React, {useEffect, useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Typography, TextField, Modal, Grid } from '@material-ui/core';
// import {ProfessionList} from '../../data/Professions'
import StepperBottomRow from '../onboard/StepperBottomRow';
import LoadingButton from '@mui/lab/LoadingButton';
import { newProfession } from 'src/socket';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
import ResponsiveTableStyle  from 'src/assets/responsiveTableStyle';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const modalStyle = {
  position: 'absolute',
  // width: 'calc(100% - 70%)',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  height: '300px',
  // height: 'calc(100% - 75%)',
  bgcolor: 'background.paper',
  borderRadius:'10px',
  boxShadow: 24,
  padding: '30px',
  // top: '30%',
  // left: '30%',

};

export default function CheckboxList({jobdata, toast, loading, setLoading, handleProfessions, handleNext, stepperHandlers}) {
  const [checked, setChecked] = useState({});
  const [newServiceModal, toggleNewServiceModal] = useState(false);
  const [title, setTitle] = useState('');
  const [search, setSearch] = useState('');
  const [professionList, setProfessionList] = useState([]);

  useEffect(() => {
    if(jobdata && jobdata.id){
      setProfessionList(jobdata.professionList);
	  if(jobdata && jobdata.professions && jobdata.professions.length>0){
		 var newChecked = {};
		 jobdata.professions.map(pr=>{
			 newChecked[pr.title] = true;
		 });
		 setChecked(newChecked);
	  }
    }

  }, [jobdata])
  // const handleSearch = e=>{
  //   let key = e.target.value.toLowerCase();
  //   // console.log(key);
  //   if(key.length>0){
  //     setProfessionList(ProfessionList.filter(f=>f.title.toLowerCase().includes(key)));
  //   }else{
  //     setProfessionList(ProfessionList);
  //   }
  // }
  const handleToggle = (value) => () => {
    const newChecked = {...checked};

    if (checked[value.title]) {
      delete newChecked[value.title]
    } else {
      newChecked[value.title] = true;
    }

    setChecked(newChecked);
  };

  const handleNewService = e=>{
    console.log('swms title: ',title);
    if(title.trim()){
      // good to go
      newProfession({title});
      toggleNewServiceModal(false);
    }else{
      toast('warning', 'please enter a valid title!');
    }
  }

  const handleSubmit = e=>{
//     if(jobdata && jobdata.id && jobdata.professions.length>0){
//       handleNext();
//     }else{
      if(Object.keys(checked).length==0){
        toast('warning', 'You must chose at least one!');
        return;
      }
	  var data = jobdata.professionList.filter(pr=>checked[pr.title]? true: false);
//       console.log('sending professions: ',checked);
      handleProfessions(data);
//     }



  }
  let profList = professionList;
  profList.sort((a, b) => a.title.localeCompare(b.title));

  return (
<div>
    <Typography variant="h4" sx={{ mb: 3 }}>
     Select your trade or SWMS
    </Typography>
    <Typography sx={{ color: 'text.secondary', mb: 4 }}>
     Your SWMS will be generated based on the trade or services you pick for yourself or your empolyees. 
    </Typography>
	  {/*<Button sx={{ml: 2, mb: 1}} variant="outlined" onClick={e=>toggleNewServiceModal(true)} >New Service</Button>*/}
    <Box sx={{ pl: 2 , pr: 2}}>
    <TextField id="search" value={search} onChange={e=>setSearch(e.target.value)} label="Search" variant="outlined" sx={{ width:'100%', mb:2 }} />
    </Box>
    <Box sx={{mb:2,  width: '100%',
        mx:1,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,}}>
      {/* <ResponsiveTableStyle> */}
        <Table className="responsiveTable">
          <Thead>
            <Tr>
              <Th style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>Checked</Th>
              <Th style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>Title</Th>
              {/* <Th style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>Amount</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {
                profList.map((value, index) => {

                if(search && search.length>0){
                  if(!value.title.toLowerCase().includes(search.toLowerCase())) return null
                }
                const labelId = `checkbox-list-label-${value.title}`;
                return (
                  <Tr onClick={handleToggle(value)}>
                    <Td style={{padding: 10}}>
                      <Checkbox
                        edge="start"
                        checked={checked[value.title]? true: false}
                        tabIndex={-1}
                        disableRipple

                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </Td>
                    <Td style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>{value?.title}</Td>
                    {/* <Td style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>${value?.amount}</Td> */}
                    
                  </Tr>
                )

              })
            }
            
          </Tbody>
        </Table>
      {/* </ResponsiveTableStyle> */}
    </Box>
    {/* <List sx={{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
      }}>
      {profList.map((value, index) => {
        if(search && search.length>0){
          if(!value.title.toLowerCase().includes(search.toLowerCase())) return null
        }
        const labelId = `checkbox-list-label-${value.title}`;

        return (
          <ListItem
            key={index}
            disablePadding
          >
            <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked[value.title]? true: false}
                  tabIndex={-1}
                  disableRipple

                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.title} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List> */}

    <Modal
          keepMounted
          open={newServiceModal}
          onClose={e=>toggleNewServiceModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"

        >


          <Box sx={modalStyle}>


          <Grid item xs={8} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: 'auto', mt: 4}} >

            <TextField id="b-card-no" value={title} onChange={e=>setTitle(e.target.value)} label="Service Title" variant="standard" sx={{ marginTop: 2 , marginBottom: 1 , }} fullWidth />
            {/* <TextField id="b-card-no" value={substanceLink} onChange={e=>setSubstanceLink(e.target.value)} label="Substance Link" variant="standard" sx={{ marginTop: 2 , marginBottom: 1 , }} fullWidth /> */}
          </Grid>

          <div style={{display: 'flex', justifyContent: 'center'}} >

              <LoadingButton
                    size="medium"
                    onClick={handleNewService}
                    loading={loading}
                    variant="contained"

                    sx={{ mt: 2, mr: 2 }}
                >
                    Add Service
                </LoadingButton>
              <Button color="warning" size="large" onClick={e=>toggleNewServiceModal(false)}
                variant="outlined" sx={{ mt: 2 }}>
                  Cancel
              </Button>
          </div>


          </Box>

        </Modal>
    <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
</div>
  );
}




