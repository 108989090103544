import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import { Box, Button, Link, Container, Typography, TextField, Grid, useMediaQuery, Modal } from '@material-ui/core';


export default function StepperBottomRow({ steps, activeStep, skipable, handleSkip, completed, handleBack, handleNext, handleSubmit, loading}){
    return (
		<div style={{width: '100%'}}>
			
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    variant="contained"
                    size="large"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {/* <Button
                    color="inherit"
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={loading}
                    // onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    next
                </Button> */}
                {
                    skipable?(
                        <Button sx={{mr: 1}} onClick={handleSkip}>I do not need this, Skip</Button>
                    ):null
                }

                <LoadingButton
                    // type="submit"
                    onClick={handleSubmit}
                    loading={loading}
                    variant="contained"
                    size="large"
                >
                    {
                        'Next'
                    }
                </LoadingButton>


            </Box>
		</div>
    )

  }

//    {/* {
//                 steps && activeStep != steps.length &&
//                 (completed[activeStep] ? (
//                     <Typography variant="caption" sx={{ display: 'inline-block' }}>
//                     Step {activeStep + 1} already completed
//                     </Typography>
//                 ) : null)
//                 } */}