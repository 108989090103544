import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import { Icon } from '@iconify/react';

import { useSnackbar } from 'notistack5';
import { MIconButton } from '../components/@material-extend';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import { useState, useEffect } from 'react';
import {userRoles } from '../constants/defaultValues';
import Swms from '../pages/documents/swms';
import HazardousSubstances from '../pages/documents/HazardousSubstances';
import { login, loginOk, loginOff, onlineUsers, sendMessage, unreadMessagesCount} from '../socket';
// import Verification from '../pages/verification/index'

// ----------------------------------------------------------------------


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user, updateOnlineUsers } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  var [defaultDashboard, setDefaultDashboard] = useState(<Navigate to="/dashboard/app" replace />);
  // console.log('user is: ',user);

  const toast = (type, message)=>{
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }

  useEffect(() => {
    if(user && user.id){
      updateDashboard(user.roleId);
    }

    login(processLogin)
    onlineUsers(processOnlineUsers);
    sendMessage(processSendMessage);
    processLogin(loginOk());
    return ()=>{
      loginOff(processLogin);
    }

  }, [])

  const processLogin = (login)=>{
		// console.log("login",login)
		if(login.auth){
      // onlineUsers();
		}
	}

  const processSendMessage = res=>{

    if(!window.location.pathname.includes('chat')){
      if(res.success){
        let newMessage = res.data;
        unreadMessagesCount();
        toast('info', `new message from ${newMessage.senderFirstName} ${newMessage.senderLastName}`);
      }else{
        toast('error',res.msg);
      }
    }


  }

  const processOnlineUsers = async users=>{
    // console.log('got online users: ',users);
    await updateOnlineUsers(users);
  }

  useEffect(() => {
    if(user && user.id){
      updateDashboard(user.roleId);
    }
  }, [user])

  const updateDashboard = role=>{
    let dashboard = <Navigate to="/dashboard/app" replace />;
    if(role == userRoles.builder) dashboard = <Navigate to="/dashboard/checklist" replace />
    else if(role == userRoles.admin) dashboard = <Navigate to="/dashboard/admin" replace />
    else if(role == userRoles.subcontractor) dashboard = <Navigate to="/dashboard/checklist" replace />
    else if(role == userRoles.employee) dashboard = <Navigate to="/dashboard/checklist" replace />
    else if(role == userRoles.officeadmin) dashboard = <Navigate to="/dashboard/officeadmin" replace />
    else if(role == userRoles.supervisor) dashboard = <Navigate to="/dashboard/checklist" replace />
    else if(role == userRoles.storeAdmin) dashboard = <Navigate to="/dashboard/storeAdmin" replace />
    else dashboard = <Navigate to="/dashboard/app" replace />
    setDefaultDashboard(dashboard);
  }

  return useRoutes([
    {
      path:'documents',
      children:[
        {
          path:'jobsite/:jobsiteId',
          element: <JobsiteDocuments />
        }
      ]
    },
    
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'register/:token',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'register/role/:role',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'user/:userId/documents/:builderId', element: <BuilderContractAccept /> },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'password-recovery/:token', element: <PasswordRecovery /> },
        { path: 'password-recovery', element: <PasswordRecovery /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'user/docUpdate/:requestId', element: <CardUpload /> },
        { path: 'public/user/:userId', element: <UserProfile />  }
      ]
    },

    {
      path: 'store',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: defaultDashboard},
        { path: 'products', element: <GiveawayProducts /> },
        { path: 'claims', element: <Claims /> },
        { path: 'settings', element: <Settings /> },
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: defaultDashboard},
        { path: 'app', element: defaultDashboard },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'admin', element: <DashAdmin /> },
        { path: 'dashBuilder', element: <DashBuilderMain /> },
        { path: 'checklist', element: <DashBuilderMain /> },
        { path: 'builder', element: <DashBuilder /> },
       
        { path: 'employee', element: <DashEmployee /> },
        { path: 'subcontractor', element: <DashSubcontractors /> },
        { path: 'officeadmin', element: <DashOfficeadmin /> },
        { path: 'supervisor', element: <DashSupervisor /> },
        { path: 'storeAdmin', element: <DashStoreAdmin /> },
        { path: 'jobsites', element: <JobSitesMap /> },
        {
          path: 'documents',
          // element:  <Documents type="ptc" />,
          children: [
            { path: '/', element: <Documents type="ptc" />},
            { path: 'ptc', element: <Documents type="ptc" /> },
            { path: 'whs', element: <Documents type="whs" /> },
            { path: 'wir', element: <Documents type="wir" /> },
            { path: 'swms', element: <Swms /> },
            { path: 'hazardousSubstances', element: <HazardousSubstances /> },
            { path: 'termsAndConditions', element: <TermsAndConditions /> },

          ]
        },
        {
          path: 'documents',
          // element:  <Documents type="ptc" />,
          children: [
            { path: '/old', element: <OldDocuments type="ptc" />},
            { path: 'old/ptc', element: <OldDocuments type="ptc" /> },
            { path: 'old/whs', element: <OldDocuments type="whs" /> },
            { path: 'old/wir', element: <OldDocuments type="wir" /> }

          ]
        },
        {
          path: 'verification',
          children: [
            { path: '/', element: <Verification type="cards" />},
            { path: '/cards', element: <Verification type="cards" />},
            { path: '/licenses', element: <Verification type="licenses" />},
            { path: '/insurances', element: <Verification type="insurances" />},
            { path: '/workCovers', element: <Verification type="workCovers" />},
            { path: '/ewpCards', element: <Verification type="ewpCards" />},
            { path: '/workingHeightsCards', element: <Verification type="workingHeightsCards" />},
            { path: '/testTag', element: <Verification type="testTag" />},


          ]
        },
        {
          path: 'jobsiteSWMS',
          children: [
            { path: '/', element: <JobsiteSwms />},
            { path: 'constructionTypes', element: <ConstructionTypeSwms /> },
            { path: 'poolTypes', element: <PoolTypeSwms /> },
          ]
        },
        {
          path: 'analytics',
          element: <GeneralAnalytics />
        },
        {
          path: 'e-commerce',
          children: [
            { path: '/', element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> }
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'profile/:actionType/:docType', element: <UserProfile /> },
            { path: 'profile/:userId', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'yourteam', element: <YourTeam /> },
            { path: 'yourbuilders', element: <YourBuilders /> },
            { path: 'new', element: <UserCreate /> },
            { path: '/:name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'addjobsite', element: <AddJobSite /> },
            { path: 'jobsite/:jobsiteId/edit', element: <UpdateJobsite /> },
            { path: 'jobdetails/:jobsiteId', element: <JobDetails /> },
			      { path: 'upload/:docType/card/:id', element: <CardUpload /> }
          ]
        },
        {
          path: 'blog',
          children: [
            { path: '/', element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { path: '/', element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { path: '/', element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':roomId', element: <Chat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> }
      ]
    },
    // OnBoard Routes -- This is the page where users 1st see when they login and will show the onboard steps
    {
      path: 'onboarding',
      element: (
        <AuthGuard>
          <OnboardLayout />
        </AuthGuard>
      ),
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'TermsOfService', element: <TermsOfService /> },
        { path: 'PrivacyPolicy', element: <PrivacyPolicy /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'TimeLine', element: <TimeLine /> },
        { path: 'faqs', element: <Faqs /> },
        {
          path: 'components',
          children: [
            { path: '/', element: <ComponentsOverview /> },
            // FOUNDATIONS
            { path: 'color', element: <Color /> },
            { path: 'typography', element: <Typography /> },
            { path: 'shadows', element: <Shadows /> },
            { path: 'grid', element: <Grid /> },
            { path: 'icons', element: <Icons /> },
            // MATERIAL UI
            { path: 'accordion', element: <Accordion /> },
            { path: 'alert', element: <Alert /> },
            { path: 'autocomplete', element: <Autocomplete /> },
            { path: 'avatar', element: <Avatar /> },
            { path: 'badge', element: <Badge /> },
            { path: 'breadcrumbs', element: <Breadcrumb /> },
            { path: 'buttons', element: <Buttons /> },
            { path: 'checkbox', element: <Checkbox /> },
            { path: 'chip', element: <Chip /> },
            { path: 'dialog', element: <Dialog /> },
            { path: 'label', element: <Label /> },
            { path: 'list', element: <List /> },
            { path: 'menu', element: <Menu /> },
            { path: 'pagination', element: <Pagination /> },
            { path: 'pickers', element: <Pickers /> },
            { path: 'popover', element: <Popover /> },
            { path: 'progress', element: <Progress /> },
            { path: 'radio-button', element: <RadioButtons /> },
            { path: 'rating', element: <Rating /> },
            { path: 'slider', element: <Slider /> },
            { path: 'snackbar', element: <Snackbar /> },
            { path: 'stepper', element: <Stepper /> },
            { path: 'switch', element: <Switches /> },
            { path: 'table', element: <Table /> },
            { path: 'tabs', element: <Tabs /> },
            { path: 'textfield', element: <Textfield /> },
            { path: 'timeline', element: <Timeline /> },
            { path: 'tooltip', element: <Tooltip /> },
            { path: 'transfer-list', element: <TransferList /> },
            { path: 'tree-view', element: <TreeView /> },
            { path: 'data-grid', element: <DataGrid /> },
            // EXTRA COMPONENTS
            { path: 'chart', element: <Charts /> },
            { path: 'map', element: <Map /> },
            { path: 'editor', element: <Editor /> },
            { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
            { path: 'upload', element: <Upload /> },
            { path: 'carousel', element: <Carousel /> },
            { path: 'multi-language', element: <MultiLanguage /> },
            { path: 'animate', element: <Animate /> },
            { path: 'mega-menu', element: <MegaMenu /> },
            { path: 'form-validation', element: <FormValidation /> }
          ]
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const PasswordRecovery = Loadable(lazy(() => import('../pages/authentication/PasswordRecovery')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Onboard Layout
const OnboardLayout = Loadable(lazy(() => import('../pages/onboard/OnBoardLayout')));
// const OnboardLayout = Loadable(lazy(() => import('../pages/onboard/OnBoardLayout22')));

// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const Documents = Loadable(lazy(() => import('../pages/documents/index')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/documents/TermsAndConditions')));
const GiveawayProducts = Loadable(lazy(() => import('../pages/store/GiveawayProducts')));
const Claims = Loadable(lazy(() => import('../pages/store/Claims')));
const Settings = Loadable(lazy(() => import('../pages/store/Settings')));
const OldDocuments = Loadable(lazy(() => import('../pages/documents/indexCopy')));
const Verification = Loadable(lazy(() => import('../pages/verification/index')));
const JobsiteSwms = Loadable(lazy(() => import('../pages/jobsite-swms/index')));
const ConstructionTypeSwms = Loadable(lazy(() => import('../pages/jobsite-swms/constructionTypes')));
const PoolTypeSwms = Loadable(lazy(() => import('../pages/jobsite-swms/poolTypes')));
// const Swms = Loadable(lazy(() => import('../pages/documents/index')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const DashAdmin = Loadable(lazy(() => import('../pages/dashboard/GeneralDashAdmin')));
const DashBuilder = Loadable(lazy(() => import('../pages/dashboard/GeneralDashBuilder')));
const DashBuilderMain = Loadable(lazy(() => import('../pages/dashboard/BuildersDash')));
const DashEmployee = Loadable(lazy(() => import('../pages/dashboard/GeneralDashEmployee')));
const DashSubcontractors = Loadable(lazy(() => import('../pages/dashboard/GeneralDashSubcontractors')));
const DashOfficeadmin = Loadable(lazy(() => import('../pages/dashboard/GeneralDashOfficeadmin')));
const DashSupervisor = Loadable(lazy(() => import('../pages/dashboard/GeneralDashSupervisor')));
const DashStoreAdmin = Loadable(lazy(() => import('../pages/dashboard/GeneralDashStoreAdmin')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const CardUpload = Loadable(lazy(() => import('../pages/dashboard/CardUpload')));
const BuilderContractAccept = Loadable(lazy(() => import('../pages/dashboard/BuilderContractAccept')));
const YourTeam = Loadable(lazy(() => import('../pages/dashboard/YourTeam')));
const YourBuilders = Loadable(lazy(() => import('../pages/dashboard/YourBuilders')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const AddJobSite = Loadable(lazy(() => import('../pages/dashboard/AddJobSite')));
const UpdateJobsite = Loadable(lazy(() => import('../pages/dashboard/AddJobSite')));
const JobDetails = Loadable(lazy(() => import('../pages/dashboard/JobDetails')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const JobSitesMap = Loadable(lazy(() => import('../pages/dashboard/JobSitesMap')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const JobsiteDocuments = Loadable(lazy(() => import('../pages/JobsiteDocuments')));
const TermsOfService = Loadable(lazy(() => import('../pages/TermsOfService')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const OnBoard = Loadable(lazy(() => import('../pages/OnBoard')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const TimeLine = Loadable(lazy(() => import('../pages/TimeLine')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Components
const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')));
const Color = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationColors')));
const Typography = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationTypography')));
const Shadows = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationShadows')));
const Grid = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationGrid')));
const Icons = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationIcons')));
const Accordion = Loadable(lazy(() => import('../pages/components-overview/material-ui/Accordion')));
const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
const Autocomplete = Loadable(lazy(() => import('../pages/components-overview/material-ui/Autocomplete')));
const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
const Breadcrumb = Loadable(lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')));
const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
const Checkbox = Loadable(lazy(() => import('../pages/components-overview/material-ui/Checkboxes')));
const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
const Pagination = Loadable(lazy(() => import('../pages/components-overview/material-ui/Pagination')));
const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
const RadioButtons = Loadable(lazy(() => import('../pages/components-overview/material-ui/RadioButtons')));
const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
const Textfield = Loadable(lazy(() => import('../pages/components-overview/material-ui/textfield')));
const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
const TransferList = Loadable(lazy(() => import('../pages/components-overview/material-ui/transfer-list')));
const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
const DataGrid = Loadable(lazy(() => import('../pages/components-overview/material-ui/data-grid')));
//
const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')));
const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
const CopyToClipboard = Loadable(lazy(() => import('../pages/components-overview/extra/CopyToClipboard')));
const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
const MultiLanguage = Loadable(lazy(() => import('../pages/components-overview/extra/MultiLanguage')));
const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
const FormValidation = Loadable(lazy(() => import('../pages/components-overview/extra/form-validation')));
