import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Typography, TextField, Modal } from '@material-ui/core';
import StepperBottomRow from '../onboard/StepperBottomRow';
import Paper from '@mui/material/Paper';
import { useTheme, styled } from '@material-ui/core/styles';
import Logo from '../../components/Logo';

let {useEffect} = React;
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '40px', 
  position: 'relative', 
  marginBottom: '15px', 
  borderRadius: '0px',
  maxWidth: '794px',
  minHeight: '1123px',
  marginLeft: 'auto',
  marginRight: 'auto',
  background: theme.palette.background.neutral
}));
const footer = {
  fontSize: '10px', 
  fontWeight: 'bold',
  textAlign:'center',
  bottom: '10px',
  position: 'absolute',
  width: '100%',
};

const style = {
  position: 'absolute',
  width: 'calc(100% - 20px)',
  height: 'calc(100% - 20px)',    
  bgcolor: 'background.paper',
  borderRadius:'10px',
  boxShadow: 24,
  overflowY: 'scroll',
  padding: '30px',
  top: '10px',
  left: '10px',
 
};
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '70%',
//   bgcolor: 'white',
//   color: 'black',
//   boxShadow: 24,
//   borderRadius:'4px',
//   padding: '18px',
//   paddingLeft: '10px',
//   paddingRight: '10px',
 
// };


export default function CheckboxList({jobdata, toast, handleAcceptEmployerSwms, handleAcceptEmployerCustomSwms, stepperHandlers}) {
  const [checked, setChecked] = React.useState([]);
  const [professionList, setProfessions] = React.useState([]);
  const [currentProfession, setCurrentProfession] = React.useState(null);
  let [isOpen, setOpen] = React.useState(false);
  const handleClose = e=> setOpen(false);
  const handleOpen = e=> setOpen(true);

  
  useEffect(() => {
    if(jobdata)
      setProfessions(jobdata.employerProfessions)
  }, [])

  const handleSearch = e=>{
    let key = e.target.value.toLowerCase();
    // console.log(key);
    if(key.length>0){
      setProfessions(jobdata.professions.filter(f=>f.title.toLowerCase().includes(key)));
    }else{
      setProfessions(jobdata.professions);
    }
  }


  
  const handleToggle = value=> {
    if(checked[value.id]){
     delete checked[value.id];
    }else{
     checked[value.id] = value;
    }
    setChecked({...checked});
   };

  const handleSubmit = e=>{
    if(professionList.length>0 && Object.keys(checked).length>0){
        let acceptedSwms = professionList.filter(pr=> checked[pr.id]? true: false);
        handleAcceptEmployerSwms(acceptedSwms);
      }
    else if(jobdata.customSwmsFile){
      handleAcceptEmployerCustomSwms(jobdata.customSwmsFile);
    }
    else{
        toast('warning','Something went wrong! please contact to your employer')
      }
  }

  return (
    <div>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          The SWMS documents from your employer
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 4 }}>
            you need to accept all these documents to proceed.
        </Typography>
        {/* <Box sx={{ pl: 2 , pr: 2}}>  
        <TextField id="search" label="Search" onChange={handleSearch} variant="outlined" sx={{ width:'100%', mb:2 }} />
        </Box> */}
        {
          professionList.length>0? (
            <List sx={{
              width: '100%',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 300,
            }}>
            {professionList.map((value,index) => {
              const labelId = `checkbox-list-label-${value.title}`;
  
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton onClick={()=>{
                      setCurrentProfession(value); handleOpen();
                    }} edge="end" aria-label="comments">
                      <VisibilityIcon />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined}  dense>
                    <ListItemIcon>
                      {
                        checked[value.id] ? (
                          <Checkbox
                            edge="start"
                            checked={checked[value.id]?true: false}
                            tabIndex={-1}
                            disableRipple
                            onClick={()=>handleToggle(value)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        ):(
                          <Button onClick={()=>handleToggle(value)}>Accept</Button>
                        )
                      }
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value.title} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          ): jobdata.customSwmsFile? (
            <Box>
              <Button
                 
                 sx={{ width: '100%', my:1 }}
                 variant={'outlined' }
                 color={'primary'}
               >
                <a target="_blank" href={jobdata.customSwmsFile}>View SWMS Document</a>
               </Button>
            </Box>
          ):(
            <Box>
               <Typography variant="h6"> No SWMS document found! </Typography>
            </Box>
          )
        }
        
        <Modal
          keepMounted
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
              
          <Box sx={style}>
              <Logo />
            <Typography id="modal-modal-title" variant="h3" component="h1" style={{ textAlign: 'center' }} sx={{ mb:3 }}>
              {/* Your master SWMS (Safe Work Method Statement) */}
              {currentProfession? currentProfession.title: ''}
            </Typography>
            <div style={{ height: '100vh'}}>
              {
                  currentProfession?(
                      <div className="pages" >
                          {
                              currentProfession.sections && currentProfession.sections.map((section, index)=>{
                                  let con = section.content? JSON.parse(section.content): '';
                                  return (
                                      <div>
                                          <StyledPaper 
                                              // onMouseEnter={()=>toggleEditButton(index, true)} 
                                              // onMouseLeave={()=>toggleEditButton(index, false)} 
                                              elevation={3} 
                                              className="editorPage"
                                              
                                          >
                                                  <Logo /> 
                                              
                                              <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: con }} />
                                                                          
                                              {/* {
                                                  hovered[index]? (
                                                      <div className="editDocButton" style={{position:'absolute'}}>
                                                          <IconButton onClick={()=>handleEditSection(section)} size="small" variant="contained" >
                                                              <EditIcon />
                                                          </IconButton>
                                                          
                                                          <IconButton onClick={()=>handleDelete(section)} aria-label="delete">
                                                              <DeleteIcon />
                                                          </IconButton>
          
                                                      </div>
                                                      
                                                  ):null
                                              } */}
                                              <div style={footer}>Copyright © 2022 WeSafe Pty Ltd document generated at: WeSafe.com.au</div>
                                          </StyledPaper>
                                      </div>
                                  )
                              })
                          }

                          {/* <Button onClick={handleNewPage}>New Section</Button>  */}
                          
                      </div>
                  ):null
              }
            {/* <textarea id="editor" name="editor" ></textarea> */}
              {/* <JoditEditor
                  ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => {
                      // console.log(newContent)
                      setContent(newContent);
                      setDesc(newContent);
                    }} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {
                      // console.log(newContent)
                      // setContent(newContent)
                    }}
                /> */}

                <Box sx={{position: 'relative', display: 'flex', justifyContent: 'center', pb:4, pt:1}}>
                  <Button size="medium" sx={{mr: 2}} variant="outlined" onClick={()=>{
                      handleClose();
                    }}>
                      close
                  </Button> 
                  <Button size="medium" variant="contained" onClick={()=>{
                      // handleToggle(currentProfession); 
                      setChecked({...checked, [currentProfession.id]: currentProfession});
                      handleClose();
                    }}>
                      Accept
                  </Button> 
                </Box>
              
            </div>
           
            {/* <Button size="medium" onClick={(e)=>{
                handleSaveProfession();
              }} 
              variant="contained" sx={{ mt: 2 }}>
                Save Document
            </Button> */}
        </Box>
        
        </Modal>
        {/* <Modal
          keepMounted
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
             
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {
                currentProfession? currentProfession.title : null
              }
            </Typography>
            <div style={{height: '500px', overflowY: 'scroll'}}>
            <p className="safetyDoc" dangerouslySetInnerHTML={{__html: currentProfession?currentProfession.content: ''}} />
            </div>

          
          </Box>
        
        </Modal> */}
        <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
    </div>
  );
}