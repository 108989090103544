import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Typography, TextField, Modal, Grid } from '@material-ui/core';
import StepperBottomRow from '../onboard/StepperBottomRow';
import Logo from '../../components/Logo';
import CloseIcon from '@mui/icons-material/Close';
import {newSubstance} from '../../socket';
import LoadingButton from '@mui/lab/LoadingButton';

let {useEffect} = React;
const style = {
  position: 'absolute',
  width: 'calc(100% - 60%)',
  height: 'calc(100% - 60%)',
  bgcolor: 'background.paper',
  borderRadius:'10px',
  boxShadow: 24,
  padding: '30px',
  top: '30%',
  left: '30%',

};
const styleButton = {
  position: 'absolute',
  top: '10px',
  right: '25px',

};


export default function CheckboxList({jobdata, toast, handleHazards, setLoading, loading, handleNext, stepperHandlers}) {
  const [checked, setChecked] = React.useState({});
  const [hazardList, setHazardList] = React.useState([]);
  const [currentHazard, setCurrentHazard] = React.useState(null);
  let [isOpen, setOpen] = React.useState(false);
  const handleClose = e=> setOpen(false);
  const [search, setSearch] = React.useState('');
  const handleOpen = e=> setOpen(true);
  let [substance, setSubstance] = React.useState('');
  let [substanceLink, setSubstanceLink] = React.useState('');


  useEffect(() => {
    if(jobdata){
		setHazardList(jobdata.hazards)
		if(jobdata.subcontractorHazards.length>0){
			let check={};
			jobdata.subcontractorHazards.map(h=>{
				check[h.hazardId] = true;
			});
			setChecked({...check});
		}
	}

  }, [jobdata])

  useEffect(()=>{
    newSubstance(processNewSubstance);
    return ()=>{
      newSubstance(processNewSubstance, 'off');
    }
  });

  const processNewSubstance = res=>{
    if(res.success){
      setLoading(false);
      toast('success', 'Added successfullt')
      handleClose();
    }else{
      toast('warning', res.msg);
    }
  }
  const handleSearch = e=>{
    let key = e.target.value.toLowerCase();
    // console.log(key);
    if(key.length>0){
      setHazardList(jobdata.hazards.filter(f=>f.title.toLowerCase().includes(key)));
    }else{
      setHazardList(jobdata.hazards);
    }
  }



  const handleToggle = (value) => () => {
    const newChecked = {...checked};

    if (checked[value.hazardId]) {
      delete newChecked[value.hazardId]
    } else {
      newChecked[value.hazardId] = true;
    }

    setChecked(newChecked);
  };

  const handleSubmit = e=>{
    // if(jobdata && jobdata.id && jobdata.subcontractorHazards.length>0){
//       handleNext();
//     }else{
      if(Object.keys(checked).length>0){
		let data = jobdata.hazards.filter(h=>checked[h.hazardId]? true: false);

        // console.log('ready to send: ',checked);
        // return;
        handleHazards(data);
      }else{
        toast('warning', 'you must chose at least one!');
//         handleHazards({hazardsSkipped: 1})
      }
//     }

  }

  const handleNewSubstance = e=>{
    if(substance && substanceLink){
      let data = {
        substanceLink,
        substance
      }
      newSubstance(data);
      setLoading(true);
    }else{
      toast('warning', 'Please fill both fields!');
    }
  }
  const handleSkip = ()=>{
	handleHazards({hazardsSkipped: 1})
  }
  let sortedHazards = hazardList;
  sortedHazards.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          Do you use hazardous substances?
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 2 }}>
            Select from the list below all the substances you use for work.
        </Typography>
        <Button sx={{ml: 2, mb: 1}} onClick={handleOpen} >New Substance</Button>
        <Box sx={{ pl: 2 , pr: 2}}>
        <TextField id="search" label="Search" value={search} onChange={e=>setSearch(e.target.value)} variant="outlined" sx={{ width:'100%', mb:2 }} />
        </Box>
        <List sx={{
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
          }}>
          {sortedHazards.map((value,index) => {
            const labelId = `checkbox-list-label-${value.title}`;
            if(search && search.length>0){
              if(!value.title.toLowerCase().includes(search.toLowerCase())) return null
            }
            return (
              <div style={{display: 'flex', marginLeft: '20px'}}>
                <Checkbox
                      edge="start"
                      checked={checked[value.hazardId]? true: false}
                      tabIndex={-1}
                      disableRipple
                      onClick={handleToggle(value)}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                <ListItem
                  key={index}
                  secondaryAction={
                    <Button variant="contained" size="small" onClick={e=>{
                      window.open(value.content, '_blank');
                    }} edge="end" aria-label="comments">
                      {/* <VisibilityIcon  /> */}
                      view
                    </Button>

                  }
                  // secondaryAction = {
                  //   <Button color="warning" size="medium" onClick={()=>{
                  //     // setCurrentHazard(value); handleOpen();
                  //     window.open(value.content, '_blank');
                  //   }} sx={{ mt: 2 }}>
                  //       View
                  //   </Button>
                  // }
                  // onClick={()=>{
                  //   // setCurrentHazard(value); handleOpen();
                  //   window.open(value.content, '_blank');
                  // }}
                  disablePadding
                >
                  <ListItemButton onClick={()=>{
                      // setCurrentHazard(value); handleOpen();
                      window.open(value.content, '_blank');
                    }} role={undefined}  dense>
                    <ListItemIcon>

                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value.title} />
                  </ListItemButton>
                </ListItem>
              </div>

            );
          })}
        </List>
        <Modal
          keepMounted
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"

        >


          <Box sx={style}>


          <Grid item xs={8} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: 'auto', mt: 4}} >

            <TextField id="b-card-no" value={substance} onChange={e=>setSubstance(e.target.value)} label="Substance Name" variant="standard" sx={{ marginTop: 2 , marginBottom: 1 , }} fullWidth />
            <TextField id="b-card-no" value={substanceLink} onChange={e=>setSubstanceLink(e.target.value)} label="Substance Link" variant="standard" sx={{ marginTop: 2 , marginBottom: 1 , }} fullWidth />
          </Grid>

          <div style={{display: 'flex', justifyContent: 'center'}} >

              <LoadingButton
                    size="medium"
                    onClick={handleNewSubstance}
                    loading={loading}
                    variant="contained"

                    sx={{ mt: 2, mr: 2 }}
                >
                    Add Substance
                </LoadingButton>
              <Button color="warning" size="large" onClick={handleClose}
                variant="outlined" sx={{ mt: 2 }}>
                  Cancel
              </Button>
          </div>


          </Box>

        </Modal>
        <StepperBottomRow {...stepperHandlers} skipable={true} handleSkip={handleSkip} handleSubmit={handleSubmit} />
    </div>
  );
}