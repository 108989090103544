import React from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material

import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField,Card, Grid, useMediaQuery, Modal, List, ListItem } from '@material-ui/core';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoadingButton from '@mui/lab/LoadingButton';

import ListItemText from '@mui/material/ListItemText';
import DoneIcon from '@mui/icons-material/Done';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import { useEffect, useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import {monthlyMembershipPrice, periodTradeContract, yearlyMembershipPrice} from '../../constants/defaultValues';
import StepperBottomRow from '../onboard/StepperBottomRow';
import CheckoutForm from '../../components/stripe/checkoutForm';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import YourProfession from '../onboarding-steps/YourProfession'
import { stripeCard } from 'src/socket';
import PriceCardSwms from 'src/components/_external-pages/pricing/priceCardSwms';

import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from 'src/assets';

function ChooseProfessions(props){
    let { stepperHandlers, jobdata, handleStartMembership, handleCustomSwms, handleStripeToken, updateToken, loading, setLoading, toast, customer, handleNext, isMobile} = props;
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
    const [manual, setManual] = useState(false);
    let [expiryDate, setExpiryDate] = useState('');
    const [paying, setPaying] = useState(false);
    const [cardAdded, setCardAdded] = useState(false);
    const [file, setFile] = useState(null);
    const fileInputRef = React.useRef(null);
    const [monthly, setMonthly] = useState(true);

    const handleSubmit = ()=>{
        // console.log('customer: ',customer);
        if(customer && customer?.sources?.data?.length>0 && !manual){
            if(customer?.subscriptions?.data[0] && customer.subscriptions?.data[0].status=='active'){
                handleNext();
            }else{
                toast("Your subscription was not started! please contact to support");
            }
            
        }else{
            // toast('warning', 'Please submit the card details first!');
            if(!manual){
                let submitButton = document.querySelector('.addCardButton');
                if(submitButton) submitButton.click();
            }else{
                // uploading own docs
                if(file && expiryDate){
                    if(expiryDate=='Invalid Date'){
                        toast('warning', 'Please select a valid expiry date');
                        return;
                    }
                    let today = new Date() / 1000;
                    let expDate = new Date(expiryDate) / 1000;
                    if(expDate<=today){
                        toast('warning', 'Invalid expiry date!');
                        return;
                    }
                    handleCustomSwms({file, customSwmsExpiryDate: new Date(expiryDate).toISOString().split('T')[0]});
                }else{
                    if(!file)
                        toast('warning', 'Please choose a valid SWMS document!')
                    if(!expiryDate)
                        toast('warning', 'Please choose an expiry date!')

                }
            }
            
        }
    }

    useEffect(()=>{
        if(jobdata && jobdata.id){ setFile(jobdata.customSwmsFile); setExpiryDate(jobdata.customSwmsExpiryDate) }
        if(jobdata && jobdata.id && jobdata.customSwmsFile && jobdata.professions.length==0) setManual(1);
        if(jobdata && jobdata.id && jobdata.professions.length>0) setManual(0);

        stripeCard(processStripeCard);
    },[])

    useEffect(()=>{
        if(customer && customer?.sources?.data?.length>0){
            setCardAdded(true);
            if(customer?.subscriptions?.data[0] && customer.subscriptions?.data[0].status=='active'){
                setPaying(true);
            }
        }
    },[customer])

    const processStripeCard = res=>{
        setLoading(false);
        if(res.success){
            setCardAdded(true);
        }
    }

    const handleSkip = e=>{
      // console.log('want to skip this step', jobdata);
      setLoading(true);
      updateToken({
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor
      });
    }
    const handleUploadClick=e=>{
        // console.log('refs: ',fileInputRef.current);
        fileInputRef.current.click();
      }

    const handleSelededFile = e=>{
        let file=e.target.files[0];
        if(file.type.includes('pdf')){
        //   fileUpload(file, res=>{
        //     setFileLoading(false);
        //     setFile(res.location);
        //   })
            setFile(file);
        } else
          toast('warning', 'please choose a pdf file!');
    }

    // choose  swms without card 
    // return (
    //     <Box>
              
    //         <ToggleButtonGroup
    //             color="primary"
    //             value={manual? '0': '1'}
    //             fullWidth
    //             sx={{my:2}}
    //             exclusive
    //             onChange={ (event, mode) => {
    //             setManual(mode==0? true: false);
    //             }}
    //         >
    //             <ToggleButton value="1">Let Wesafe Manage My SWMS</ToggleButton>
    //             <ToggleButton value="0">Upload My Own SWMS</ToggleButton>
    //         </ToggleButtonGroup>

    //         {
    //               manual? (
    //                   <Box>
    //                     <input
    //                         type="file"
    //                         className="custom-file-input"
    //                         id="customFile"
    //                         onChange={handleSelededFile}
    //                         // accept="image/*,video/*"
    //                         name="profilePic"
    //                         ref={fileInputRef}
    //                         required
    //                         style={{display:'none'}}
    //                     />
    //                         <Grid container spacing={1}>
    //                             <Grid item xs={12} >
    //                                 <Box
    //                                     component="form"
    //                                     noValidate
    //                                     autoComplete="off"
    //                                 >
    //                                     <Typography sx={{ color: 'text.secondary', mb: 1 }}>It is not recomemded to manage your own paperwork. Make sure everything is up to date or your the one who will pay the price. </Typography> 
    //                                     {
    //                                         !jobdata.customSwmsFile && file?(
    //                                             <Typography sx={{my:2}}>Document : {file?.name}</Typography>
    //                                         ):null
    //                                     }
    //                                     {
    //                                         jobdata.customSwmsFile? (
    //                                             <Typography sx={{my:2}}>Document : <a href={jobdata.customSwmsFile} target="_blank">{jobdata.customSwmsFile}</a></Typography>
                                                
    //                                         ):null
    //                                     }
    //                                     <Button onClick={handleUploadClick} fullWidth size="large" variant="outlined" sx={{mt:1}}>Upload Your SWMS</Button>
    //                                 </Box>
    //                             </Grid>
                                
    //                         </Grid>
    //                         <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
    //                   </Box>
    //               ):(
    //                   <Box>
    //                      <YourProfession {...props} />
    //                   </Box>
    //               )
    //           }
                
    //     </Box>
    // );


    // choose swms with card and membership first
        return (
            <Box>

              {/* if user is not yet have a valid sub, show both options */}
              {
                  !paying && (
                      <ToggleButtonGroup
                          color="primary"
                          value={manual? '0': '1'}
                          fullWidth
                          sx={{my:2}}
                          exclusive
                          onChange={ (event, mode) => {
                          setManual(mode==0? true: false);
                          }}
                      >
                          <ToggleButton value="1">Let Wesafe Manage My SWMS</ToggleButton>
                          <ToggleButton value="0">Upload My Own SWMS</ToggleButton>
                      </ToggleButtonGroup>
                  )
              }
              
              {/* if user chooses manual then show manual */}
              {
                  manual &&  (
                      <Box>
                        <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={handleSelededFile}
                            // accept="image/*,video/*"
                            name="profilePic"
                            ref={fileInputRef}
                            required
                            style={{display:'none'}}
                        />
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Typography sx={{ color: 'text.secondary', mb: 1 }}>It is not recomemded to manage your own paperwork. Make sure everything is up to date or your the one who will pay the price. </Typography> 
                                        <Grid item xs={12} sx={{my:2}}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker 
                                                fullWidth
                                                inputFormat="dd-MM-yyyy"
                                                label="Expiry Date"
                                                openTo="day"
                                                views={['day','month','year']}
                                                value={expiryDate}
                                                onChange={(newValue) => {
                                                    setExpiryDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                            </LocalizationProvider>

                                        </Grid>
                                        {
                                            !jobdata.customSwmsFile && file?(
                                                <Typography sx={{my:2}}>Document : {file?.name}</Typography>
                                            ):null
                                        }
                                        {
                                            jobdata.customSwmsFile? (
                                                <Typography sx={{my:2}}>Document : <a href={jobdata.customSwmsFile} target="_blank">{jobdata.customSwmsFile?.length>50? jobdata.customSwmsFile.slice(0,50)+'...': jobdata.customSwmsFile}</a></Typography>
                                                
                                            ):null
                                        }
                                        <Button onClick={handleUploadClick} fullWidth size="large" variant="outlined" sx={{mt:1}}>Select Your SWMS</Button>
                                    </Box>
                                </Grid>
                                
                            </Grid>
                            <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
                      </Box>
                  )   
              }

              {/* if he want to pay but haven't added his card yet */}
              {
                !manual && !cardAdded && (
                    <Box>
                        <Typography sx={{ color: 'text.secondary', mb: 1 }}>By using WeSafe to manage your safety documentation your documents will always be up to scratch and ready to send or produce for any builder or any safety inspector anytime, anywhere.</Typography>
                        <Typography sx={{ color: 'text.secondary', mb: 1, fontWeight:'bold', }}>Why use WeSafe?</Typography>
    
                            <List dense={dense}>
    
                                <ListItem>
                                <ListItemIcon>
                                    <DoneIcon sx={{ color:'#0eea0e' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Produce digital up  to date safety documentation on the spot"
                                    sx={{ color: 'text.secondary'}}
                                    secondary={secondary ? 'Secondary text' : null}
                                />
                                </ListItem>
                                <ListItem>
                                <ListItemIcon>
                                    <DoneIcon sx={{ color:'#0eea0e' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Have up to date documents for safety inspectors "
                                    secondary={secondary ? 'Secondary text' : null}
                                    sx={{ color: 'text.secondary'}}
                                />
                                </ListItem>
                                <ListItem>
                                <ListItemIcon>
                                    <DoneIcon sx={{ color:'#0eea0e' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="You won’t be a builder’s safety liability anymore"
                                    secondary={secondary ? 'Secondary text' : null}
                                    sx={{ color: 'text.secondary'}}
                                />
                                </ListItem>
                                <ListItem>
                                <ListItemIcon>
                                    <DoneIcon sx={{ color:'#0eea0e' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Share your safety documents with any builder anytime"
                                    secondary={secondary ? 'Secondary text' : null}
                                    sx={{ color: 'text.secondary'}}
                                />
                                </ListItem>
                                <ListItem>
                                <ListItemIcon>
                                    <DoneIcon sx={{ color:'#0eea0e' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Never worry about safety paperwork on the job again"
                                    secondary={secondary ? 'Secondary text' : null}
                                    sx={{ color: 'text.secondary'}}
                                />
                                </ListItem>
                                <ListItem>
                                <ListItemIcon>
                                    <DoneIcon sx={{ color:'#0eea0e' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Customer support "
                                    secondary={secondary ? 'Secondary text' : null}
                                    sx={{ color: 'text.secondary'}}
                                />
                                </ListItem>
                                <ListItem>
                                <ListItemIcon>
                                    <DoneIcon sx={{ color:'#0eea0e' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Having WeSafe manage and create your SWMS and other documents is cheapter then getting a safety inspector to make you one"
                                    secondary={secondary ? 'Secondary text' : null}
                                    sx={{ color: 'text.secondary'}}
                                />
                                </ListItem>
    
                            </List>
    
                        <Typography sx={{ color: 'text.secondary', mb: 4 }}>Enter a credit/debit card below. Memberships can be cancelled at anytime. 
                        {/* <strong>Your card will not be charged at this stage.</strong> */}
                        </Typography>
                        <Card style={{width: '100%', padding: '20px'}} >
                            <CheckoutForm hideButton={true} handleToken={handleStripeToken} />
                        </Card>
    
                        <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
    
                    </Box>
                )
              }

              {/* if card added but not paying and on the wesafe tab */}
              {
                cardAdded && !paying && !manual && (
                    <Box>
                        <Typography sx={{ color: 'text.secondary', mb: 1 }}>
                            By using WeSafe to manage your safety documentation your documents will always be up to scratch and ready to send or produce for any builder or any safety inspector anytime, anywhere.
                        </Typography>

                        <ToggleButtonGroup
                            color="primary"
                            value={monthly? '1': '0'}
                            fullWidth
                            sx={{my:2}}
                            exclusive
                            onChange={ (event, mode) => {
                                setMonthly(event.target.value==1? true: false);
                            }}
                        >
                            <ToggleButton value="1">Month</ToggleButton>
                            <ToggleButton value="0">Year</ToggleButton>
                        </ToggleButtonGroup>
                        
                        {
                            monthly? (
                                <PriceCardSwms 
                                    loading={loading}
                                    monthly={true}
                                    handleSubmit={handleStartMembership}
                                    icon = {<PlanStarterIcon />}
                                    price = {monthlyMembershipPrice}
                                    role = {3}
                                    caption = {'Manage your team\'s WH&S documents and know your team is compliant '}
                                    lists = {[
                                        { text: 'Manage WH&S Documents', isAvailable: true },
                                        { text: 'Produce WH&S Documents', isAvailable: true },
                                        { text: 'Manage Your Teams WHS', isAvailable: true },
                                        { text: 'Track Employees', isAvailable: true },
                                        { text: 'Manage Job Sites GEO Location', isAvailable: true }
                                    ]}
                                    labelAction = {'Start Monthly Subscription'}
                                />
                            ):(
                                <PriceCardSwms 
                                    loading={loading}
                                    monthly={false}
                                    handleSubmit={handleStartMembership}
                                    icon = {<PlanPremiumIcon />}
                                    price = {yearlyMembershipPrice}
                                    role = {3}
                                    caption = {'Manage your team\'s WH&S documents and know your team is compliant '}
                                    lists = {[
                                        { text: 'Manage WH&S Documents', isAvailable: true },
                                        { text: 'Produce WH&S Documents', isAvailable: true },
                                        { text: 'Manage Your Teams WHS', isAvailable: true },
                                        { text: 'Track Employees', isAvailable: true },
                                        { text: 'Manage Job Sites GEO Location', isAvailable: true }
                                    ]}
                                    labelAction = {'Start Yearly Subscription'}
                                />
                            )
                        }
                        
                        
                    </Box>
                )
              }


              {/* if card added and sub has started and on wesafe tab, then show professions tab */}
              {
                cardAdded && paying && !manual && (
                    <Box>
                        <YourProfession {...props} /> 
                    </Box>
                )
              }
            </Box>
            
        )
        
    // }
    // else if(cardAdded && !paying){
    //     return (
    //         <Box>
    //             <LoadingButton
    //                 // type="submit"
    //                 onClick={handleStartMembership}
    //                 loading={loading}
    //                 variant="outlined"
    //             >
    //                 Start Membership
    //             </LoadingButton>
    //             <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
    //         </Box>
    //     )
    // }else{
    //     return (
    //         <div><YourProfession {...props} /> </div>
    //     ) 
    // }
   



  }

  export default ChooseProfessions;