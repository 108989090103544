import { styled } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

// ----------------------------------------------------------------------

const TableStyle = styled('div')(({ theme }) => {
  const isLight = theme.palette.mode === 'light';
  const isRTL = theme.direction === 'rtl';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return {
  //   borderRadius: theme.shape.borderRadius,
  //   border: `solid 1px ${theme.palette.grey[500_32]}`,
    '& .responsiveTable': {
    //   border: `1px solid ${theme.palette.grey[500]}`,
    //   borderRadius: 5,
    //   overflow: 'hidden',
    // background: isMobile? 'red': 'blue',
       '& thead': {
        
            '& tr':{
                textAlign: 'left',
                '& th':{
                    padding: '18px',
                }
            },
        },
       '& tbody': {
        
            '& tr':{
                cursor: 'pointer',
                borderRadius: '6px',
                border: 'none',
                marginBottom: isMobile? '8px':'0px',
                // background: isMobile? theme.palette.background.neutral: theme.palette.background.paper,
                '&:hover':{
                    background: theme.palette.background.neutral,
                    borderRadius: '5px',
                },
                '& td':{
                    padding: '18px',
                }
            },
        },

    },
    // '& .se-container': {
    //   '& .se-toolbar': {
    //     background: theme.palette.background.paper,
    //     '& .se-btn':{
    //       color: theme.palette.text.primary,
    //       '&:hover': {
    //         background: theme.palette.background.default
    //       }
    //     },
    //     '& .active':{
    //       color: theme.palette.text.primary,
    //       background: theme.palette.background.neutral
    //     },
    //     '& .se-btn-module-border': {
    //       border: 0
    //     }
    //   },
    //   '& .se-wrapper-inner': {
    //     background: theme.palette.background.paper,
    //     color: theme.palette.text.primary,
    //   },
    //   '& .se-resizing-bar': {
    //     background: theme.palette.background.paper,
    //     color: theme.palette.text.primary,
    //     borderTop: `1px solid ${theme.palette.grey[500]}`
    //   }
    // }
   }
});

export default TableStyle;
