import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Link, Container, Typography, Tooltip, Button, TextField, ButtonGroup } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts

import AuthLayout from '../../layouts/AuthLayout';
// snackbar
import { useSnackbar } from 'notistack5';
import { MIconButton } from '../../components/@material-extend';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import LoadingScreen from '../../components/LoadingScreen';
// components
import {useNavigate} from 'react-router-dom';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';


import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { RegisterForm } from '../../components/authentication/register';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useSettings from '../../hooks/useSettings';
import Vcode from '../../pages/onboarding-steps/VerifyCode';
import AddNumber from '../../pages/onboarding-steps/EnterPhone';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import CompanyName from '../../pages/onboarding-steps/CompanyName';
import QABN from '../../pages/onboarding-steps/Q-ABN';
import PointOfContact from '../../pages/onboarding-steps/PointOfContact';
import Address from '../../pages/onboarding-steps/Address';
import ContractTradeAgreement from '../../pages/onboarding-steps/ContractTradeAgreement';
import WorkHealthSafetyManagementPlan from '../../pages/onboarding-steps/WorkHealthSafetyManagementPlan';
import WorkplaceInductionRecord from '../../pages/onboarding-steps/WorkplaceInductionRecord';
import ContractTradeAgreementAccept from '../../pages/onboarding-steps/ContractTradeAgreementAccept';
import TermsAndConditions from '../../pages/onboarding-steps/TermsAndConditions';
import WHSAgreementAccept from '../../pages/onboarding-steps/WHSAgreementAccept';
import WIRAgreementAccept from '../../pages/onboarding-steps/WIRAgreementAccept';
import EditYourSWMS from '../../pages/onboarding-steps/EditSWMS';
import InviteUsers from '../../pages/onboarding-steps/InviteUsers';
import BlueCardUpload from '../../pages/onboarding-steps/BlueCardUpload';
import QBCClicense from '../../pages/onboarding-steps/QBCClicense';
import HazardousSubstanceRegister from '../../pages/onboarding-steps/HazardousSubstanceRegister';
import WorkCover from '../../pages/onboarding-steps/WorkCover';
import TestTag from '../../pages/onboarding-steps/TestAndTag';
import Phone from '../../pages/onboarding-steps/Phone';
import YourProfession from '../../pages/onboarding-steps/YourProfession';
import {userRoles} from '../../constants/defaultValues'
import PublicLiabilityUpload from '../../pages/onboarding-steps/PublicLiabilityUpload';
import PeriodTradeContractUpload from '../../pages/onboarding-steps/PeriodTradeContractUpload';
import AnyOtherLicence from '../../pages/onboarding-steps/AnyOtherLicence';
import ChooseProfessions from '../../pages/onboarding-steps/chooseProfessions';
import ProfessionsAccept from '../../pages/onboarding-steps/ProfessionAccept';
import { userData, login, loginOk, sendSms,
  confirmCode, updateUser, userJobData, newEmployee,newSubcontractorHazards,
  updateSupervisor, newWorkCover, newTesttag, newProfessions, updateProfessions, stripeCustomer, stripeCard,
  updateBuilder, updateSubcontractor, newBuilder, newEWPCard,newWorkingHeightsCard,
  newSubcontractor, newInvite, newBlueCard, newInsurance, newTradeContract, newWirPlan, newWhsPlan,
  newLicense, updateToken, loginOff, updateEmployee, jobsites, updateUserDoc, newUserDoc, deleteUserDocs, createDefaultUserDocs, newProfession, jobsiteDetail, startMembership, newCustomSwms, acceptEmployerSwms } from '../../socket';
import '../../assets/styles/carousel.css';
import {withGetScreen} from 'react-getscreen'
import {screenOptions} from '../../constants/defaultValues';
import PaymentInfo from '../onboarding-steps/PaymentInfo';
// ----------------------------------------------------------------------

const titleContractTradeAgreement = 'Contract Trade Agreement';
const titleAcceptSWMS = 'Accept SWMS';

const builderSteps = [
      // {
      //   title: 'Phone',
      //   component: (props) => <div><Phone {...props} /> </div>,
      // },
      // {
      //   title: 'Verify Code',
      //   component: (props) => <div><Vcode {...props} /> </div>,
      // },
      {
        title: 'ABN',
        component: (props) => <div><QABN {...props} /> </div>,
      },
      {
        title: 'Company',
        component: (props) => <div><CompanyName {...props} />  </div>,
      },

      {
        title: 'Point of Contact',
        component: (props) => <div><PointOfContact {...props} />  </div>,
      },
      {
        title: 'Address',
        component: (props) => <div><Address {...props} /> </div>,
      },

      {
        title: 'QBCC License',
        component: (props) => <div><QBCClicense {...props} /> </div>,
      },

	  {
		title: 'Public Liability',
		component: (props) => <div><PublicLiabilityUpload {...props} /> </div>,
	  },
    {
      title: 'SWMS',
      component: (props) => <div><ChooseProfessions {...props} /> </div>,
    },
    {
      title: titleAcceptSWMS,
      component: (props) => <div><EditYourSWMS {...props} /> </div>,
    },

	  {
        title: titleContractTradeAgreement,
        component: (props) => <div><ContractTradeAgreement {...props} /> </div>,
      },

      {
        title: 'WHS',
        component: (props) => <div><WorkHealthSafetyManagementPlan {...props} /> </div>,
      },

      // {
      //   title: 'Wir',
      //   component: (props) => <div><WorkplaceInductionRecord {...props} /> </div>,
      // },
	  {
        title: 'Terms of Service',
        component: (props) => <div><TermsAndConditions {...props} /> </div>,
      },
      // {
      //   title: 'Payment Info',
      //   component: (props) => <div><PaymentInfo {...props} /> </div>,
      // }
]
const subcontractorSteps = [
  // {
  //   title: 'Phone',
  //   component: (props) => <div><Phone {...props} /> </div>,
  // },
  // {
  //   title: 'Verify Code',
  //   component: (props) => <div><Vcode {...props} /> </div>,
  // },
  {
    title: 'ABN',
    component: (props) => <div><QABN {...props} /> </div>,
  },
  {
    title: 'Company',
    component: (props) => <div><CompanyName {...props} /> </div>,
  },
  {
    title: 'Point of Contact',
    component: (props) => <div><PointOfContact {...props} /> </div>,
  },
  {
    title: 'Address',
    component: (props) => <div><Address {...props} /> </div>,
  },
  {
    title: 'White/Blue Card',
    component: (props) => <div><BlueCardUpload {...props} /> </div>,
  },
  {
    title: 'QBCC License',
    component: (props) => <div><QBCClicense {...props} /> </div>,
  },
  {
    title: 'Public Liability',
    component: (props) => <div><PublicLiabilityUpload {...props} /> </div>,
  },
  {
    title: 'Work Cover',
    component: (props) => <div><WorkCover {...props} /> </div>,
  },
  {
    title: titleContractTradeAgreement,
    component: (props) => <div><ContractTradeAgreementAccept {...props} /> </div>,
  },
  // {
  //   title: 'Work Health Safety',
  //   component: (props) => <div><WHSAgreementAccept {...props} /> </div>,
  // },
  // {
  //   title: 'Work Place Induction',
  //   component: (props) => <div><WIRAgreementAccept {...props} /> </div>,
  // },
  {
    title: 'SWMS',
    component: (props) =>  <div><ChooseProfessions {...props} /> </div>,
  },
  {
    title: titleAcceptSWMS,
    component: (props) => <div><EditYourSWMS {...props} /> </div>,
  },
  // {
  //   title: 'Test & Tag',
  //   component: (props) => <div><TestTag {...props} /> </div>,
  // },
  {
    title: 'Hazardous Substances',
    component: (props) => <div><HazardousSubstanceRegister {...props} /> </div>,
  },

  {
	title: 'Terms of Service',
	component: (props) => <div><TermsAndConditions {...props} /> </div>,
  },
  // {
  //   title: 'Payment Info',
  //   component: (props) => <div><PaymentInfo {...props} /> </div>,
  // }
]
const employeeSteps = [
  // {
  //   title: 'Phone',
  //   component: (props) => <div><Phone {...props} /></div>
  // },
  // {
  //   title: 'Verify Code',
  //   component: (props) => <div><Vcode {...props} /></div>
  // },
  {
    title: 'White/Blue Card',
    component: (props) => <div><BlueCardUpload {...props} /></div>
  },
  // {
  //   title: 'ABN',
  //   component: (props) => <div><QABN {...props} /></div>
  // },
  // {
//     title: 'Point of Contact',
//     component: (props) => <div><PointOfContact {...props} /></div>
//   },
  // {
  //   title: 'Professions',
  //   component: (props) => <div><YourProfession {...props} /> </div>,
  // },
  // {
  //   title: 'SWMS',
  //   component: (props) => <div><EditYourSWMS {...props} /> </div>,
  // },
  // {
  //   title: 'Test & Tag',
  //   component: (props) => <div><TestTag {...props} /> </div>
  // },
 //  {
//     title: titleContractTradeAgreement,
//     component: (props) => <div><ContractTradeAgreementAccept {...props} /> </div>
//   },
  // {
  //   title: 'Work Health Safety',
  //   component: (props) => <div><WHSAgreementAccept {...props} /> </div>,
  // },
  // {
  //   title: 'Work Place Induction',
  //   component: (props) => <div><WIRAgreementAccept {...props} /> </div>,
  // },
  {
    title: titleAcceptSWMS,
    component: (props) => <div><ProfessionsAccept {...props} /> </div>,
  },
  {
        title: 'Terms of Service',
        component: (props) => <div><TermsAndConditions {...props} /> </div>,
      },
  // {
  //   title: 'Any Extra Licenses',
  //   component: (props) => <div><AnyOtherLicence {...props} /> </div>
  // }
]
const supervisorSteps = [
  // {
  //   title: 'Phone',
  //   component: (props) => <div><Phone {...props} /></div>
  // },
  // {
  //   title: 'Verify Code',
  //   component: (props) => <div><Vcode {...props} /></div>
  // },
  {
    title: 'White/Blue Card',
    component: (props) => <div><BlueCardUpload {...props} /></div>
  },
  {
    title: 'QBCC License',
    component: (props) => <div><QBCClicense {...props} /> </div>,
  },
  // {
  //   title: 'ABN',
  //   component: (props) => <div><QABN {...props} /></div>
  // },
  // {
  //   title: 'Point of Contact',
  //   component: (props) => <div><PointOfContact {...props} /></div>
  // },
  // {
  //   title: 'SWMS',
  //   component: (props) => <div><YourProfession {...props} /> </div>,
  // },
  // {
  //   title: 'Accept SWMS',
  //   component: (props) => <div><EditYourSWMS {...props} /> </div>,
  // },
//   {
//     title: titleContractTradeAgreement,
//     component: (props) => <div><ContractTradeAgreementAccept {...props} /> </div>
//   },
  // {
  //   title: 'Work Health Safety',
  //   component: (props) => <div><WHSAgreementAccept {...props} /> </div>,
  // },
  // {
  //   title: 'Work Place Induction',
  //   component: (props) => <div><WIRAgreementAccept {...props} /> </div>,
  // },
  {
        title: 'Terms of Service',
        component: (props) => <div><TermsAndConditions {...props} /> </div>,
      },
  // {
  //   title: 'Any Extra Licenses',
  //   component: (props) => <div><AnyOtherLicence {...props} /> </div>
  // }
]


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  maxHeight: '100%',
  minHeight: '100vh',
  // overflow:'hidden',
  // flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // padding: theme.spacing(12, 0)
}));

const RootStyle2 = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',

}));
// ----------------------------------------------------------------------
var active = 0;
var jdata = {};
var udata = {};
var coompleted = {};
var apiCalled = 0;

function OnBoard(props) {
  // states
  var [steps, setSteps] = useState([
    {
      title: 'Phone',
      component: (props) => <div><Phone {...props} /> </div>,
    },
    {
      title: 'Verify Code',
      component: (props) => <div><Vcode {...props} /> </div>,
    }
  ]);
  var [occupation, setOccupation] =  useState(1); // 1=>builder, 2=> subcontractor, 3=> employee
  var [jobdata, setJobdata] =  useState({}); // 1=>builder, 2=> subcontractor, 3=> employee
  var [phone, setPhone] = useState(''); // for input field
  var [currentSlide, setCurrentSlide] = useState(0);
  var [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  var [startLoding, setStartLoading] = useState(true);
  var [user, setUser] = useState(null);

  const [customer, setCustomer] = useState(null);
  var customerData = null;
  const [activeStep, setActive] = useState(0);
  const [completed, setComp] = useState({});
  // contexts
  const { method, logout, updateLogin } = useAuth();
  const { themeStretch } = useSettings();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();



  const setActiveStep = number =>{
    active = number;
    setActive(active);
  }

  const setCompleted = newCompleted=>{
	  coompleted = {...newCompleted};
	  setComp(coompleted);
  }



  useEffect(()=>{
    login(processLogin)
    // userData(processUserData);
    confirmCode(processConfirmCode);
    sendSms(processMessage);
    userJobData(processUserJobData);
    updateBuilder(processUpdateBuilder);
    updateEmployee(processUpdateEmployee);
    updateSupervisor(processUpdateSupervisor);
    updateSubcontractor(processUpdateSubcontractor);
    updateToken(processUpdateToken);
    newInsurance(processNewInsurance);
    newWorkCover(processNewWorkcover);
    newTesttag(processNewTesttag);
    newEWPCard(processNewEWPCard);
	newSubcontractorHazards(processSubHazards);
  acceptEmployerSwms(processAcceptEmployerSwms);
    updateUserDoc(processUpdateUserDoc);
    newLicense(processLicense);
	newProfessions(processNewProfessions);
    newProfession(processNewProfession);
    stripeCustomer(processCustomer);
    stripeCard(processStripeCard);
    startMembership(processStartMembership);
    newCustomSwms(processNewCustomSwms);
	newBlueCard(processNewBlueCard);
    newWorkingHeightsCard(processNewWorkingHeightsCard)
    newTradeContract(processNewTradeContract);
    newUserDoc(processNewUserDoc);
    deleteUserDocs(processDeleteUserDocs);
    createDefaultUserDocs(processDefaultUserDocs);
	newWhsPlan(processNewwhsPlan)
    newInvite(processNewInvite);
    updateUser(processUpdateUser);
    // document.querySelector('.carousel__slider--horizontal').style.overflow = 'unset';

    processLogin(loginOk());

    return ()=>{
      // userData('off');
      userJobData('off');
      stripeCustomer(processCustomer, 'off');
      updateUserDoc(processUpdateUserDoc, 'off');
      newProfession(processNewProfession, 'off');
      deleteUserDocs(processDeleteUserDocs, 'off');
      createDefaultUserDocs(processDefaultUserDocs, 'off');
      acceptEmployerSwms(processAcceptEmployerSwms, 'off');
      newUserDoc(processNewUserDoc, 'off');
      newCustomSwms(processNewCustomSwms, 'off');
      startMembership(processStartMembership, 'off');
      loginOff(processLogin);
    }
  },[])

  const processLogin = (login)=>{
		//console.log("login",login)
		if(login.auth){

		  // userData();
		  if(!apiCalled){
			console.log('calling jobdata');
			stripeCustomer();
			userJobData();
			apiCalled = 1;
			setTimeout(()=>{
				apiCalled = 0;
			},5000)
		  }else{
			  console.log('tried calling jobdata but waiting for the timeout');
		  }


		}
	}

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const processNewCustomSwms = res=>{
    console.log('custom swms: ',res);
    setLoading(false);
    if(res.success){
      jdata = {...jdata, customSwmsFile: res?.data?.file, customSwmsExpiryDate: res?.data?.customSwmsExpiryDate};
      setJobdata({...jdata});
      chooseProcess(jdata, jdata);
      // gotoNextStep();
     }else{
       toast('warning', res.msg);
     }
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const processStripeCard = res=>{
    console.log('stripe card: ',res);
    setLoading(false);
    if(res.success){
      // gotoNextStep();
      // updateToken({
      //       isBuilder: jdata.isBuilder,
      //       isSubcontractor: jdata.isSubcontractor
      //     });
    }else{
      toast('warning', res.msg);
    }
  }
  const processStartMembership = res=>{
    setLoading(false);
    console.log('start membership res: ',res);
    if(res.success){
     // 
    }else{
      toast('warning', res.msg);
    }
  }
  const processCustomer = res=>{
    console.log('customer: ',res);
    setLoading(false);
    if(res && res.success && res.data){
      setCustomer(res.data);
      customerData = res.data;
      // if(res.data.sources && res.data.sources.data && res.data.sources.data.length>0 && res.data.jobdataCall!=0){
      //   setTimeout(()=>{
      //     userJobData();
      //   },1000)
      // }

    }
  }

  const processUpdateUserDoc = res=>{
    setLoading(false);
    if(res.success){
      let section = res.data;
      if(section.docType=='ptc'){
        let tradeContract = jdata.tradeContract;
        tradeContract.map(s=>{
          if(section.id==s.id){
            s.title = section.title;
            s.content = section.content;
          }
        })
        jdata = {...jdata, tradeContract};
        setJobdata({...jdata});
      }
      if(section.docType=='whs'){
        let whsPlan = jdata.whsPlan;
        whsPlan.map(s=>{
          if(section.id==s.id){
            s.title = section.title;
            s.content = section.content;
          }
        })
        jdata = {...jdata, whsPlan};
        setJobdata({...jdata});
      }

      toast('success', "Section updated successfully")

    }
  }

  const processNewProfession = res=>{
    if(res.success){
      let professionList = jdata.professionList;
        professionList.unshift(res.data);
        jdata = {...jdata, professionList};
        setJobdata({...jdata});
    }
  }


  const processNewUserDoc = res=>{
    setLoading(false);
    if(res.success){
      let section = res.data;
      if(section.docType=='ptc'){
        let tradeContract = jdata.tradeContract;
        tradeContract.push(section);
        jdata = {...jdata, tradeContract};
        setJobdata({...jdata});
      }
      if(section.docType=='whs'){
        let whsPlan = jdata.whsPlan;
        whsPlan.push(section)
        jdata = {...jdata, whsPlan};
        setJobdata({...jdata});
      }
      toast('success', "New section added successfully")
    }
  }
  const processDefaultUserDocs = res=>{
    setLoading(false);
    if(res.success){
      let doc = res.data;
      let docType = res.docType;
      if(docType=='ptc'){

        jdata = {...jdata, tradeContract: [...doc]};
        setJobdata({...jdata});
      }
      if(docType=='whs'){

        jdata = {...jdata, whsPlan: [...doc]};
        setJobdata({...jdata});
      }
      toast('success', "Default template added successfully")
    }
  }
  const processDeleteUserDocs = res=>{
    setLoading(false);
    if(res.success){
      let data = res.data;
      if(data.docType=='ptc'){
        jdata = {...jdata, tradeContract: []};
        setJobdata({...jdata});
      }
      if(data.docType=='whs'){
        jdata = {...jdata, whsPlan: []};
        setJobdata({...jdata});
      }
      toast('success', 'Document cleared successfully');

    }
  }

  const processLicense = res=>{
    setLoading(false);
    if(res.success){
      jdata = {...jdata, license: res.data};
      setJobdata({...jdata});
      gotoNextStep();
      // jumpToPendingStep(jdata);
    }
  }
  const processNewEWPCard = res=>{
    setLoading(false);
    console.log('card res: ',res);
  }
  const processNewWorkingHeightsCard = res=>{
    console.log('card res: ',res);
    setLoading(false);
  }

  const processUpdateUser = res=>{
    // user phone updated, create new builder here
    // console.log('calling new builder');
    // console.log('jobdata: ',)

    if(res.createBuilder)
      newBuilder();
    else
      chooseProcess(res, jdata)
  }

  const processUpdateToken = async res=>{
    // console.log('got new token: ', res);
    loginOff(processLogin);
    var user = res.user;
    // console.log('user: ',user);
    setTimeout(()=>{
      if(user.roleId == userRoles.builder) navigate('/dashboard/checklist');
      else if(user.roleId == userRoles.subcontractor) navigate('/dashboard/checklist');
      else if(user.roleId == userRoles.employee) navigate('/dashboard/checklist');
      else if(user.roleId == userRoles.officeadmin) navigate('/dashboard/checklist');
      else if(user.roleId == userRoles.supervisor) navigate('/dashboard/checklist');
      else navigate('/dashboard');

    },1000)
    await updateLogin(res);

  }

  const processAcceptEmployerSwms = res=>{
    setLoading(false);
    console.log('accept swms', res);
    if(res.success){
      jdata = {...jdata, professions: res?.Professions, employerSwmsFile: res?.employerSwmsFile};
      setJobdata({...jdata});
      gotoNextStep();
    }else{
      toast('warning', res.msg);
    }
  }
  const processMessage = res=>{
    console.log('send message: ',res);
    setLoading(false);
    if(res.success){
      handleNext();
    }else{
      toast('warning', res.msg);
    }
  }

  const processNewInvite = res=>{
    if(res.success){
      toast('success', 'invite sent!')
    }else{
      toast('warning', res.msg);
    }
  }

  const processNewInsurance = res=>{
    setLoading(false);
	if(res.success){
		jdata = {...jdata, insurance: res.data};
		setJobdata({...jdata});
		gotoNextStep();
	}
  }
  const processSubHazards = res=>{
	setLoading(false);
	if(res.success){
		jdata = {...jdata, subcontractorHazards: res.data};
		setJobdata({...jdata});
		gotoNextStep();
	}
  }
  const processNewWorkcover = res=>{
    setLoading(false);
	if(res.success){
		jdata = {...jdata, workCover: res.data};
		setJobdata({...jdata});
		gotoNextStep();
	}
  }
  const processNewTesttag = res=>{
    setLoading(false);
	if(res.success){
		jdata = {...jdata, testtag: res.data};
		setJobdata({...jdata});
		gotoNextStep();
//    if(jdata.isSubcontractor && !udata.invitedBy) gotoNextStep();
	}
  }
  const processNewTradeContract = res=>{
    setLoading(false);

	if(res.success){
		jdata = {...jdata, tradeContract: res.data};
		setJobdata({...jdata});
		gotoNextStep();
	}
  }
  const processNewwhsPlan = res=>{
	 setLoading(false);

	if(res.success){
		jdata = {...jdata, whsPlan: res.data};
		setJobdata({...jdata});
		gotoNextStep();
	}
  }
  const processNewProfessions = (res)=>{
	setLoading(false);

	if(res.success){
		jdata = {...jdata, professions: res.data};
		setJobdata({...jdata});
    if(res.donotProceed){
      // do nothing
    }else
      gotoNextStep();

	}
  }
  const processNewBlueCard = res=>{
	setLoading(false);

	if(res.success){
		jdata = {...jdata, blueCard: res.data};
		setJobdata({...jdata});
		gotoNextStep();
	}
  }

  let processConfirmCode = (res)=>{
    console.log('confirm code: ',res);
    // console.log('phone here: ',phone);
    // setLoading(false);
    if(res.success){
      // also update the phone number for this user
      // let number = '+61'+document.querySelector('#phoneNumber').value;
      updateUser({phone: res.phone});
      // setTimeout(()=>newBuilder(),300)

      // gotoNext();
    }else{
      toast('warning',res.msg);
    }

  }

  const toast = (type, message)=>{
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }
  const gotoNextStep = ()=>{
	setActiveStep(active+1);
	setCompleted({...coompleted, [active - 1]: true});
  }

  const processUpdateBuilder = res=>{

    setLoading(false);
    jdata = {...jdata, ...res.data};
    setJobdata({...jdata});
    gotoNextStep();
    if(jdata.termsAcceptedOn){
      updateToken({
        isBuilder: true
      });
    }
    // jumpToPendingStep(jdata);
    // handleNext();
  }
  const processUpdateEmployee = res=>{
    jdata = {...jdata, ...res.data};
    setJobdata({...jdata});
    gotoNextStep();
    setLoading(false);
    if(jdata.termsAcceptedOn){
      updateToken({
        isEmployee: true
      });
    }
  }
  const processUpdateSupervisor = res=>{
    jdata = {...jdata, ...res.data};
    setJobdata({...jdata});
    gotoNextStep();
    setLoading(false);
    if(jdata.termsAcceptedOn){
      updateToken({
        isSupervisor: true
      });
    }
  }
  const processUpdateSubcontractor = res=>{
    jdata = {...jdata, ...res.data};
    setJobdata({...jdata});
    gotoNextStep();
    setLoading(false);
    if(!udata.invitedBy && res.data.skipDoc) gotoNextStep();
    if(jdata.termsAcceptedOn){
      updateToken({
        isSubcontractor: true
      });
    }
  }

  // const processUserJobData = res=>{
  //   console.log('got job data: ',res);
  //   setStartLoading(false);
  //   if(res.success){
  //     let user = {...res.user};
  //     let job = {...res.data};
  //     if(!user.phone){
  //       setCurrentSlide(0);
  //     }else if(job && job.id){

  //       if(job.isBuilder){
  //         setOccupation(1);
  //         jumpToPendingSlide(job);
  //       }else if(job.isSubcontractor){
  //         setOccupation(2);
  //         jumpToPendingSlide(job);
  //       }else if(job.isEmployee){ // employee
  //         setOccupation(3);
  //         jumpToPendingSlide(job);
  //       }else if(job.isSupervisor){
  //         setOccupation(4);
  //         jumpToPendingSlide(job);
  //       }else{
  //         // do nothin!!! there was an error

  //       }

  //       setJobdata({...job});


  //       // setOccupations({...Occupations});

  //     }

  //   }else{
  //     toast('warning', res.msg);
  //   }
  // }

  const chooseProcess = (role, job)=>{
    // console.log('role: ',role, job)
    setLoading(false);
    setStartLoading(false);
    if(role.isBuilder){
       // remove accept swms step if he uploads his own swms file
      if(job && job.professions.length==0 && job.customSwmsFile){
        let swmsAcceptIndex = builderSteps.findIndex(item => item.title == titleAcceptSWMS);
        if(swmsAcceptIndex != -1){
          builderSteps.splice(swmsAcceptIndex, 1);
        }
      }
      // console.log('new bulider steps: ',builderSteps);
      setSteps(builderSteps);

    }else if(job.isSubcontractor){
	  // Remove contract step if subbie not linked to a builder
      if(job && !job.tradeContract){
        let indexPTC = subcontractorSteps.findIndex(item => item.title == titleContractTradeAgreement);
        if(indexPTC != -1){
          subcontractorSteps.splice(indexPTC, 1);
        }
      }

      // remove accept swms step if he uploads his own swms file
      if(job && job.professions.length==0 && job.customSwmsFile){
        let swmsAcceptIndex = subcontractorSteps.findIndex(item => item.title == titleAcceptSWMS);
        if(swmsAcceptIndex != -1){
          subcontractorSteps.splice(swmsAcceptIndex, 1);
        }
      }
      setSteps(subcontractorSteps);
    }else if(job.isEmployee){ // employee
      setSteps(employeeSteps);
    }else if(job.isSupervisor){
      setSteps(supervisorSteps);
    }else{
      // do nothin!!! there was an error
    }

    jumpToPendingStep(job);
  }
  const processUserJobData = res=>{
    console.log('got job dat: ',res);
    setStartLoading(false);
    setLoading(false);
    if(res.success){
      let user = {...res.user};
      let job = {...res.data};
      udata = user;
      if(!user.phone){
        setActiveStep(0);
        setJobdata({...job});
        jdata = {...job};
      }else if(job && job.id){
        if(job.isBuilder){
          setOccupation(1);
          if(job && job.professions.length==0 && job.customSwmsFile){
            let swmsAcceptIndex = builderSteps.findIndex(item => item.title == titleAcceptSWMS);
            if(swmsAcceptIndex != -1){
              builderSteps.splice(swmsAcceptIndex, 1);
            }
          }
          setSteps(builderSteps);
          // jumpToPendingSlide(job);
        }else if(job.isSubcontractor){
          setOccupation(2);
          if(job && !job.tradeContract){
            let indexPTC = subcontractorSteps.findIndex(item => item.title == titleContractTradeAgreement);
            if(indexPTC != -1){
              subcontractorSteps.splice(indexPTC, 1);
            }
          }

          // remove accept swms step if he uploads his own swms file
          if(job && job.professions.length==0 && job.customSwmsFile){
            let swmsAcceptIndex = subcontractorSteps.findIndex(item => item.title == titleAcceptSWMS);
            if(swmsAcceptIndex != -1){
              subcontractorSteps.splice(swmsAcceptIndex, 1);
            }
          }
          setSteps(subcontractorSteps);
          // jumpToPendingSlide(job);
        }else if(job.isEmployee){ // employee
          setOccupation(3);
          // if(job && job.employerProfessions && job.employerProfessions.length==0){
          //   let indexP = employeeSteps.findIndex(item => item.title == titleAcceptSWMS);
          //   if(indexP != -1){
          //     employeeSteps.splice(indexP, 1);
          //   }
          // }
          setSteps(employeeSteps);
          // jumpToPendingSlide(job);
        }else if(job.isSupervisor){
          setOccupation(4);
          setSteps(supervisorSteps);
          // jumpToPendingSlide(job);
        }else{
          // do nothin!!! there was an error
        }
        // setActiveStep(2);
        // setCompleted({0: true, 1: true});


        setJobdata({...job});
        jdata = {...job};
        setUser(user);
        jumpToPendingStep(job);
      }

    }else{
      toast('warning', res.msg);
    }
  }


  const setCompletedUpto = number=>{
    var newCompleted = {};
    for(let i=0; i<=number; i++){
      newCompleted[i] = true;
    }
    setCompleted(newCompleted);
    setTimeout(()=>{
      let el = document.querySelector(`#step${number+1}`);
      if(el){
        el.scrollIntoView(true);
        // console.log('scrolling into view of ', el);
      }
    },300)
  }

  const jumpToPendingStep = (job)=>{
    // use udata for user data

    if(job.isBuilder){
	  let pendingStep = 0;

	  if(!job.company_abn){
		setActiveStep(pendingStep);
		setCompletedUpto(pendingStep-1);
        return;
      }
 	  pendingStep++;

		if(!job.company_name || !job.trading_name){
		setActiveStep(pendingStep);
		setCompletedUpto(pendingStep-1);
        return;
      }
 	  pendingStep++;

      if(!job.contact_name || !job.office_number){
		setActiveStep(pendingStep);
		setCompletedUpto(pendingStep-1);
        return;
      }
 	  pendingStep++;

	  if(!job.street || !job.post_code || !job.city || !job.state){
		setActiveStep(pendingStep);
		setCompletedUpto(pendingStep-1);
        return;
      }
 	  pendingStep++;

      if(!job.license){
 		setActiveStep(pendingStep);
		setCompletedUpto(pendingStep-1);
        return;
      }
 	  pendingStep++;

      if(!job.insurance){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;


    if(job && !(job.professions.length>0 || job.customSwmsFile)){
      setActiveStep(pendingStep);
      setCompletedUpto(pendingStep-1);
      return;
    }

    if(job && !job.customSwmsFile)
    pendingStep++;
  
    if(job && (job.swmsAccepted==0 && job.customSwmsFile==null)){
      setActiveStep(pendingStep);
      setCompletedUpto(pendingStep-1);
      return;
    }

    pendingStep++;

      if(job.tradeContract.length==0 || !job.ptcAcceptDate){
		setActiveStep(pendingStep);
		setCompletedUpto(pendingStep-1);
        return;
      }
 	  pendingStep++;

      if(job.whsPlan.length==0 || !job.whsAcceptDate){
		setActiveStep(pendingStep);
		setCompletedUpto(pendingStep-1);
        return;
      }
 	  pendingStep++;

	  if(!job.termsAcceptedOn){
      setActiveStep(pendingStep);
      setCompletedUpto(pendingStep-1);
        return;
      }
      // else{
      //   pendingStep++;
      //   setActiveStep(pendingStep);
		  //   setCompletedUpto(pendingStep-1);
      //   console.log('updating the token');
      //   updateToken({
      //     isBuilder: job.isBuilder,
      //     isSubcontractor: job.isSubcontractor
      //   });
      //   return;
      // }
      pendingStep++;

      setActiveStep(pendingStep);
		  setCompletedUpto(pendingStep-1);

      updateToken({
        isBuilder: true
      });

 	  // pendingStep++;

    //   // if(job.wirPlan && job.wirPlan.length==0){
    //   //   setActiveStep(step+7);
    //   //   setCompletedUpto(comp+7);
    //   //   return;
    //   // }
    //   if(!customerData || customerData.sources.data.length==0){
		// setActiveStep(pendingStep);
		// setCompletedUpto(pendingStep-1);
    //     return;
    //   }else{
	 	// pendingStep++;
    //     // user is all set now log him in
    //     // console.log('going to update the token');
		// setActiveStep(pendingStep);
		// setCompletedUpto(pendingStep-1);
    //     updateToken({
    //       isBuilder: job.isBuilder,
    //       isSubcontractor: job.isSubcontractor
    //     });
    //     // setTimeout(()=>{

    //     // },1000)

    //   }
      // setCurrentSlide(slide);
    }else if(job.isSubcontractor){
      // the order of components
      // <QABN >  on slide  3 ok
      // <CompanyName >     4 ok
      // <PointOfContact >  5 ok
      // <Address >         6 ok
      // <BlueCardUpload >  7 ok
      // <QBCClicense >     8 ok
      // <PublicLiabilityUpload >         9 ok
      // <WorkCover >       10
      // <ContractTradeAgreementAccept >  11
      // <YourProfession /> 12
      // <EditYourSWMS />   13
      // <HazardousSubstanceRegister />   14
      // <InviteUsers />    15
      // completed

	  let pendingStep = 0;

	  if(!job.company_abn){
			setActiveStep(pendingStep);
			setCompletedUpto(pendingStep-1);
			return;
	  }
	  pendingStep++;

      if(!job.company_name || !job.trading_name){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

      if(!job.contact_name || !job.office_number){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

      if(!job.street || !job.post_code || !job.city || !job.state){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

      if(job && !job.blueCard){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

      if(!job.license && !job.qbccSkipped){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

      if(!job.insurance){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

      if(!job.workCover && !job.workCoverSkipped){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

	  // Only validate contract step if we have one
      if(job && job.tradeContract && job.tradeContract.length>0){
        if(job.contractAccepted){
          // setCurrentSlide(slide+9);
          // return;
        }else{
		  setActiveStep(pendingStep);
          setCompletedUpto(pendingStep-1);
          return;
        }
		pendingStep++;
      }

      if(job && !(job.professions.length>0 || job.customSwmsFile)){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
  
      if(job && !job.customSwmsFile)
      pendingStep++;
    
      if(job && (job.swmsAccepted==0 && job.customSwmsFile==null)){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }

      // if(job && !(job.professions.length>0 || job.customSwmsFile)){
      //   setActiveStep(pendingStep);
      //   setCompletedUpto(pendingStep-1);
      //   return;
      // }

      // if(job && job.professions.length==0 && !job.customSwmsFile){
      //   pendingStep++;
  
      //   if(job && !(job.swmsAccepted || job.customSwmsFile)){
      //     setActiveStep(pendingStep);
      //     setCompletedUpto(pendingStep-1);
      //     return;
      //   }
      // }
      
	  // pendingStep++;

    //   if(!job.testtag && !job.testtagSkipped){
    //     setActiveStep(pendingStep);
    //     setCompletedUpto(pendingStep-1);
    //     return;
    //   }
	  pendingStep++;

      if(job.subcontractorHazards.length==0 && !job.hazardsSkipped){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

	  if(!job.termsAcceptedOn){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
	  pendingStep++;

    //   if(!customerData || customerData.sources.data.length==0){
    //     setActiveStep(pendingStep);
    //     setCompletedUpto(pendingStep-1);
    //     return;
    //   }else{
		// pendingStep++;

        // user is all set now log him in
        // console.log('going to update the token');
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        updateToken({
          isSubcontractor: true
        });
        // setTimeout(()=>{

        // },1000)

      // }

    }else if(job.isEmployee){

      let step = 0;
      let comp = -1;
      let pendingStep = 0;
      if(!job.blueCard && !job.blueCardSkipped){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
      
      // if(!job.company_abn){
      //   setActiveStep(step + 1);
      //   setCompletedUpto(comp + 1);
      //   return;
      // }
      // if(!job.contact_name || !job.office_number){
//         setActiveStep(step + 1);
//         setCompletedUpto(comp + 1);
//         return;
//       }
     

      // if(job && job.professions.length>0 && !job.swmsAccepted){
      //   setActiveStep(step+2);
      //   setCompletedUpto(comp+2);
      //   return;
      // }
      // pendingStep++;
      // if(!job.testtag && !job.testtagSkipped){
      //   setActiveStep(pendingStep);
      //   setCompletedUpto(pendingStep-1);
      //   return;
      // }
      
      pendingStep++;
      if(job && job.professions.length==0 && !job.employerSwmsFile){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }

      pendingStep++;

	  if(!job.termsAcceptedOn){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }

     //  if(job && job.tradeContract.length>0){
//         if(job.contractAccepted){
//         }else{
//           setActiveStep(step + 4);
//           setCompletedUpto(comp + 4);
//         return;
//         }

//       }
      // if(job && !job.whsAcceptDate){
      //   setActiveStep(step+6);
      //   setCompletedUpto(comp+6);
      //   return;
      // }
      // if(job && !job.wirAcceptDate){
      //   setActiveStep(step+7);
      //   setCompletedUpto(comp+7);
      //   return;
      // }
      pendingStep++;
      setActiveStep(pendingStep);
      setCompletedUpto(pendingStep-1);

      updateToken({
        isEmployee: true
      });



    }else if(job.isSupervisor){
      let step = 0;
      let comp = -1;
      let pendingStep = 0;

      if( job && !job.blueCard){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
      pendingStep++;
      if(!job.license && !job.qbccSkipped){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
        return;
      }
      // if(!job.company_abn){
      //   setActiveStep(step + 1);
      //   setCompletedUpto(comp + 1);
      //   return;
      // }
      // if(!job.contact_name || !job.office_number){
      //   setActiveStep(step + 2);
      //   setCompletedUpto(comp + 2);
      //   return;
      // }

      // pendingStep++;

      // if(job && job.professions.length==0){
      //   setActiveStep(pendingStep);
      //   setCompletedUpto(pendingStep-1);
      //   return;
      // }
      // pendingStep++;

      // if(job && job.professions.length>0 && !job.swmsAccepted){
      //   setActiveStep(pendingStep);
      //   setCompletedUpto(pendingStep-1);
      //   return;
      // }
      pendingStep++;
      if(!job.termsAcceptedOn){
        setActiveStep(pendingStep);
        setCompletedUpto(pendingStep-1);
          return;
        }
//       if(job && job.tradeContract.length>0){
//         if(job.contractAccepted){
//         }else{
//           setActiveStep(step + 5);
//           setCompletedUpto(comp + 5);
//         return;
//         }

//       }
      // if(job && !job.whsAcceptDate){
      //   setActiveStep(step+5);
      //   setCompletedUpto(comp+5);
      //   return;
      // }
      // if(job && !job.wirAcceptDate){
      //   setActiveStep(step+6);
      //   setCompletedUpto(comp+6);
      //   return;
      // }
      pendingStep++;
      setActiveStep(pendingStep);
      setCompletedUpto(pendingStep-1);
      updateToken({
        isSupervisor: true
      });
    }else{
      // do nothin
    }
  }



  // const jumpToPendingSlide = job=>{
  //   // console.log(jobdata)
  //   if(job.isBuilder){
  //     let slide = 2;

  //     if(!job.company_abn){
  //       setCurrentSlide(slide);
  //       return;
  //     }

  //     if(!job.company_name || !job.trading_name){
  //       setCurrentSlide(slide+1);
  //       return;
  //     }

  //     if(!job.contact_name || !job.office_number){
  //       setCurrentSlide(slide+2);
  //       return;
  //     }
  //     if(!job.street || !job.post_code || !job.city || !job.state){
  //       setCurrentSlide(slide+3);
  //       return;
  //     }
  //     if(!job.tradeContract){
  //       setCurrentSlide(slide+4);
  //       return;
  //     }
  //     if(!job.whsPlan){
  //       setCurrentSlide(slide+5);
  //       return;
  //     }
  //     if(!job.wirPlan){
  //       setCurrentSlide(slide+6);
  //       return;
  //     }
  //     if(job && job.invites.length<2){
  //       setCurrentSlide(slide+7);
  //       return;
  //     }else{
  //       // user is all set now log him in
  //       updateToken({
  //         isBuilder: job.isBuilder,
  //         isSubcontractor: job.isSubcontractor
  //       });
  //       setCurrentSlide(slide+8);
  //       // updateToken();
  //       // console.log('you can login now');

  //     }
  //     // setCurrentSlide(slide);
  //   }else if(job.isSubcontractor){
  //     // the order of components
  //     // <QABN >  on slide  3 ok
  //     // <CompanyName >     4 ok
  //     // <PointOfContact >  5 ok
  //     // <Address >         6 ok
  //     // <BlueCardUpload >  7 ok
  //     // <QBCClicense >     8 ok
  //     // <PublicLiabilityUpload >         9 ok
  //     // <WorkCover >       10
  //     // <ContractTradeAgreementAccept >  11
  //     // <YourProfession /> 12
  //     // <EditYourSWMS />   13
  //     // <HazardousSubstanceRegister />   14
  //     // <InviteUsers />    15
  //     // completed
  //     let slide = 2;


  //     if(!job.company_abn){
  //       setCurrentSlide(slide);
  //       return;
  //     }

  //     if(!job.company_name || !job.trading_name){
  //       setCurrentSlide(slide+1);
  //       return;
  //     }

  //     if(!job.contact_name || !job.office_number){
  //       setCurrentSlide(slide+2);
  //       return;
  //     }

  //     if(!job.street || !job.post_code || !job.city || !job.state){
  //       setCurrentSlide(slide+3);
  //       return;
  //     }
  //     if(job && !job.blueCard){
  //       setCurrentSlide(slide+4);
  //       return;
  //     }
  //     if(job && !job.license){
  //       setCurrentSlide(slide+5);
  //       return;
  //     }
  //     if(!job.insurance){
  //       setCurrentSlide(slide+6);
  //       return;
  //     }

  //     if(!job.workCover){
  //       setCurrentSlide(slide+7);
  //       return;
  //     }
  //     if(job && job.tradeContract){
  //       if(job.contractAccepted){
  //         // setCurrentSlide(slide+9);
  //         // return;
  //       }else{
  //         setCurrentSlide(slide+8);
  //         return;
  //       }

  //     }

  //     if(job && job.professions.length==0){
  //       setCurrentSlide(slide+9);
  //       return;
  //     }

  //     if(job && job.professions.length>0 && !job.swmsAccepted){
  //       setCurrentSlide(slide+10);
  //       return;
  //     }

  //     if(job && job.subcontractorHazards.length==0){
  //       setCurrentSlide(slide+11);
  //       return;
  //     }


  //     setCurrentSlide(slide+12);

  //   }else if(job.isEmployee){

  //       let slide = 2;
  //       if( job && !job.blueCard){
  //         setCurrentSlide(slide);
  //         return;
  //       }
  //       if(!job.company_abn){
  //         setCurrentSlide(slide+1);
  //         return;
  //       }
  //       if(!job.contact_name || !job.office_number){
  //         setCurrentSlide(slide+2);
  //         return;
  //       }
  //       if(job && job.professions.length>0){
  //         if(job.swmsAccepted){
  //           // setCurrentSlide(slide+9);
  //           // return;
  //         }else{
  //           setCurrentSlide(slide+3);
  //           return;
  //         }

  //       }
  //       if(job && job.tradeContract){
  //         if(job.contractAccepted){
  //           // setCurrentSlide(slide+9);
  //           // return;
  //         }else{
  //           setCurrentSlide(slide+4);
  //           return;
  //         }

  //       }
  //       setCurrentSlide(slide+5);

  //   }else if(job.isSupervisor){
  //     let slide = 2;
  //       if( job && !job.blueCard){
  //         setCurrentSlide(slide);
  //         return;
  //       }
  //       if(!job.company_abn){
  //         setCurrentSlide(slide+1);
  //         return;
  //       }
  //       if(!job.contact_name || !job.office_number){
  //         setCurrentSlide(slide+2);
  //         return;
  //       }
  //       if(job && job.tradeContract){
  //         if(job.contractAccepted){
  //           // setCurrentSlide(slide+9);
  //           // return;
  //         }else{
  //           setCurrentSlide(slide+3);
  //           return;
  //         }

  //       }
  //       setCurrentSlide(slide+4);
  //   }else{
  //     // do nothin
  //   }
  // }

  const processUserData = (res)=>{
    // console.log('got user data: ',res);
    if(res.success){
      let user = res.data;
      setUser(user);
      if(user.phone) {
        // gotoNext();
        // gotoNext();
        setPhone(user.phone);

        if(jobdata && (jobdata.isBulider || jobdata.isSubcontractor)){
          // setCurrentSlide(2) // this will skip first two phone slides
        }else{
          setCurrentSlide(2) // this will skip first two phone slides
        }


      }

    }else{
      // show error message
      toast('warning', res.msg);
    }
  }
  // const handleNext = ()=>{

  //   if(currentSlide==0){ // phone slide
  //     let number = phone.trim();
  //     if(number && number.length>0){
  //       sendSms({phone: '+61'+number});
  //       setLoading(true);
  //     }

  //   }else if(currentSlide==2){ // occupation slide
  //     // just use next for now
  //     gotoNext();
  //   }else{
  //     gotoNext();
  //   }

  // }
  const handleNext = ()=>{ // simply go to next step
    const newActiveStep = activeStep + 1;
    const newCompleted = {...completed};
    newCompleted[activeStep] = true;
//     console.log(newActiveStep);

    setActiveStep(newActiveStep);
    setCompleted(newCompleted);
  }
  // const gotoNext = ()=>{
  //   let button = document.querySelector('.carousel__next-button');
  //   if(button){

  //     // button.click();
  //     setCurrentSlide(currentSlide+1)
  //   }
  // }

  const gotoNext = () => {

    // const newActiveStep = activeStep + 1;
    // if(newActiveStep==1){
    //   if(jobsite.address && jobsite.lat && jobsite.lng){
    //     setActiveStep(newActiveStep);
    //     const newCompleted = completed;
    //     newCompleted[activeStep] = true;
    //     setCompleted(newCompleted);
    //   }else{
    //     toast('warning','please choose a valid address!');
    //   }
    // }else if(newActiveStep==2){
    //   setActiveStep(newActiveStep);
    //   const newCompleted = completed;
    //   newCompleted[activeStep] = true;
    //   setCompleted(newCompleted);

    // }else if(newActiveStep==3){
    //   setActiveStep(newActiveStep);
    //   const newCompleted = completed;
    //   newCompleted[activeStep] = true;
    //   setCompleted(newCompleted);
    // }else if(newActiveStep==4){
    //   if(!jobsite.id)
    //     newJobsite(jobsite);
    //   setActiveStep(newActiveStep);
    //   const newCompleted = completed;
    //   newCompleted[activeStep] = true;
    //   setCompleted(newCompleted);
    // }else if(newActiveStep == 5){
    //   // job site created and users are invited
    //   if(jobsite && jobsite.id){
    //     console.log('good to go');
    //     console.log('active step: ',activeStep);
    //     setActiveStep(newActiveStep);
    //     const newCompleted = completed;
    //     newCompleted[activeStep] = true;
    //     setCompleted({...newCompleted});
    //   }

    // }

  };

  const handleConfirmCode = code=>{
    setLoading(true);
    console.log('phone: ',phone);
    console.log('code: ',code);
    confirmCode({
      phone: '+61'+phone,
      code
    })
  }
  // console.log('phone: ',phone);


  const handlePhone = phone=>{
    let number = phone.trim();
      if(number && number.length>0){
        sendSms({phone: '+61'+number});
        setPhone(number);
        setLoading(true);
      }
  }
  const handleLogout = async ()=>{
    await logout();
    window.location.href='/auth/login';
  }

  const handleCompany = data=>{
    setLoading(true);
    if(jobdata && jobdata.isBuilder)
      updateBuilder(data);
    else if(jobdata && jobdata.isSubcontractor)
      updateSubcontractor(data);
    else
      console.log('employee update pending');
  }
  const handleABN = data=>{
    // console.log('jobdata: ',jobdata);
    // console.log('data: ',data);
    if(jobdata && jobdata.isBuilder)
      updateBuilder(data);
    else if(jobdata && jobdata.isSubcontractor)
      updateSubcontractor(data);
    else if(jobdata && jobdata.isEmployee)
      updateEmployee(data);
    else if(jobdata && jobdata.isSupervisor)
      updateSupervisor(data);
    else
     console.log('invalid job data!')
  }
  const handleContactOffice = data=>{
    setLoading(true);
    if(jobdata && jobdata.isBuilder)
      updateBuilder(data);
    else if(jobdata && jobdata.isSubcontractor)
      updateSubcontractor(data);
    else if(jobdata && jobdata.isEmployee)
      updateEmployee(data);
    else if(jobdata && jobdata.isSupervisor)
      updateSupervisor(data);
    else
     console.log('invalid job data!')
  }

  const handleAddress = data=>{
	  setLoading(true);
    if(jobdata && jobdata.isBuilder)
      updateBuilder(data);
    else if(jobdata && jobdata.isSubcontractor)
      updateSubcontractor(data);
    else
      console.log('employee update pending');
  }

  const handleContractAccept = data=>{
    setLoading(true);
    if(jobdata && jobdata.isSubcontractor)
      updateSubcontractor(data);
    else if(jobdata && jobdata.isEmployee)
      updateEmployee(data);
    else if(jobdata && jobdata.isSupervisor)
      updateSupervisor(data);
    else{
      console.log('other update pending');
    }

  }
  const handleWhsAccept = data=>{
    setLoading(true);
    if(jobdata && jobdata.isSubcontractor)
      updateSubcontractor(data);
    else if(jobdata && jobdata.isEmployee)
      updateEmployee(data);
    else if(jobdata && jobdata.isSupervisor)
      updateSupervisor(data);
    else{
      console.log('other update pending');
    }

  }
  const handleWirAccept = data=>{
    setLoading(true);
    if(jobdata && jobdata.isSubcontractor)
      updateSubcontractor(data);
    else if(jobdata && jobdata.isEmployee)
      updateEmployee(data);
    else if(jobdata && jobdata.isSupervisor)
      updateSupervisor(data);
    else{
      console.log('other update pending');
    }

  }
  const handleTesttag = data=>{
    setLoading(true);
    if(jobdata && jobdata.isSubcontractor){
      if(data.testtagSkipped){
        if(!udata.invitedBy) data.skipDoc = true;
        updateSubcontractor({...data});
      }else{
        newTesttag(data);
      }
    }
    else if(jobdata && jobdata.isEmployee){
      if(data.testtagSkipped){
        updateEmployee(data);
      }else{
        newTesttag(data);
      }
    }
    else{
      console.log('other update pending');
    }

  }

  const handleNewInvite = invite=>{
    if(jobdata && jobdata.id){
      let data={
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor,
        company_name: jobdata.company_name,
        invite
      }
      newInvite(data);
    }
  }
  const handleTermsAndConditions = data=>{
	  console.log('data: ',data);
	if(jobdata && jobdata.id){
      setLoading(true);
      if(jobdata.isBuilder) updateBuilder(data);
      if(jobdata.isSubcontractor) updateSubcontractor(data);
      if(jobdata.isEmployee) updateEmployee(data);
      if(jobdata.isSupervisor) updateSupervisor(data);
    }
  }
  const handleProfessions = professions=>{

    if(jobdata && jobdata.id){
      let data={
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor,
        isEmployee: jobdata.isEmployee,
        professions
      }
      setLoading(true);
      newProfessions(data);
    }
  }
  const handleHazards = hazards=>{
    if(jobdata && jobdata.id){
      if(hazards.hazardsSkipped){
        updateSubcontractor(hazards);
      }else{
        let data={
          isBuilder: jobdata.isBuilder,
          isSubcontractor: jobdata.isSubcontractor,
          isEmployee: jobdata.isEmployee,
          hazards
        }
        newSubcontractorHazards(data);
      }
		setLoading(true);
    }
  }

  const handleAcceptEmployerSwms = data=>{
    setLoading(true);
    acceptEmployerSwms(data);
  }

  const handleAcceptEmployerCustomSwms = customSwmsFile=>{
    if(jobdata && jobdata.isEmployee){
      setLoading(true);
      updateEmployee({employerSwmsFile: customSwmsFile, swmsAccepted: 1});
    }
    
  }
  const handleUpdateProfessions = professions=>{
    if(jobdata && jobdata.id){
      let data={
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor,
        isEmployee: jobdata.isEmployee,
        professions
      }
      setLoading(true);
      updateProfessions(data);
      if(jobdata.isBuilder){
        updateBuilder({
          swmsAccepted: 1
        })
      }
      if(jobdata.isSubcontractor){
        updateSubcontractor({
          swmsAccepted: 1
        })
      }else if(jobdata.isEmployee){
        updateEmployee({
          swmsAccepted: 1
        })
      }else if(jobdata.isSupervisor){
        updateSupervisor({
          swmsAccepted: 1
        })
      }else{
        // do nothin...
      }

    }
  }

  const handleBlueCard = card=>{

    if(jobdata && jobdata.id){
		setLoading(true);
		let data={
			// isBuilder: jobdata.isBuilder,
			// isSubcontractor: jobdata.isSubcontractor,
			// isEmployee: jobdata.isEmployee,
			// isSupervisor: jobdata.isSupervisor,
			card
		  }
	  if(jobdata.isEmployee){
		  if(card.blueCardSkipped){
			  updateEmployee(card);
		  }else{
			newBlueCard(data);
		  }
	  }else{
		  newBlueCard(data);
	  }

    }
  }
  const handleLicense = card=>{
    if(jobdata && jobdata.id){
      if(card.qbccSkipped){
		if(jobdata.isSubcontractor){
			updateSubcontractor(card)
		}
		if(jobdata.isSupervisor){
			updateSupervisor(card);
		}

      }else{
        let data={
          isBuilder: jobdata.isBuilder,
          isSubcontractor: jobdata.isSubcontractor,
          card
        }
        newLicense(data);
        setLoading(true);
      }

    }
  }
  const handleInsurance = data=>{
    if(jobdata && jobdata.id){
      setLoading(true);
      newInsurance(data);
    }
  }
  const handleProfessionsAccept = ()=>{
    if(jobdata && jobdata.id){
      updateEmployee({
        swmsAccepted: 1
      });
    }
  }
  const handleWorkCover = data=>{
    if(jobdata && jobdata.id){
      setLoading(true);
      if(data.workCoverSkipped){
        updateSubcontractor(data);
      }else{
        newWorkCover(data);
      }

    }
  }
  const handleTradeContract = data=>{
    // console.log('trade contract data: ',data);
    // return;
    // this step is pending, need to receive the sections array at backend and save it to db
    // console.log('sending sections: ',data.sections);
    if(jobdata && jobdata.id){
      setLoading(true);
      updateBuilder({...data})
      // newTradeContract({
      //   ...data,
      //   isBuilder: jobdata.isBuilder,
      //   isSubcontractor: jobdata.isSubcontractor
      // });
    }
  }
  const handleWirPlan = data=>{
    if(jobdata && jobdata.id){
      setLoading(true);
      newWirPlan({
        ...data,
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor
      });
    }
  }
  const handleWhsPlan = data=>{
    if(jobdata && jobdata.id){
      setLoading(true);
      updateBuilder({...data})
      // newWhsPlan({
      //   ...data,
      //   isBuilder: jobdata.isBuilder,
      //   isSubcontractor: jobdata.isSubcontractor
      // });
    }
  }
  const handleEWPCard = data=>{
    if(jobdata && jobdata.id){
      setLoading(true);
      newEWPCard({
        ...data,
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor
      });
    }
  }
  const handleWorkingHeightsCard = data=>{
    if(jobdata && jobdata.id){
      setLoading(true);
      newWorkingHeightsCard({
        ...data,
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor
      });
    }
  }

  const handleExtras = ()=>{
    updateSubcontractor({
      extras: 1
    })
  }

  const handleStripeToken = token=>{
    console.log('token: ',token);
    setLoading(true);
    stripeCard({token});
  }
  
  const handleStartMembership = (data)=>{
    // console.log('data: ',data);
    if(jobdata && jobdata.id && (jobdata.isBuilder || jobdata.isSubcontractor)){
      setLoading(true);
      startMembership({...data, getCustomer: true});
    }
  }

  const handleCustomSwms = data=>{
    console.log('uplaod swms: ', data);
    newCustomSwms({
      file: data.file, 
      fileName: data?.file?.name,
      customSwmsExpiryDate: data?.customSwmsExpiryDate,
      isBuilder: jobdata.isBuilder, 
      isSubcontractor: jobdata.isSubcontractor
    });
    setLoading(true);
  }




  // console.log('active step is: ',activeStep, active);
  // console.log('current step: ',activeStep);
  // console.log('completed: ',completed);
  var stepData = {
    jobdata,
    user,
    customer,
    isMobile: props.isMobile(),
    // stepper handlers
    setLoading,
    loading,
    stepperHandlers: {
      loading,
      steps,
      activeStep,
      completed,
      handleNext,
      handleBack
    },
    toast,
    handleNext,
    // data handlers
    handleAcceptEmployerCustomSwms,
    handleCustomSwms,
    handlePhone,
    handleConfirmCode,
	handleTermsAndConditions, // all users
    handleExtras,
    updateToken,
    handleAcceptEmployerSwms,
    handleTesttag, // subcontractor, employee
    handleStripeToken, // builder, subcontractor
    handleStartMembership,
    handleWhsPlan, // builder
    handleWirPlan, // builder
    handleCompany, // builder, subcontractor
    handleABN, // builder, subcontractor
    handleContactOffice, // builder, subcontractor
    handleAddress, // builder, subcontractor
    handleNewInvite, // builder
    handleBlueCard, // subcontractor
    handleWhsAccept, // subcontractor
    handleWirAccept, // subcontractor
    handleLicense, // subcontractor
    handleInsurance, // subcontractor
    handleTradeContract, // subcontractor
    handleEWPCard, // subcontractor
    handleWorkingHeightsCard, // subcontractor
    handleWorkCover, // subcontractor
    handleProfessions, // subcontractor
    handleUpdateProfessions, // subcontractor
    handleHazards, // subcontractor
    handleProfessionsAccept, // employee
    handleContractAccept, // all users except builder
  }
  return (

    <RootStyle title="On Boarding | WeSafe" style={{marginTop: props.isMobile()?'35px':'0px'}}>
      <AuthLayout>
        <Link underline="none" variant="subtitle2" onClick={handleLogout} component={RouterLink} to={PATH_AUTH.login}>
          Logout
        </Link>
      </AuthLayout>
      <MHidden width="mdDown">
        <SectionStyle>

          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Let's get you setup with WeSafe
          </Typography>
          <img alt="On Boarding" src="/static/illustrations/illustration_register.png" />
        </SectionStyle>
      </MHidden>


      {
        startLoding?(
          <div style={{margin: '0 auto'}}>
            <LoadingScreen/>
          </div>

        ):(
          <Container>
            <ContentStyle>

            <Box sx={{ width: '100%', p:3 }} style={{marginTop: props.isMobile()? '490px':'0px', minHeight: '100vh', maxHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', maxWidth: '560px'}}>
              {/* <Stepper nonLinear activeStep={activeStep} orientation={props.isMobile()?"vertical":"horizontal"} > */}
              <Stepper nonLinear activeStep={activeStep} orientation="vertical" >
                {steps.map((step, index) => (
                  <Step key={step.title} id={'step'+index} completed={completed[index]}>
                    <StepLabel color="inherit"
                    // onClick={handleStep(index)}

                    >
                      {step.title}
                    </StepLabel>

                    <StepContent>
                      {
                        // activeStep==index?(
                          <React.Fragment>

                            <Box sx={{ mt:3 }}>
                                {
                                  steps[index].component(stepData)
                                }
                            </Box>

                          </React.Fragment>
                        // ):null
                      }

                    </StepContent>
                    {/* <StepContent>
                      {
                        props.isMobile() && activeStep==index?(
                          <React.Fragment>

                            <Box sx={{ mt:3 }}>
                                {
                                  steps[index].component(stepData)
                                }
                            </Box>

                          </React.Fragment>
                        ):null
                      }

                    </StepContent> */}
                  </Step>
                ))}
              </Stepper>


                {/* {
                  !props.isMobile() && steps[activeStep]?(
                    <React.Fragment>

                      <Box sx={{ mt:3 }}>
                          {
                            steps[activeStep].component(stepData)
                          }
                      </Box>


                    </React.Fragment>
                  ):null
                } */}

                {
                  allStepsCompleted() ? (
                    <React.Fragment>
                      <Typography sx={{ mb: 1 }}>
                        All steps completed - Redirecting to Dashboard
                      </Typography>
                      {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box> */}
                    </React.Fragment>
                  ) : null
                }
            </Box>

            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} sx={{ mr: 1 }}>
              Next
            </Button>
            {activeStep !== steps.length &&
              (completed[activeStep] ? (
                <Typography variant="caption" sx={{ display: 'inline-block' }}>
                  Step {activeStep + 1} already completed
                </Typography>
              ) : null)}
          </Box> */}



            </ContentStyle>

          </Container>
        )
      }


    </RootStyle>



  );
}

export default withGetScreen(OnBoard, screenOptions);





// <RootStyle title="On Boarding | WeSafe">
//       <AuthLayout>
//         <Link underline="none" variant="subtitle2" onClick={handleLogout} component={RouterLink} to={PATH_AUTH.login}>
//           Logout
//         </Link>
//       </AuthLayout>
//       <MHidden width="mdDown">
//         <SectionStyle>

//           <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
//             Let's get you setup with WeSafe
//           </Typography>
//           <img alt="On Boarding" src="/static/illustrations/illustration_register.png" />
//         </SectionStyle>
//       </MHidden>


//       {
//         startLoding?(
//           <div style={{margin: '0 auto'}}>
//             <LoadingScreen/>
//           </div>

//         ):(
//           <Container style={{height: '100vh', overflow: 'hidden'}} maxWidth="sm">
//             <ContentStyle>

//               <Box sx={{ m: 2 }}>
//                 <CarouselProvider
//                     naturalSlideWidth={100}
//                     naturalSlideHeight={200}
//                     totalSlides={20}
//                     currentSlide={currentSlide}
//                     touchEnabled={false}
//                     dragEnabled={false}
//                     >
//                     <Slider>

//                     <Slide index={0}>
//                         <RootStyle2>
//                         <Container>
//                         <Typography variant="h4" gutterBottom>
//                         What is your phone number?
//                         </Typography>
//                         <Typography sx={{ color: 'text.secondary', mb: 4 }}>Enter your details below.</Typography>
//                         <TextField
//                           fullWidth
//                           id="phoneNumber"
//                           autoComplete="phone"
//                           value={phone}
//                           onChange={(e)=>setPhone(e.target.value)}
//                           type="number"
//                           label="Mobile Number"
//                         />
//                         {
//                           loading?(
//                             <Button fullWidth size="large" variant="contained" sx={{ mt: 6 }}>please wait...</Button>
//                           ):(
//                             <Button onClick={handleNext} fullWidth size="large" variant="contained" sx={{ mt: 6 }}>Next</Button>
//                           )
//                         }

//                         </Container>
//                         </RootStyle2>
//                     </Slide>
//                     <Slide index={1}>
//                         <RootStyle2>
//                           <Container>
//                               <Typography variant="h4" gutterBottom>
//                                 Verify Number
//                               </Typography>

//                               <Box sx={{ mt: 5, }}>
//                                 <VerifyCodeForm handleConfirmCode={handleConfirmCode} loading={loading} />
//                               </Box>

//                               <Typography variant="body2" align="center">
//                                 Don’t have a code? &nbsp;
//                                 <Link variant="subtitle2" underline="none" onClick={() => {}}>
//                                   Resend code
//                                 </Link>
//                               </Typography>
//                           </Container>
//                         </RootStyle2>
//                     </Slide>
//                     {/* <Slide index={2}>
//                         <RootStyle2>
//                           <Container>
//                               <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
//                                 What is your occupation?
//                               </Typography>

//                               <Box
//                                 sx={{
//                                   display: 'flex',
//                                   '& > *': {
//                                     m: 1,
//                                   },
//                                 }}
//                               >
//                                 <ButtonGroup fullWidth
//                                   size="large"
//                                   orientation="vertical"
//                                   aria-label="vertical contained button group"
//                                   variant="contained"
//                                 >
//                                   <Button onClick={()=>{ setOccupation(1); newBuilder();  }} key="one">Builder</Button>,
//                                   <Button onClick={()=>{ setOccupation(2); newSubcontractor();  }} key="two">Subcontractor</Button>
//                                 </ButtonGroup>
//                               </Box>


//                           </Container>
//                         </RootStyle2>
//                     </Slide> */}
//                     {
//                       jobdata && jobdata.id &&
//                       occupations[occupation].questions.map((q,index)=>{
//                         return (
//                           <Slide key={index} index={2+index}>
//                             <RootStyle2>
//                               <Container>
//                                   <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
//                                     {q({
//                                       loading,
//                                       user,
//                                       toast,
//                                       jobdata,
//                                       handleExtras,
//                                       updateToken,
//                                       handleNext,
//                                       handleWhsPlan, // builder
//                                       handleWirPlan, // builder
//                                       handleCompany, // builder, subcontractor
//                                       handleABN, // builder, subcontractor
//                                       handleContactOffice, // builder, subcontractor
//                                       handleAddress, // builder, subcontractor
//                                       handleNewInvite, // builder
//                                       handleBlueCard, // subcontractor
//                                       handleLicense, // subcontractor
//                                       handleInsurance, // subcontractor
//                                       handleTradeContract, // subcontractor
//                                       handleEWPCard, // subcontractor
//                                       handleWorkingHeightsCard, // subcontractor
//                                       handleWorkCover, // subcontractor
//                                       handleProfessions, // subcontractor
//                                       handleUpdateProfessions, // subcontractor
//                                       handleHazards, // subcontractor
//                                       handleProfessionsAccept, // employee
//                                       handleContractAccept, // all users except builder
//                                     })}
//                                   </Typography>

//                               </Container>
//                             </RootStyle2>
//                         </Slide>
//                         )
//                       })
//                     }


//                     </Slider>
//                     <ButtonNext style={{display: 'none'}}>
//                       next
//                     </ButtonNext>
//                 </CarouselProvider>

//               </Box>



//             </ContentStyle>

//           </Container>
//         )
//       }


//     </RootStyle>