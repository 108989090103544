import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession, getUserData } from '../utils/jwt';
import {serverBaseUrl} from '../constants/defaultValues';
import { getIDandLogin, userLogin } from '../socket';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  onlineUsers: {}
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },

  SET_USER: (state, action)=>{
    const { user } = action.payload;
    return {
      ...state,
      user: {...user}
    };
  },

  UPDATE_TOKEN: (state, action)=>{
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  ONLINE_USERS:   (state, action)=>{
    const { users } = action.payload;

    return {
      ...state,
      onlineUsers: {...users}
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  Login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateLogin: () => Promise.resolve(),
  updateUserToken: () => Promise.resolve(),
  updateOnlineUsers: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          // getIDandLogin();
          // const response = await axios.get('/api/account/my-account');
          
          // const { user } = response.data;
          var user = getUserData(accessToken);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => { // not used
    // const response = await axios.post('/api/account/login', {
    //   email,
    //   password
    // });
    console.log('got email and pass: ',email, password);
    // debugger;
    const response = await axios.post(serverBaseUrl, {
      email,
      password
    });
    console.log('got response: ',response);
    const { accessToken, user } = response.data;
    
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };
  const Login = async (email, password) => {
    const processUserLogin = res=>{
        console.log('login ress: ',res);
      if(res.success){
        const { accessToken, user } = res;
        setSession(accessToken);
        // getIDandLogin();
        dispatch({
          type: 'LOGIN',
          payload: {
            user
          }
        });
      }else{
        throw {message: res.msg}
      }
    }
    let data = {email, password};
    userLogin(processUserLogin);
    userLogin(data);
    /////////////////
      
    // let data = {email, password};
    // console.log(data);
    // const response = await fetch(serverBaseUrl+'/user/login', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(data)
    // });
    // var res =  await response.json();
    // console.log('got response: ',res);
    // if(res.success){
    //   const { accessToken, user } = res;
    //   setSession(accessToken);
    //   dispatch({
    //     type: 'LOGIN',
    //     payload: {
    //       user
    //     }
    //   });
    // }else{
    //   throw {message: res.msg}
    // }
    
    
    
  };
  const updateLogin = async (data) => {
      
    const { token, user } = data;
    setSession(token);
    // getIDandLogin();
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    
  };
  const updateUserToken = async (data) => {
      
    const { token, user } = data;
    setSession(token);
    dispatch({
      type: 'SET_USER',
      payload: {
        user
      }
    });
    
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateOnlineUsers = async (users) => {
    dispatch({
      type: 'ONLINE_USERS',
      payload: {
        users
      }
    });
  }

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        Login,
        login,
        logout,
        register,
        updateLogin,
        resetPassword,
        updateProfile,
        updateUserToken,
        updateOnlineUsers
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
