import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
//
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  let history = useNavigate();
  const isHome = pathname === '/';
  const { user } = useAuth();
  

  useEffect(() => {
    // console.log('im here with user: ',user);
    if(user && user.id){
      if(user.roleId==10)
        history('/onboarding')
      else 
        history('/dashboard')

    }
  }, [])

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default'
          }}
        >
          <Container maxWidth="lg">
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mb: 0, mx: 'auto', cursor: 'pointer' }} />
            </ScrollLink>

            <Typography variant="caption" component="p">
              © All rights reserved
              <br /> made by &nbsp;
              <Link href="https://AllaroTech.com/">AllaroTech.com</Link>
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
}
