import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import LightboxModal from '../../../../../components/LightboxModal';

import { useEffect, useState } from 'react';

import Page from '../../../../../components/Page';

import LoadingButton from '@mui/lab/LoadingButton';
import { doc400 } from '../../../../userImage';
import DocPreview, { LoadingPreview } from './DocPreview';
import { fileUpload } from '../../../../../socket';
import { FDate } from 'src/constants/defaultValues';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));


const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
        color:'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
        color:'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
        color:'black',
    },
  },

  '& .MuiInputBase-input': {
    color: 'black',

  },
});

const textStyle = {color: 'black'}



// ----------------------------------------------------------------------

export default function VerifyCode({doc, toast, isMobile, editable, handleUpdateDoc,handleAddDoc,loading}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = React.useRef(null);

  let [name, setName] = useState('');
  let [issue_date, setIssueDate] = useState('');
  let [expiry_date, setExpiryDate] = useState('');
  let [type, setType] = useState('');
  let [license_number, setLicenseNumber] = useState('');
  let   [currentFile, setCurrentFile] = useState(null);
  let   [fileOpen, setFileOpen] = useState(false);
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);



  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(doc && doc.id){
      if(doc.name) setName(doc.name);
      if(doc.type) setType(doc.type);
      if(doc.license_number) setLicenseNumber(doc.license_number);
      if(doc.expiry_date) setExpiryDate(doc.expiry_date);
      if(doc.issue_date) setIssueDate(doc.issue_date);
    }

  },[doc]);

  const handleSubmit = e=>{
    if(expiry_date){
		// if(issue_date=='Invalid Date'){
		// 	toast('warning', 'Please select a valid issue date');
		// 	return;
		// }
		if(expiry_date=='Invalid Date'){
			toast('warning', 'Please select a valid expiry date');
			return;
		}
        let newCard = {
          // name,
          // type,
          // license_number,
          // issue_date: new Date(issue_date).toISOString().split('T')[0],
          expiry_date: new Date(expiry_date).toISOString().split('T')[0],
        }
        if(file){
          newCard.file = file;
        }
        if(doc && doc.id){
          handleUpdateDoc(newCard);
        }else{
          if(!newCard.file){
            toast('warning',"Please upload the copy of the card!");
            return;
          }

          handleAddDoc(newCard);
        }

      }else{
          toast('warning', 'please enter all the fields!')
      }
  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }
  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }

  const handleViewDoc = doc=>{
    setCurrentFile(doc.file);
    setFileOpen(true);
  }


  const stackStyle = {};
  if(isMobile){
    stackStyle.maxHeight = '90vh';
    stackStyle.overflowY = 'auto';
  }

  return (
    <div style={stackStyle}>
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
             
              width: 1,
              position: 'relative',
              
            }}
            
          >

        <Grid container sx={{ width: '100%', padding:'10px' }}>
          <Grid item xs={2} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/EWPA-LOGO-${isLight ? 'light' : 'dark'}.png`}

            />
          </Grid>

          <Grid item>
             <Typography sx={{ marginTop:'6px', marginLeft:'10px' }}>EWP OPERATOR LICENCE</Typography>
                {
                  editable?(
                    <Box sx={{ mt:4, ml:2 }} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker inputFormat="dd-MM-yyyy" 
                            openTo="day"
                            views={['day','month','year']}
                            // label="Year, month and date"
                            label="Expiry Date"
                            value={expiry_date}
                            onChange={(newValue) => {
                            setExpiryDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    </Box>
                  ):(
                      <Typography sx={{ marginLeft:'10px' }}>Expires: <span style={{ color: '#fda92d' }}> {FDate(expiry_date)}</span></Typography>
                  )
                }
          </Grid>
        </Grid>
          </Paper>


      </Stack>

      <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />
        {
          editable?(
            <Grid container spacing={1}>

            <Grid item xs={12} >
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                {
                  fileLoading?(
                    <LoadingPreview />
                  ):null
                }
                {
                  file?(
                    <DocPreview fileLoading={fileLoading} file={file} />
                  ):null
                }
                <Button onClick={handleFile} fullWidth sx={{mt:1}} size="large" variant="outlined">Upload a Photo of Your Card</Button>
              </Box>
            </Grid>

          </Grid>
          ):null
        }

      <LightboxModal
        images={[currentFile]}
        photoIndex={0}
        setPhotoIndex={setCurrentFile}
        isOpen={fileOpen}
        onClose={() => setFileOpen(false)}
      />
        {
            editable?(
              <LoadingButton fullWidth variant="contained" loading={loading} sx={{mt:2}} onClick={handleSubmit}> {handleUpdateDoc? 'Finished':'Add'} </LoadingButton>
            ):<DocPreview file={doc.file} />
        }
    </div>
  );
}
