import * as Yup from 'yup';
import { useSnackbar } from 'notistack5';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
// material
import { OutlinedInput, FormHelperText, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import fakeRequest from '../../../utils/fakeRequest';
import ReactCodeInput from 'react-verification-code-input';
// ----------------------------------------------------------------------

// eslint-disable-next-line consistent-return
function maxLength(object) {
  if (object.target.value.length > object.target.maxLength) {
    return (object.target.value = object.target.value.slice(0, object.target.maxLength));
  }
}


export default function VerifyCodeForm({handleConfirmCode,loading}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  var [code,setCode] = useState('');
  var [codeValues, setCodes] = useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  })
  const VerifyCodeSchema = Yup.object().shape({
    // code1: Yup.number().required('Code is required'),
    // code2: Yup.number().required('Code is required'),
    // code3: Yup.number().required('Code is required'),
    // code4: Yup.number().required('Code is required'),
    // code5: Yup.number().required('Code is required'),
    // code6: Yup.number().required('Code is required')
  });

  useEffect(()=>{
    let code1 = document.querySelector('#code1');
    if(code1) code1.focus();
  },[])

  const handlers = {
    handlecode1: (e)=> {
      let values = {...codeValues};
      values['code1'] = e.target.value;
      setCodes(values);
      if(e.target.value.length>0){
        document.querySelector('#code2').focus();
      } 
    },
    handlecode2: (e)=> {
      let values = {...codeValues};
      values['code2'] = e.target.value;
      setCodes(values);
      if(e.target.value.length>0){
        
       
        document.querySelector('#code3').focus();
      } 
    },
    handlecode3: (e)=> {
      let values = {...codeValues};
      values['code3'] = e.target.value;
      setCodes(values);
      if(e.target.value.length>0){
        
        document.querySelector('#code4').focus();
      } 
    },
    handlecode4: (e)=> {
      let values = {...codeValues};
      values['code4'] = e.target.value;
      setCodes(values);
      if(e.target.value.length>0){
       
        document.querySelector('#code5').focus();
      } 
    },
    handlecode5: (e)=> {
      let values = {...codeValues};
      values['code5'] = e.target.value;
      setCodes(values);
      if(e.target.value.length>0){
       
        document.querySelector('#code6').focus();
      } 
    },
    handlecode6: (e)=> {
      let values = {...codeValues};
      values['code6'] = e.target.value;
      setCodes(values);
      if(e.target.value.length>0){
        let code = values.code1+values.code2+values.code3+values.code4+values.code5+values.code6;
        if(code.length==6){
          handleConfirmCode(code);
        }
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: ''
    },
    validationSchema: VerifyCodeSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) =>{
      console.log('values: ',values)
      // await fakeRequest(500);
      // enqueueSnackbar('Verify success', { variant: 'success' });
      // navigate(PATH_DASHBOARD.root);
    }
  });

  const handleChangeCode = code=>{
    if(code.length==6){
      setCode(code);
      handleConfirmCode(code);
    }else{
      setCode(code);
    }
   
  }

  const { values, errors, isValid, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="center">
        {Object.keys(values).map((item) => (
          <OutlinedInput
            key={item}
            id={item}
            {...getFieldProps(item)}
            type="number"
            // style={{display: 'none'}}
            value={codeValues[item]}
            placeholder="-"
            onInput={maxLength}
            onChange={handlers['handle'+item]}
            error={Boolean(touched[item] && errors[item])}
            inputProps={{
              maxLength: 1,
              sx: {
                p: 0,
                textAlign: 'center',
                width: { xs: 36, sm: 56 },
                height: { xs: 36, sm: 56 }
              }
            }}
          />
        ))}
        {/* <ReactCodeInput onChange={handleChangeCode} style={{position: 'absolute'}} /> */}
      </Stack>

      <FormHelperText error={!isValid} style={{ textAlign: 'right' }}>
        {!isValid && 'Code is required'}
      </FormHelperText>
      {/* {
        loading?(
          <p>Verifying</p>
        ):null
      } */}
      
    {/* <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} justifyContent="center">
          {Object.keys(values).map((item) => (
            <OutlinedInput
              key={item}
              id={item}
              {...getFieldProps(item)}
              type="number"
              // value={codeValues[item]}
              placeholder="-"
              onInput={maxLength}
              onChange={handlers['handle'+item]}
              error={Boolean(touched[item] && errors[item])}
              inputProps={{
                maxLength: 1,
                sx: {
                  p: 0,
                  textAlign: 'center',
                  width: { xs: 36, sm: 56 },
                  height: { xs: 36, sm: 56 }
                }
              }}
            />
          ))}
        </Stack>

        <FormHelperText error={!isValid} style={{ textAlign: 'right' }}>
          {!isValid && 'Code is required'}
        </FormHelperText>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} sx={{ mt: 3 }}>
          Verify
        </LoadingButton>
      </Form>
    </FormikProvider> */}

    </>
  );
}
