// ----------------------------------------------------------------------

export const fullName = [
  'Josh Cavallaro',
  'Lucian Obrien',
  'Deja Brady',
  'Harrison Stein'
];

export const firstName = [
  'Josh',
  'David',
  'Ebba',
  'Chester'
];

export const lastName = [
  'Cavallaro',
  'Simonis',
  'Yost',
  'Hand'
];
