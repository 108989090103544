import { Icon } from '@iconify/react';

import LoadingButton from '@mui/lab/LoadingButton';

import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField, Grid, useMediaQuery, Modal } from '@material-ui/core';
import SaveIcon from '@mui/icons-material/Save';

// components
import Page from '../../components/Page';
import { useEffect, useState, useRef } from 'react';
import {arrangeDocIndexes, documentStyle} from '../../constants/defaultValues';
import StepperBottomRow from '../onboard/StepperBottomRow';
import useSettings from '../../hooks/useSettings';
import Paper from '@mui/material/Paper';
import SunEditor from '../../components/editor/suneditor';

import Logo from '../../components/LogoDoc';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {
  Page as DocPage,
  Text,
  View,
  Image,
  Document,
  usePDF,
  Font,
  StyleSheet
} from "@react-pdf/renderer";
import { createDefaultUserDocs, deleteUserDocs, newUserDoc, updateUserDoc } from 'src/socket';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));
const deleteModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};
const hoverButtonStyle = {
  position: 'absolute',
  paddingLeft: '7px',
  paddingRight: '7px',
  backgroundColor: 'lightgrey',
  borderRadius: '40px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  borderBottomLeftRadius: '0px',
  top:'-20px',
  right: 0
}

const modalStyleDesktop = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height:'100%',
  bgcolor: '#161C24',
  border: '0px solid #000',
  maxHeight: '100vh',
  overflowY: 'scroll',
  // p: 4,
  padding: '25px',
  paddingLeft: '30px',
  paddingRight: '30px',

};

const modalStyleMobile = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height:'100%',
  bgcolor: '#161C24',
  border: '0px solid #000',
  maxHeight: '100vh',
  overflowY: 'scroll',
  // p: 4,
  padding: '5px',
  paddingLeft: '5px',
  paddingRight: '5px',

};
const sectionStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height:'auto',
  maxHeight: '100vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '5px',
  // p: 4,
  padding: '25px',
  paddingLeft: '30px',
  paddingRight: '30px',

};
const paperStyleDesktop = {
  padding: '40px',
  position: 'relative',
  marginBottom: '15px',
  borderRadius: '0px',
  maxWidth: '794px',
  minHeight: '1123px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'justify',
  textJustify: 'inter-word'

};
const paperStyleMobile = {
  padding: '20px',
  position: 'relative',
  marginBottom: '15px',
  borderRadius: '0px',
  maxWidth: '794px',
  minHeight: '1123px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'justify',
  textJustify: 'inter-word'

};

// ----------------------------------------------------------------------

const footer = {
  fontSize: '10px',
  fontWeight: 'bold',
  textAlign:'center',
  bottom: '10px',
  position: 'absolute',
  width: '100%',

};


export default function VerifyCode({handleTradeContract: handleDoc, setLoading, toast, jobdata, handleNext, loading, user, stepperHandlers}) {
  let [isOpen, setOpen] = useState(false);
  let [sectionOpen, setSectionOpen] = useState(false);
  let [deleteDocModal, toggleDeleteDocModal] = useState(false);
  let [defaultDocModal, toggleDefaultDocModal] = useState(false);
  const handleClose = e=> setOpen(false);
  const handleSectionClose = e=> setSectionOpen(false);
  var [currentSection, setCurrentSection] = useState(null);
  var [sections, setSections] = useState([]);
  let [hovered, setHovered] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [content, setContent] = useState('')
	const [title, setTitle] = useState('')

  var editor = null;
  const { themeMode } = useSettings();
	const config = {
		// readonly: false // all options from https://xdsoft.net/jodit/doc/
    style: {
      color: 'black',
      height: 'auto',
    }
	}

  const handleSectionOpen = e=>{
    setSectionOpen(true);
  }
  const handleOpen = e=>{
    // if(jobdata && jobdata.id && jobdata.tradeContract && jobdata.tradeContract.length>0){
//       handleNext();
//     }else{
      setOpen(true);
//     }
  }

  useEffect(()=>{

    // if(jobdata && jobdata.id && jobdata.tradeContract && jobdata.tradeContract.length>0){
      setSections(jobdata.tradeContract);
    // }else{
    //   // if(jobdata && jobdata.id && jobdata.ptc.length>0){
    //   //   setSections(jobdata.ptc);
    //   // }
    // }

  },[jobdata, jobdata.tradeContract]);


  const handleSubmit = e=>{

    if(sections.length>0){
      handleDoc({
        ptcAcceptDate: new Date(new Date().toUTCString()).toISOString().slice(0, 19).replace('T', ' ')
      })
    }else{
      toast('warning','please enter at least one page to proceed!')
    }

  }
  const handleUpdateSection = e=>{
    if(currentSection && currentSection.id){
      let secs = [...sections];
      secs.map(sec=>{
        if(sec.id==currentSection.id){
          // sec.content = JSON.stringify(con);
          sec.content = content;
          sec.title = title;
        }
      });
      updateUserDoc({...currentSection, title, content});
      setSections([...secs]);
      setContent('');
      setTitle('');
      setCurrentSection(null);
      handleSectionClose();
    }else{
       // new section
       let last = sections[sections.length-1];
       let orderId = last? last.orderId+1: 1;
       let newSection = {
        title, content, orderId, docType: 'ptc'
       }
       newUserDoc(newSection)
       setLoading(true);

      setContent('');
      setTitle('');
      setCurrentSection(null);

       setTimeout(()=>{
        handleSectionClose();
       },1000)

    }



  }
  const toggleEditButton = (index, show)=>{
    let hov = {...hovered};
    if(show){
        hov[index] = true;
    }else{
        delete hov[index];
    }
    setHovered({...hov});
  }

  const handleEdit = doc=>{
    console.log('edting doc: ',doc);
    setCurrentSection({...doc});
    handleSectionOpen();
    // setContent(JSON.parse(doc.content));
    setContent(doc.content);
    setTitle(doc.title);
  }


  const handleDeleteDoc = ()=>{
    deleteUserDocs({docType: 'ptc'});
    setLoading(true);
    toggleDeleteDocModal(false);
    // setTimeout(()=>toggleDeleteDocModal(false), 1000);
  }

  const handleDefaultDoc = ()=>{
    createDefaultUserDocs({docType: 'ptc'});
    setLoading(true);
    toggleDefaultDocModal(false);
  }

  return (
    <div>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          Your P.C. Builder's "Period Trade Contract - PCT"
        </Typography>
	    <Typography sx={{ color: 'text.secondary', mb: 4 }}>
     	  This is your PCT contract between you the builder P.C, and your subcontractors, please view it, edit it how you wish, then accept it. All subcontractors will accept this document when they join your team. 
    	</Typography>
	    <Typography sx={{ color: 'text.secondary', mb: 4 }}>
     	  Note: You can edit this document anytime in your document profile.
    	</Typography>

		<Button onClick={handleOpen}>View Document</Button>

        <Modal
          keepMounted
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={isMobile? modalStyleMobile: modalStyleDesktop}>
          <Box sx={{display: 'flex', justifyContent: 'center', mb:2}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '60%'}}>
              <Typography variant="h6" component="h2">
                Edit or Accept Current Document
              </Typography>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Button variant="outlined" onClick={()=>setSectionOpen(true)}>New Section</Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    sx={{ml:1}}
                    onClick={()=>toggleDeleteDocModal(true)}
                    variant="outlined"
                    >
                        Custom Template
                </LoadingButton>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    sx={{ml:1}}
                    onClick={()=>toggleDefaultDocModal(true)}
                    variant="outlined"
                    >
                        Default Template
                </LoadingButton>
                {/* <Button variant="outlined" sx={{ml:1}} onClick={()=>setSectionOpen(true)}>Default Template</Button> */}
              </Box>

            </Box>
          </Box>

          <div>
          <Document>
          {
            sections.length>0?(
                <DocPage size="A4" style={documentStyle.body} wrap>
                    <Paper
                    elevation={3} style={isMobile? paperStyleMobile: paperStyleDesktop}
                    className="editorPage"
                    >
                        {
                            arrangeDocIndexes(sections).map((doc, index)=>{
                                // let con = JSON.parse(doc.content);
                                // console.log('here: ',content);
                                return (
                                    <div
                                    style={{position: 'relative'}}
                                    onMouseEnter={()=>toggleEditButton(index, true)}
                                    onMouseLeave={()=>toggleEditButton(index, false)}
                                    >
                                      {
                                          doc.title && doc.title.length>0?(
                                            <Text style={documentStyle.subtitle}>
                                                {doc?.index} . {doc?.title}
                                            </Text>
                                          ):null
                                      }
                                        <Text style={documentStyle.text}>
                                            <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: doc?.content }} />
                                        </Text>

                                        {
                                          hovered[index]? (
                                              <div className="editDocButton" style={hoverButtonStyle}>
                                                  <IconButton onClick={()=>handleEdit(doc)} size="small" variant="contained" >
                                                      <EditIcon />
                                                  </IconButton>

                                              </div>

                                          ):null
                                        }
                                    </div>

                                )

                            })
                        }

                    </Paper>
                </DocPage>
            ):null
          }
          </Document>
            {/*
              sections.map((section,index)=>{
                // let con = jobdata.tradeContract.length>0? section.content: JSON.parse(section.content);
                let con = section.content;
                return (
                  <div>
                      <Paper
                          onMouseEnter={()=>toggleEditButton(index, true)}
                          onMouseLeave={()=>toggleEditButton(index, false)}
                          elevation={3} style={isMobile? paperStyleMobile: paperStyleDesktop}
                      >
                      <Logo />

                      <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: con }} />

                      {
                          hovered[index]? (
                              <div className="editDocButton" style={{position:'absolute'}}>
                                  <IconButton onClick={()=>handleEdit(section)} size="small" variant="contained" >
                                      <EditIcon />
                                  </IconButton>
                              </div>

                          ):null
                      }
                      <div style={footer}>Copyright © 2022 WeSafe Pty Ltd document generated at: WeSafe.com.au</div>
                      </Paper>
                  </div>
                )
              })
            */}


          </div>
          {
            sections.length>0 && (
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '45%'}}>
                  <Button onClick={()=>setSectionOpen(true)}>New Section</Button>
                  <div style={{display: 'flex', justifyContent: 'flex-end', width: '73%'}}>
                    <LoadingButton
                            onClick={handleClose}
                            variant="outlined"
                            size="medium"
                            sx={{mt:2, mr: 2}}
                        >
                            Cancel
                        </LoadingButton>
                    <LoadingButton
                            onClick={handleSubmit}
                            loading={loading}
                            variant="contained"
                            size="medium"
                            sx={{mt:2}}
                        >
                            Accept Contract
                        </LoadingButton>
                  </div>
                </div>
              </div>
            )
          }




        </Box>

        {/* //////////////////////////////////// section update modal ////////////////////// */}
        </Modal>

        <Modal
          keepMounted
          open={sectionOpen}
          onClose={handleSectionClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={sectionStyle}>
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              { currentSection? 'Edit Section': 'New Section' }
            </Typography>
            <div>

              {
                  <div>
                      {
                          sectionOpen?(
                            <>
                             <TextField
                                fullWidth
                                sx={{my:2}}
                                value={title}
                                onChange={e=> setTitle(e.target.value) }
                                label="Title"
                            />
                            <SunEditor setContents={content} onChange={(content)=>setContent(content)} />
                            </>

                          ):null
                      }
                  </div>
              }


            </div>

              <br/>
            <LoadingButton
                loading={loading}
                loadingPosition="start"
                onClick={handleUpdateSection}
                startIcon={<SaveIcon />}
                variant="contained"
                >
                    {
                        currentSection?"Update":"Save"
                    }
            </LoadingButton>
            {/* <Button size="medium" onClick={handleUpdateSection}
              variant="contained" sx={{ mt: 2 }}>
                  {
                    currentSection?"Update":"Save"
                  }
              </Button> */}
          </div>

        </Box>

        </Modal>
        <Modal
          keepMounted
          open={deleteDocModal}
          onClose={e=>toggleDeleteDocModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={deleteModalStyle}>
            <Typography sx={{my:2}} variant="h6">The default document will be removed from your account. Are you sure you want to do this?</Typography>


            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button sx={{mr: 1}} variant="outlined" onClick={e=>toggleDeleteDocModal(false)}>Cancel</Button>
              <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  onClick={handleDeleteDoc}
                  variant="outlined"
                  >
                      Yes
              </LoadingButton>
            </Box>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={defaultDocModal}
          onClose={e=>toggleDefaultDocModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={deleteModalStyle}>
            <Typography sx={{my:2}} variant="h6">This action will replace your current changes by default template. Are you sure you want to do this?</Typography>


            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button sx={{mr: 1}} variant="outlined" onClick={e=>toggleDefaultDocModal(false)}>Cancel</Button>
              <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  onClick={handleDefaultDoc}
                  variant="outlined"
                  >
                      Yes
              </LoadingButton>
            </Box>
          </Box>
        </Modal>



        <StepperBottomRow {...stepperHandlers} handleSubmit={handleOpen} />
    </div>
  );
}