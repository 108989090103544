import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme, styled } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import SunEditor from '../../components/editor/suneditor';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { Box, Button, Link, Container, Typography, TextField, Grid, useMediaQuery, Modal, Dialog, DialogTitle, DialogContent, } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import {Animated} from "react-animated-css";

import { VerifyCodeForm } from '../../components/authentication/verify-code';
import { useEffect, useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import {periodTradeContract} from '../../constants/defaultValues';
import StepperBottomRow from '../onboard/StepperBottomRow';
import Logo from '../../components/Logo';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deleteProfession, deleteProfessionPage, newProfessionPage, updateProfessionPage } from 'src/socket';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
import ResponsiveTableStyle  from 'src/assets/responsiveTableStyle';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '40px',
  position: 'relative',
  marginBottom: '15px',
  borderRadius: '0px',
  maxWidth: '794px',
  minHeight: '1123px',
  marginLeft: 'auto',
  marginRight: 'auto',
  background: theme.palette.background.neutral
}));

const style = {
  position: 'absolute',
  width: 'calc(100% - 20px)',
  height: 'calc(100% - 20px)',
  bgcolor: 'background.paper',
  borderRadius:'10px',
  boxShadow: 24,
  overflowY: 'scroll',
  padding: '30px',
  top: '10px',
  left: '10px',

};

const footer = {
  fontSize: '10px',
  fontWeight: 'bold',
  textAlign:'center',
  bottom: '10px',
  position: 'absolute',
  width: '100%',
};
const style2 = {
  width: 'calc(100% - 20px)',
  height: 'calc(100% - 20px)',

};

const modalStyleDesktop = {
  position: 'absolute',
  width: 'calc(100% - 20px)',
  height: 'calc(100% - 20px)',
  bgcolor: '#161C24',
  borderRadius:'10px',
  boxShadow: 24,
  padding: '30px',
  top: '10px',
  left: '10px',

};
const modalStyleMobile = {
  position: 'absolute',
  width: 'calc(100% - 0px)',
  height: 'calc(100% - 0px)',
  bgcolor: '#161C24',
  borderRadius:'0px',
  boxShadow: 24,
  padding: '0px',
  top: '0px',
  left: '0px',
};
const buttonStyleDesktop = {
  display: 'flex',
  justifyContent: 'center',
 	width: '400px',
 	position:'relative',
 	marginLeft:'auto',
	marginRight:'auto',
};
const buttonStyleMobile = {
  width: '100%',
};
// ----------------------------------------------------------------------

var profession = null;


export default function VerifyCode({handleUpdateProfessions, toast, loading, setLoading, jobdata, handleNext, user, stepperHandlers}) {
  let [isOpen, setOpen] = useState(false);
  const handleClose = e=> setOpen(false);
  const handleOpen = e=> setOpen(true);
  // const editor = useRef(null);
  const readOnlyEditor = useRef(null);
  var [checked, setChecked] = useState({})
  const [desc, setDesc] = useState('');
  const [swms, setSwms] = useState([]);
	const [content, setContent] = useState('')
  const [currentProf, setCurrentProf] = useState(null);
  let [editor, setEditor] = useState(null);
  let [animate, setAnimate] = useState(false);
  let [hovered, setHovered] = useState({});
  let [currentSection, setCurrentSection] = useState(null);
  let [editorModalOpen, toggleEditorModal] = useState(false);
  // let [loading, setLoading] = useState(false);
	  const theme = useTheme();
 const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const config = {
		// readonly: false // all options from https://xdsoft.net/jodit/doc/
    style: {
      color: 'black',
      height: 1000,
    }
	}
  const ReadOnlyConfig={
    readonly: true,
    toolbar: false,
    style: {
      color: 'black',
      height: 900
    }
  }

  useEffect(() => {
    newProfessionPage(processNewProfessionPage);
    updateProfessionPage(processUpdateProfessionPage);
    deleteProfessionPage(processDeleteProfessionPage);
  
    return () => {
      newProfessionPage(processNewProfessionPage, 'off');
      updateProfessionPage(processUpdateProfessionPage, 'off');
      deleteProfessionPage(processDeleteProfessionPage, 'off');
    }
  }, []);
  

  useEffect(()=>{
    if(jobdata && jobdata.professions) {
      setSwms(jobdata.professions);
      // setChecked({})
    }
	if(jobdata && jobdata.swmsAccepted){
		let check = {};
		jobdata.professions.map(pr=>{
			check[pr.id] = pr;
		});
		setChecked(check)
	}
    // setTimeout(()=>{
    //   let editor = new window.Jodit('#editor',{
    //     style: {
    //       color: 'black',
    //       height: '100%'
    //     },
    //     events: {
    //       change: function(content){
    //         setContent(content);
    //       }
    //     },
    //     extraButtons: [
    //         {
    //             name: 'My Company',
    //             tooltip: 'my company',
    //             // iconURL: 'http://xdsoft.net/jodit/logo.png',
    //             exec: function (ed) {
    //                 ed.s.insertHTML(jobdata.company_name);
    //             }
    //         },
    //         {
    //             name: 'My Name',
    //             tooltip: 'my name',
    //             // iconURL: 'http://xdsoft.net/jodit/logo.png',
    //             exec: function (ed) {
    //                 ed.s.insertHTML(user.first_name+' '+user.last_name);
    //             }
    //         }
    //     ]
    //   });
    //   // editor.value = periodTradeContract+'';
    //   window.editor = editor;
    //   setEditor(editor);

    // },10);



  },[jobdata]);

  

  const toggleEditButton = (index, show)=>{
    let hov = {...hovered};
    if(show){
        hov[index] = true;
    }else{
        delete hov[index];
    }
    setHovered({...hov});
  }

  const handleToggle = value=> {
   if(checked[value.id]){
    delete checked[value.id];
   }else{
    checked[value.id] = value;
   }
   setChecked({...checked});
  };

  const addToAccepted = value=>{
    checked[value.id] = value;
    setChecked({...checked});
  }

  const handleSubmit = e=>{
    // if(jobdata && jobdata.id && jobdata.swmsAccepted){
//       handleNext();
//     }else{
  // console.log('checked: ',checked);
  // return;
      if(jobdata && jobdata.id && swms.length==0 && jobdata.customSwmsFile ){
        handleNext();
        return;
      }
      if(swms.length==0){
        toast('warning', 'please add at least one service before you proceed!');
        return;
      }
      if(Object.keys(checked).length==swms.length){
        // console.log('ready to update: ',swms);
        // handleTradeContract({content: desc});
        handleUpdateProfessions(swms);
      }else{
        toast('warning','Please read and accept all the swms!')
        // setAnimate(true);
        // setTimeout(()=>{
        //   setAnimate(false);
        // },1000)
      }
//     }

  }

  const handleEditProf = prof=>{
    setCurrentProf(prof);
    setCurrentSection(null);
    profession = prof;
    // editor.value = prof.content;
    // setContent(prof.content);
    // setEditor(editor)
    handleOpen();
  }

  const handleDeleteProf = prof=>{
    // console.log('delete prof: ',prof);
    delete checked[prof.id];
    deleteProfession({id: prof.id});
    setLoading(true);
  }

  const handleEditSection = prof=>{
    // console.log('so you want to edit: ',prof);
    setCurrentSection({...prof, content: JSON.parse(prof.content)});
    setContent(JSON.parse(prof.content));
    toggleEditorModal(true);
  }
  const handleDeleteSection = section=>{
    deleteProfessionPage({id: section.id});
  }

  const handleSaveProfession = ()=>{
    currentProf.content = content;
    setCurrentProf(currentProf);
    profession = currentProf;
    // console.log(currentProf);
    swms.map(s=>{
      if(s.id==currentProf.id) s.content = currentProf.content;
    });
    setSwms(swms);
    // handleToggle(currentProf);
    handleClose();

    // console.log(swms);
  }

  const processNewProfessionPage = res=>{
    if(res.success){
      let cp = {...profession};
      cp.sections.push(res.data);
      setCurrentProf({...cp});
      setContent('');
      profession = cp;
      toggleEditorModal(false);
      toast('success', 'New page added successfully');
    }
  }
  const processUpdateProfessionPage = res=>{
    if(res.success){
      toast('success', 'Page updated successfully');
      // if(profession && profession.id==res.data.documentId){
      //   let secs = profession.sections;
      //   secs.map(sec=>{
      //     if(sec.id==res.data.id){
      //       sec.content = res.data.content;
      //     }
      //   });
      //   profession = {...profession, sections: secs};
      //   setCurrentProf({...profession});
      // }
    }
  }

  const processDeleteProfessionPage = res=>{
    if(res.success){
      toast('success', 'Page deleted successfully');
      profession.sections = profession.sections.filter(sec=> sec.id!=res.data.id);
      setCurrentProf({...profession})
    }
  }

  const handleSaveDocumentSection = ()=>{

    // console.log('currnet prof: ',currentProf);
    // console.log('currnet section: ',currentSection);
    // console.log('current: ', content);

    let currentDoc = {...currentProf};
    if(currentSection){
      updateProfessionPage({...currentSection, content: JSON.stringify(content)});
      currentDoc.sections.map(section=>{
        if(section.id==currentSection.id){
          section.content = JSON.stringify(content);
        }
      });
      let professions = [...swms];
      professions.map(prof=>{
        if(prof.id==currentDoc.id){
          prof.sections = currentDoc.sections;
        }
      });
      setCurrentProf({...currentDoc});
      profession = {...currentDoc};
      setContent('');
      setSwms([...professions]);
      toggleEditorModal(false);
    }else{
      // console.log('this is a new section');
      newProfessionPage({
        documentId: currentProf.id,
        content: JSON.stringify(content)
      })
    }
    
  }

  const handleAcceptAll = e=>{
	var newChecked = {};
	swms.map(s=> newChecked[s.id] = true);
	setChecked(newChecked);
  }
  // console.log(checked);
  // window.editor = editor;
  // window.jodit = JoditEditor;
  let sortedSwms = swms;
  sortedSwms.sort((a, b) => a.title.localeCompare(b.title));
  return (
    <div>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          Your master SWMS (Safe Work Method Statement)
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 2 }}>
            Check the SWMS statment we have generated for you and edit if needed otherwise just accept it to continue.
        </Typography>
        <Button sx={{mb:2}} onClick={handleAcceptAll}>Accept all SWMS</Button>
        {/* <p className="safetyDoc" dangerouslySetInnerHTML={{__html: content}} /> */}
        {/* <div>
        <JoditEditor
          ref={readOnlyEditor}
            value={content}
            config={ReadOnlyConfig}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => {
              // console.log(newContent)
              // setContent(newContent)
            }} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => {
              // console.log(newContent)
              // setContent(newContent)
            }}
        />
        </div> */}
         <Box sx={{mb:2,  width: '100%',
            mx:1,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 400,}}>
          {/* <ResponsiveTableStyle> */}
            <Table className="responsiveTable">
              <Thead>
                <Tr>
                  <Th style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}></Th>
                  <Th style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>Title</Th>
                  <Th style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>Price</Th>
                  <Th style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                   sortedSwms.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.title}`;
                    return (
                      <Tr>
                        <Td style={{padding: 10}}>
                          {
                            checked[value.id] ? (
                              <Checkbox
                                edge="start"
                                checked={checked[value.id]?true: false}
                                tabIndex={-1}
                                disableRipple
                                onClick={()=>handleToggle(value)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            ):null
                          }
                        </Td>
                        <Td style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>{value?.title}</Td>
                        <Td style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>${value?.amount}</Td>
                        <Td style={{padding: 10, textAlign: 'left', cursor: 'pointer'}}>
                          <Box sx={{display: 'flex'}}>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                variant="outlined" sx={{mr:1}} size="small" onClick={()=>handleDeleteProf(value)} edge="end" aria-label="comments"
                                >
                                    delete
                            </LoadingButton>
                            {/* <Button variant="outlined" sx={{mr:1}} size="small" onClick={()=>handleDeleteProf(value)} edge="end" aria-label="comments">
                              delete
                            </Button> */}
                            <Button variant="contained" size="small" onClick={()=>handleEditProf(value)} edge="end" aria-label="comments">
                              {/* <VisibilityIcon  /> */}
                              view
                            </Button>
                          </Box>
                        </Td>
                        
                      </Tr>
                    )

                  })
                }
                
              </Tbody>
            </Table>
          {/* </ResponsiveTableStyle> */}
        </Box>

        {/* {
          sortedSwms.map((value,index) => {
            const labelId = `checkbox-list-label-${value.title}`;
            return (
                <ListItem
                  key={index}
                  sx={{mb:1}}
                  secondaryAction={
                    <Box sx={{display: 'flex'}}>
                      <LoadingButton
                          loading={loading}
                          loadingPosition="start"
                          variant="outlined" sx={{mr:1}} size="small" onClick={()=>handleDeleteProf(value)} edge="end" aria-label="comments"
                          >
                              delete
                      </LoadingButton>
                    
                      <Button variant="contained" size="small" onClick={()=>handleEditProf(value)} edge="end" aria-label="comments">
                        view
                      </Button>
                    </Box>
                    
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined}  dense>
                    <ListItemIcon>
                      {
                        checked[value.id] ? (
                          <Checkbox
                            edge="start"
                            checked={checked[value.id]?true: false}
                            tabIndex={-1}
                            disableRipple
                            onClick={()=>handleToggle(value)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        ):null
                      }
                      

                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value.title} />
                  </ListItemButton>
                </ListItem>
            );
          })
        } */}

        <Modal
          keepMounted
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={isMobile? modalStyleMobile: modalStyleDesktop}>
               <Box  sx={{ m:1 }} ><Logo /></Box>
            <Typography id="modal-modal-title" variant="h3" component="h1" style={{ textAlign: 'center' }} sx={{ mb:3 }}>
              {/* Your master SWMS (Safe Work Method Statement) */}
              {currentProf? currentProf.title: ''}
            </Typography>
            <div style={{height: 'calc(100% - 195px)', overflowY: 'scroll'}}>
              {
                  currentProf?(
                      <div className="pages" >
                          {
                              currentProf.sections && currentProf.sections.map((section, index)=>{
                                  let con = section.content? JSON.parse(section.content): '';
                                  return (
                                      <div>
                                          <StyledPaper
                                              onMouseEnter={()=>toggleEditButton(index, true)}
                                              onMouseLeave={()=>toggleEditButton(index, false)}
                                              elevation={3}
                                              className="editorPage"

                                          >
                                                  <Logo />

                                              <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: con }} />

                                              {
                                                  hovered[index]? (
                                                      <div className="editDocButton" style={{position:'absolute'}}>
                                                          <IconButton onClick={()=>handleEditSection(section)} size="small" variant="contained" >
                                                              <EditIcon />
                                                          </IconButton>

                                                          <IconButton onClick={()=>handleDeleteSection(section)} aria-label="delete">
                                                              <DeleteIcon />
                                                          </IconButton>

                                                      </div>

                                                  ):null
                                              }
                                              <div style={footer}>Copyright © 2022 WeSafe Pty Ltd document generated at: WeSafe.com.au</div>
                                          </StyledPaper>
                                      </div>
                                  )
                              })
                          }

                          {/* <Button onClick={handleNewPage}>New Section</Button>  */}

                      </div>
                  ):null
              }
            {/* <textarea id="editor" name="editor" ></textarea> */}
              {/* <JoditEditor
                  ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => {
                      // console.log(newContent)
                      setContent(newContent);
                      setDesc(newContent);
                    }} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {
                      // console.log(newContent)
                      // setContent(newContent)
                    }}
                /> */}


            </div>

            {/* <Button size="medium" onClick={(e)=>{
                handleSaveProfession();
              }}
              variant="contained" sx={{ mt: 2 }}>
                Save Document
            </Button> */}
              <div style={isMobile? buttonStyleMobile: buttonStyleDesktop} >
                
                <Button size="large" style={{ width:'100%' }} onClick={()=>{
                   handleClose();
                }}
                  variant="outlined" sx={{ mt: 2 }}>
                      Cancel
                </Button>
                <Button size="large" style={{ width:'100%' }} onClick={()=>{
                  setCurrentSection(null);
                   toggleEditorModal(true);
                }}
                  variant="contained" sx={{ mt: 2, ml:2 }}>
                      New Page
                </Button>
                <Button size="large" style={{ width:'100%' }} onClick={()=>{
                   addToAccepted(currentProf);
                   handleClose();
                }}
                  variant="contained" sx={{ mt: 2, ml:2 }}>
                      Accept
                </Button>
              </div>

        </Box>

        </Modal>


        <Dialog fullWidth={true} maxWidth={'lg'}  open={editorModalOpen} onClose={()=> toggleEditorModal(false)} >
            <DialogTitle>{currentSection? "Update Section":"New Section"}</DialogTitle>
            <DialogContent>
                {
                    editorModalOpen?(
                        <div className="editor">
                            {/* <DraftEditor
                                initialContentState={content}
                                onContentStateChange={handleContentChange}
                            /> */}
                            <SunEditor setContents={content} onChange={(content)=>setContent(content)} />
                            <br/>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                onClick={handleSaveDocumentSection}
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                >
                                    {
                                        currentSection?"Update":"Save"
                                    }
                            </LoadingButton>
                        </div>
                    ):null
                }
            </DialogContent>

            {/* <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Send Invite</Button>
            </DialogActions> */}
        </Dialog>


        {
          swms.length==0? (
            <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
          ):null
        }
       
        {
          Object.keys(checked).length==swms.length && swms.length!=0? (
            <Box>
              <Button fullWidth style={{ width:'100%' }} onClick={handleSubmit}
                  variant="contained" sx={{ mt: 2, ml:2 }}>
                     Submit
                </Button>
            </Box>
          ):null
        }
    </div>
  );
}
