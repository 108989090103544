import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { useEffect, useState, useRef } from 'react';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import StepperBottomRow from '../onboard/StepperBottomRow';
import DocPreview, { LoadingPreview } from '../../components/_dashboard/user/profile/doc-templates/DocPreview';
import { fileUpload } from '../../socket';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleWorkCover, jobdata, toast, handleNext, loading, stepperHandlers}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = useRef(null);

  let [provider, setProvider] = useState('');
  let [expiryDate, setExpiryDate] = useState('');
  let [policyNumber, setPolicyNumber] = useState('');
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  // let [cards, setCards] = useState([]);



  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id && jobdata.workCover){
      if(jobdata.workCover.provider) setProvider(jobdata.workCover.provider)
	  if(jobdata.workCover.file) setFile(jobdata.workCover.file);
      if(jobdata.workCover.expiry_date) setExpiryDate(new Date(jobdata.workCover.expiry_date))
      if(jobdata.workCover.policy_number) setPolicyNumber(jobdata.workCover.policy_number)
    }

  },[]);

  // const handleSubmit = e=>{

  //   if(cards.length>0){
  //     handleLicenses(cards);
  //   }else{
  //     toast('warning', 'Please add at least one card!')
  //   }
  // }

  const handleSubmit = e=>{
//     if(jobdata.workCover && jobdata.workCover.id){
//       handleNext();
//     }else{
      if(expiryDate ){
		if(!file){
			toast('warning', 'Please select a valid document copy!');
			return;
		}
		if(expiryDate=='Invalid Date'){
			toast('warning', 'Please select a valid expiry date');
			return;
		}
        let insurance = {
          // provider,
          expiry_date: new Date(expiryDate).toISOString().split('T')[0],
          // policy_number: policyNumber,
          file: file,
          // fileName: file?file.name:''
        };
        handleWorkCover(insurance);

      }else{
		setShowError(true);
        toast('warning', 'Please fill all the fields!')
//         handleWorkCover({workCoverSkipped: 1});
      }
//     }


  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }

  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }
  const handleSkip = ()=>{
	handleWorkCover({workCoverSkipped: 1});
  }

  return (
    <div>
            <Typography variant="h4" sx={{ mb: 3 }}>
             Add your Workers Compensation Insurance (Certificate of Currency)
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 4 }}>
               If you don't have any employees you may skip this step
            </Typography>
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
              p: 2,
              width: 1,
              position: 'relative',
              border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
            }}
            style={{paddingRight: '40px'}}
          >

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={8} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker inputFormat="dd-MM-yyyy"
                label="Expiry Date"
                value={expiryDate}
         		openTo="day"
				error={showError && !expiryDate}
          		views={['day','month','year']}
                onChange={(newValue) => {
                  setExpiryDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            </Grid>

          <Grid item xs={4} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/WC-logo-${isLight ? 'light' : 'dark'}.png`}

            />


          </Grid>
        </Grid>

        {/* <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={6} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField value={policyNumber} error={showError && !policyNumber} onChange={e=>setPolicyNumber(e.target.value)} id="qbcc-n" label="Policy Number" variant="outlined" />
            </Box>
          </Grid>

          <Grid item xs={6} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker inputFormat="dd-MM-yyyy"
                label="Expiry Date"
                value={expiryDate}
         		openTo="day"
				error={showError && !expiryDate}
          		views={['day','month','year']}
                onChange={(newValue) => {
                  setExpiryDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            </Grid>
        </Grid> */}
        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />

        <Grid container spacing={1}>

          <Grid item xs={12} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
                {
                    fileLoading?(
                      <LoadingPreview />
                    ):null
                  }
                  {
                    file?(
                      <DocPreview fileLoading={fileLoading} file={file} />
                    ):null
                  }
              <Button onClick={handleFile} fullWidth size="large" variant="outlined" sx={{ mt: 1 }}>Upload Copy</Button>
            </Box>
          </Grid>

        </Grid>

            <Typography variant="subtitle2"> </Typography>
          </Paper>

      </Stack>
      <StepperBottomRow skipable={true} handleSkip={handleSkip} {...stepperHandlers} handleSubmit={handleSubmit} />
    </div>
  );
}
