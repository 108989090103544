import React from 'react'
import LightboxModal from '../../../../../components/LightboxModal';
import { Button, Typography,Box } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { doc400, doc600 } from '../../../../userImage';
// import axios from 'axios'
// import PDFViewer from 'pdf-viewer-reactjs'

export default function DocPreview({file}){
  let   [currentFile, setCurrentFile] = useState(null);
  let   [fileOpen, setFileOpen] = useState(false);
  // let [image, setImage] = useState(null);

  // useEffect(() => {
  //   if(isObject) setImage(URL.createObjectURL(file));
  //   else setImage(file);

  //   if(typeof file=='string' && file.includes('.pdf')){      
  //     // updateImage(file);
  //   }
  // }, [file,isObject]) 

  // const updateImage = (doc)=>{
  //   // console.log('calling blob for: ',doc);
  //   axios
  //     .get(doc, {
  //       responseType: 'arraybuffer'
  //     })
  //     .then(response => {
  //       const blob = new Blob(
  //         [response.data],
  //         {type: 'image/png'}
  //         // { type: 'application/pdf' }
  //       );
  //       // console.log('got blob for file: ',doc);
  //       // console.log('got blob: ',blob);
  //       setImage(URL.createObjectURL(blob))
  //     }).catch(e=>{
  //       setTimeout(() => {
  //         updateImage(doc);
  //       }, 300);
  //       // updateImage(doc);
  //       // console.log('got fetch error: ',e.message);
  //     })
  // }

  
  const handleViewDoc = file=>{
    setCurrentFile(file);
    setFileOpen(true);
  }

  // console.log('image: ',image);
 
  return (
   <div>
       {
           file?(
              <Box sx={{my:1}}>
                <img style={{borderRadius: '4px', cursor: 'pointer', width: '100%'}}  onClick={e=>handleViewDoc(file)} src={doc400(file)} />
                {/* <img style={{borderRadius: '4px', cursor: 'pointer'}}  onClick={e=>handleViewDoc(image)} src={isObject || (typeof file=='string' && file.includes('.pdf'))? image: doc400(image)} /> */}
              </Box>
            ):(
                <Typography sx={{ml:1}}>Copy not provided </Typography>
            )
       }
       <LightboxModal
        images={[currentFile]}
        photoIndex={0}
        setPhotoIndex={setCurrentFile}
        isOpen={fileOpen}
        onClose={() => setFileOpen(false)}
      />
   </div>
  )
}

export const LoadingPreview = ()=> <Typography sx={{my:1}}>loading preview...</Typography>