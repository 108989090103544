import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useEffect, useState } from 'react';

// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import StepperBottomRow from '../onboard/StepperBottomRow';
import DocPreview, { LoadingPreview } from '../../components/_dashboard/user/profile/doc-templates/DocPreview';
import { fileUpload } from '../../socket';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleLicense, toast, jobdata, handleNext, stepperHandlers}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = React.useRef(null);
  const [showError, setShowError] = React.useState(false);


  let [type, setType] = useState('');
  let [expiryDate, setExpiryDate] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [licenseNumber, setLicenseNumber] = useState('');
  let [licenseClass, setLicenseClass] = useState('');
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);

  useEffect(()=>{
//     console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id){
      if(jobdata.license){
        let license = jobdata.license;
        setType(license.type);
        setFile(license.file);
        setExpiryDate(license.expiry_date);
        setCompanyName(license.company_name);
        setLicenseNumber(license.license_number);
        setLicenseClass(license.license_class);
      }
    }

  },[]);

  const handleSubmit = e=>{

//     if(jobdata && jobdata.license){
//       handleNext();
//     }else{

      if(expiryDate){
        if(!file){
          toast('warning', 'Please select a valid copy of the document!');
          return;
        }
		if(expiryDate=='Invalid Date'){
			toast('warning', 'Please select a valid expiry date');
			return;
		}
        setShowError(false);
        let newCard = {
          // type,
          expiry_date: new Date(expiryDate).toISOString().split('T')[0],
          // company_name: companyName,
          // license_number: licenseNumber,
          // license_class: licenseClass,
          file: file,
          // fileName: file? file.name: ''
        }
        let today = new Date() / 1000;
        let expDate = new Date(expiryDate) / 1000;
        if(expDate<=today){
          toast('warning', 'Invalid expiry date!');
        }else{
          handleLicense(newCard);
          // setExpiryDate('');
//           setType('');
//           setLicenseClass('');
//           setCompanyName('');
//           setLicenseNumber('');
        }

      }else{
//         if(jobdata.isBuilder){
          setShowError(true);
          toast('warning', 'Please fill all the fields!');
        // }else{
//           handleLicense({
//             qbccSkipped: 1
//           })
//         }
        // toast('warning', 'Please fill all the fields!');

      }
//     }
  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }

  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }
  const handleSkip = ()=>{
	handleLicense({
            qbccSkipped: 1
    })
  }

  return (
    <div>
            <Typography variant="h4" sx={{ mb: 3 }}>
             Add your QBCC licence 
            </Typography>

      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
              p: 2,
              width: 1,
              position: 'relative',
              border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
            }}

          >

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={8} >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker inputFormat="dd-MM-yyyy" 
                  label="Expiry Date"
              openTo="day"
              error={showError && !expiryDate}
                views={['day','month','year']}
                  value={expiryDate}
                  onChange={(newValue) => {
                    setExpiryDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

            </Grid>
          <Grid item xs={4} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/QBCC-logo-${isLight ? 'light' : 'dark'}.png`}

            />


          </Grid>
        </Grid>

        {/* <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={6} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField value={licenseNumber} error={showError && !licenseNumber} onChange={e=>setLicenseNumber(e.target.value)} id="qbcc-n" label="Licence Number" variant="outlined" />
            </Box>
          </Grid>

          <Grid item xs={6} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker inputFormat="dd-MM-yyyy" 
                label="Expiry Date"
         		openTo="day"
             error={showError && !expiryDate}
          		views={['day','month','year']}
                value={expiryDate}
                onChange={(newValue) => {
                  setExpiryDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField
				multiline
				id="b-card-rto-number"
				error={showError && !licenseClass}
				value={licenseClass}
				onChange={e=>setLicenseClass(e.target.value)} label="Licence Class" variant="outlined" fullWidth  />
            </Box>
          </Grid>

        </Grid> */}
        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />
        <Grid container spacing={1}>

            <Grid item xs={12} >
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
               {
                    fileLoading?(
                      <LoadingPreview />
                    ):null
                  }
                  {
                    file?(
                      <DocPreview fileLoading={fileLoading} file={file} />
                    ):null
                  }
                <Button onClick={handleFile} fullWidth size="large" variant="outlined" sx={{mt:1}}>Upload QBCC Card</Button>
              </Box>
            </Grid>

          </Grid>


            <Typography variant="subtitle2"> </Typography>
          </Paper>

      </Stack>


      <StepperBottomRow {...stepperHandlers} handleSkip={handleSkip} skipable={jobdata && (jobdata.isSubcontractor || jobdata.isSupervisor)? true: false} handleSubmit={handleSubmit} />
    </div>
  );
}
