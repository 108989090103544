import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import LightboxModal from '../../../../../components/LightboxModal';

import Page from '../../../../../components/Page';
import { doc400 } from '../../../../userImage';
import DocPreview, { LoadingPreview } from './DocPreview';
import { fileUpload } from '../../../../../socket';
import { FDate } from 'src/constants/defaultValues';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({doc, isMobile, toast, editable, handleUpdateDoc,handleAddDoc,loading}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = React.useRef(null);

  let [name, setName] = useState('');
  let [rtoNumber, setRtoNumber] = useState('');
  let [issueDate, setIssueDate] = useState('');
  let [soa, setSoa] = useState('');
  let [pin, setPin] = useState('');

  let   [currentFile, setCurrentFile] = useState(null);
  let   [fileOpen, setFileOpen] = useState(false);
  let [cards, setCards] = useState([]);
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);


  useEffect(()=>{
    if(doc && doc.id){
        if(doc.name) setName(doc.name);
        if(doc.soa) setSoa(doc.soa);
        if(doc.rto_number) setRtoNumber(doc.rto_number);
        if(doc.issue_date) setIssueDate(doc.issue_date);
        if(doc.pin) setPin(doc.pin);
    }

  },[doc]);


  const handleSubmit = e=>{
    // if(name && soa && rtoNumber && issueDate && pin){
		// if(issueDate=='Invalid Date'){
		// 	toast('warning', 'Please select a valid issue date');
		// 	return;
		// }
      let newCard = {
        // name,
        // soa: soa,
        // rto_number: rtoNumber,
        // issue_date: new Date(issueDate).toISOString().split('T')[0],
        // pin: pin,
      };

      if(file){
        newCard.file = file;
      }

      if(!newCard.file){
        toast('warning',"Please upload the copy of the card!");
        return;
      }


      if(doc && doc.id){
        handleUpdateDoc(newCard);
      }else{
        
        handleAddDoc(newCard);
      }

    //   setSoa('');
    //   setName('');
    //   setPin('');
    //   setRtoNumber('');
    //   setIssueDate('');
    // }else{
    //     toast('warning','please fill all the fields!')
    // }

  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }

  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }


  const handleViewDoc = doc=>{
    setCurrentFile(doc.file);
    setFileOpen(true);
  }

  const stackStyle = {};
  if(isMobile){
    stackStyle.maxHeight = '90vh';
    stackStyle.overflowY = 'auto';
  }

  return (
    <div style={stackStyle}>
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
              p: 2,
              width: 1,
              position: 'relative',
              border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
            }}
          >

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={2} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/NRT-logo-${isLight ? 'light' : 'dark'}.png`}

            />
          </Grid>

          <Grid item xs={8} >
             <Typography variant="subtitle1" sx={{ fontSize: 15, textAlign: 'center', marginTop: 1 }}>Competency Card</Typography>
          </Grid>
          <Grid item xs={2} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/DT-logo-${isLight ? 'light' : 'dark'}.png`}

            />
          </Grid>
        </Grid>

        {/* <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={7} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
                {
                  editable?(
                    <TextField value={name} onChange={e=>setName(e.target.value)} id="b-card-name" label="Full Name" variant="outlined" />
                  ):(
                      <Typography sx={{borderBottom: `solid 1px ${theme.palette.grey[500_32]}`}}> {name}</Typography>
                  )
                }

            </Box>
          </Grid>

          <Grid item xs={5} >
                {
                  editable?(
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker inputFormat="dd-MM-yyyy" 
                            label="Issue Date"
                            openTo="day"
                            views={['day','month','year']}
                            value={issueDate}
                            onChange={(newValue) => {
                            setIssueDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                  ):(
                      <Typography sx={{borderBottom: `solid 1px ${theme.palette.grey[500_32]}`}}> {FDate(issueDate)}</Typography>
                  )
                }


            </Grid>
        </Grid>
        <Box
            component="form"
            noValidate
            sx={{mb:2}}
            autoComplete="off"
        >
            <Typography variant="subtitle1" sx={{ fontSize: 15, textAlign: 'center', marginTop: 1 }}>Has attained competency in:<br />RIIOHS204A Work Safety at Heights</Typography>
        </Box>
        <Grid container spacing={1} sx={{mb: 2}}>
          <Grid item xs={5} >

                {
                  editable?(
                    <TextField value={soa} onChange={e=>setSoa(e.target.value)} id="b-card-no" label="SOA" variant="standard" size="small" />
                  ):(
                      <Typography sx={{borderBottom: `solid 1px ${theme.palette.grey[500_32]}`}}> {soa}</Typography>
                  )
                }


          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
                {
                  editable?(
                    <TextField value={pin} onChange={e=>setPin(e.target.value)} id="b-card-no" label="PIN" variant="standard" size="small" />
                  ):(
                      <Typography sx={{borderBottom: `solid 1px ${theme.palette.grey[500_32]}`}}> {pin}</Typography>
                  )
                }

            </Box>
          </Grid>
          <Grid item xs={6} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
                {
                  editable?(
                    <TextField value={rtoNumber} onChange={e=>setRtoNumber(e.target.value)} id="b-card-no" label="RTO" variant="standard" size="small" />
                  ):(
                      <Typography sx={{borderBottom: `solid 1px ${theme.palette.grey[500_32]}`}}> {rtoNumber}</Typography>
                  )
                }

            </Box>
          </Grid>

        </Grid> */}
      </Paper>

      </Stack>

      <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />
        {
          editable?(
            <Grid container spacing={1}>

            <Grid item xs={12} >
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                {
                    fileLoading?(
                      <LoadingPreview />
                    ):null
                  }
                  {
                    file?(
                      <DocPreview fileLoading={fileLoading} file={file} />
                    ):null
                  }
                <Button onClick={handleFile} sx={{mt:1}} fullWidth size="large" variant="outlined">Upload Card</Button>
              </Box>
            </Grid>

          </Grid>
          ):null
        }
        {
            editable?(
              <LoadingButton fullWidth variant="contained" loading={loading} sx={{mt:2}} onClick={handleSubmit}> {handleUpdateDoc?'Update':'Add'} </LoadingButton>
            ): <DocPreview file={doc.file} />
        }

    </div>
  );
}
