import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material

import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField, Grid, useMediaQuery, Modal } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import { useEffect, useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import {arrangeDocIndexes, documentStyle, periodTradeContract} from '../../constants/defaultValues';
import Logo from '../../components/Logo';
import StepperBottomRow from '../onboard/StepperBottomRow';
import Paper from '@mui/material/Paper';
import DraftEditor from '../../components/editor/draft';
import {
  Page as DocPage,
  Text,
  View,
  Image,
  Document,
  usePDF,
  Font,
  StyleSheet
} from "@react-pdf/renderer";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));
const paperStyleDesktop = {
  padding: '40px',
  position: 'relative',
  marginBottom: '15px',
  borderRadius: '0px',
  maxWidth: '794px',
  minHeight: '1123px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'justify',
  textJustify: 'inter-word'

};
const paperStyleMobile = {
  padding: '10px',
  position: 'relative',
  marginBottom: '15px',
  borderRadius: '0px',
  maxWidth: '794px',
  minHeight: '1123px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'justify',
  textJustify: 'inter-word'

};

const modalStyleDesktop = {
  position: 'absolute',
  width: 'calc(100% - 20px)',
  height: 'calc(100% - 20px)',
  bgcolor: '#161C24',
  borderRadius:'10px',
  boxShadow: 24,
  padding: '30px',
  top: '10px',
  left: '10px',

};
const modalStyleMobile = {
  position: 'absolute',
  width: 'calc(100% - 0px)',
  height: 'calc(100% - 0px)',
  bgcolor: '#161C24',
  borderRadius:'0px',
  boxShadow: 24,
  padding: '0px',
  top: '0px',
  left: '0px',

};

const buttonStyleDesktop = {
 	width: '500px',
  display:'flex',
 	position:'relative',
 	marginLeft:'auto',
	marginRight:'auto',
};
const buttonStyleMobile = {
  width: '100%',
  display:'flex'
};
// ----------------------------------------------------------------------



export default function VerifyCode({handleWhsAccept: handleAcceptDoc, toast, jobdata, handleNext, user, stepperHandlers}) {
  let [isOpen, setOpen] = useState(false);
  const handleClose = e=> setOpen(false);
  const handleOpen = e=> setOpen(true);
  // const editor = useRef(null);
  const readOnlyEditor = useRef(null);
  const [accepted, setAccepted] = useState(false);
	const [sections, setSections] = useState([])
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  useEffect(()=>{
    if(jobdata && jobdata.id && jobdata.whsPlan){
        setSections(jobdata.whsPlan);
    }
  },[]);

  const handleSubmit = e=>{
    handleAcceptDoc({
        whsAcceptDate: new Date(new Date().toUTCString()).toISOString().slice(0, 19).replace('T', ' ')
    });
    setTimeout(()=>{
      handleClose();
    },300);

  }

  return (
    <div>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          "Workplace Health and Safety Management Plan" from your employer
        </Typography>
        <Modal
          keepMounted
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"

        >


          <Box sx={isMobile? modalStyleMobile: modalStyleDesktop}>
            <Box  sx={{ m:1 }} ><Logo /></Box>
            <Typography id="modal-modal-title" variant="h3" component="h1" style={{ textAlign: 'center' }} sx={{ mb:3 }}>
              Workplace Health and Safety Management Plan (WHS)
            </Typography>

            <div style={{height: 'calc(100% - 180px)', overflowY: 'scroll'}}>
              <Document>
              {
                sections.length>0?(
                    <DocPage size="A4" style={documentStyle.body} wrap>
                        <Paper 
                        elevation={3} style={isMobile? paperStyleMobile: paperStyleDesktop}
                        className="editorPage"
                        >
                            {
                                arrangeDocIndexes(sections).map((doc, index)=>{
                                    // let con = JSON.parse(doc.content);
                                    // console.log('here: ',content);
                                    return (
                                        <div
                                        style={{position: 'relative'}}>
                                          {
                                              doc.title && doc.title.length>0?(
                                                <Text style={documentStyle.subtitle}>
                                                    {doc?.index} . {doc?.title}
                                                </Text>
                                              ):null
                                          }
                                            <Text style={documentStyle.text}>
                                                <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: doc?.content }} />
                                            </Text>
                                        </div>
                                        
                                    )
                                
                                })
                            }
                            
                        </Paper>
                    </DocPage>
                ):null
              }
              </Document>
                {/* {
                  sections.map((section, index) => {
                    let con = JSON.parse(section.content);
                    return (
                      <div>
                        <Paper
                          elevation={3}
                          style={isMobile? paperStyleMobile: paperStyleDesktop}
                        >
                          <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: con }} />
                        </Paper>
                      </div>
                    );
                  })
                } */}



            </div>

              <div style={isMobile? buttonStyleMobile: buttonStyleDesktop}>

                <Button size="large" onClick={handleClose} style={{ width:'100%' }}
                  variant="outlined" sx={{ mt:1, mr: 2 }}>
                      Cancel
                </Button>
                <Button size="large" onClick={handleSubmit} style={{ width:'100%' }}
                  variant="contained" sx={{ mt:1 }}>
                      Accept Contract
                </Button>
              </div>
        </Box>

        </Modal>



        <StepperBottomRow {...stepperHandlers} handleSubmit={handleOpen} />
    </div>
  );
}
