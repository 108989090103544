import React from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material

import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField,Card, Grid, useMediaQuery, Modal, List, ListItem } from '@material-ui/core';
import ListItemIcon from '@mui/material/ListItemIcon';

import ListItemText from '@mui/material/ListItemText';
import DoneIcon from '@mui/icons-material/Done';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import { useEffect, useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import {periodTradeContract} from '../../constants/defaultValues';
import StepperBottomRow from '../onboard/StepperBottomRow';
import CheckoutForm from '../../components/stripe/checkoutForm';
function PaymentInfo({ stepperHandlers, jobdata, handleStripeToken, updateToken, setLoading, toast, customer, handleNext, isMobile}){

     const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

    const handleSubmit = ()=>{
        // console.log('customer: ',customer);
        if(customer && customer.sources.data.length>0){
            handleNext();
        }else{
            // toast('warning', 'Please submit the card details first!');
            let submitButton = document.querySelector('.addCardButton');
            if(submitButton) submitButton.click();
        }
    }
    const handleSkip = e=>{
      // console.log('want to skip this step', jobdata);
      setLoading(true);
      updateToken({
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor
      });
    }
    return (
      <div>
        <Typography sx={{ color: 'text.secondary', mb: 1 }}>By using WeSafe to manage your safety documentation your documents will always be up to scratch and ready to send or produce for any builder or any safety inspector anytime, anywhere. WeSafe charges a small monthly fee of $47 per month with the 1st month free of charge.</Typography>
        <Typography sx={{ color: 'text.secondary', mb: 1, fontWeight:'bold', }}>Why use WeSafe?</Typography>

            <List dense={dense}>

                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Produce digital up  to date safety documentation on the spot"
                    sx={{ color: 'text.secondary'}}
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Have up to date documents for safety inspectors "
                    secondary={secondary ? 'Secondary text' : null}
                    sx={{ color: 'text.secondary'}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="You won’t be a builder’s safety liability anymore"
                    secondary={secondary ? 'Secondary text' : null}
                    sx={{ color: 'text.secondary'}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Your employees won’t be a safety liability to you anymore"
                    secondary={secondary ? 'Secondary text' : null}
                    sx={{ color: 'text.secondary'}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Never worry about safety paperwork on the job again"
                    secondary={secondary ? 'Secondary text' : null}
                    sx={{ color: 'text.secondary'}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Use the WeSafe mobile app to manage your teams safety documentation "
                    secondary={secondary ? 'Secondary text' : null}
                    sx={{ color: 'text.secondary'}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Track when and on what job site your employees are currently working on and when they arrived and leave the job site"
                    secondary={secondary ? 'Secondary text' : null}
                    sx={{ color: 'text.secondary'}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Customer support "
                    secondary={secondary ? 'Secondary text' : null}
                    sx={{ color: 'text.secondary'}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon sx={{ color:'#0eea0e' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Free Training provided (as needed)"
                    secondary={secondary ? 'Secondary text' : null}
                    sx={{ color: 'text.secondary'}}
                  />
                </ListItem>

            </List>

        <Typography sx={{ color: 'text.secondary', mb: 4 }}>Enter a credit/debit card below. Memberships can be cancelled at anytime. <strong>Your card will not be charged at this stage.</strong></Typography>
        <Card style={{width: '100%', padding: '20px'}} >
            <CheckoutForm hideButton={true} handleToken={handleStripeToken} />
            <Button onClick={handleSkip} >Skip this step</Button>
        </Card>

        <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />

      </div>
    )



  }

  export default PaymentInfo;