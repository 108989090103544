import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { useEffect, useState } from 'react';

// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import StepperBottomRow from '../onboard/StepperBottomRow';
import DocPreview, { LoadingPreview } from '../../components/_dashboard/user/profile/doc-templates/DocPreview';
import { fileUpload } from '../../socket';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleBlueCard, toast, jobdata, handleNext, stepperHandlers}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = React.useRef(null);
  const [showError, setShowError] = useState(false);

  let [name, setName] = useState('');
  let [dob, setDob] = useState('');
  let [rtoNumber, setRtoNumber] = useState('');
  let [issueDate, setIssueDate] = useState('');
  let [cardNmber, setCardNumber] = useState('');
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);
  let [cards, setCards] = useState([]);



  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id){
      if(jobdata.blueCard){
        setName(jobdata.blueCard.name);
		setFile(jobdata.blueCard.file);
//         setDob(jobdata.blueCard.dob);
        setIssueDate(jobdata.blueCard.issue_date);
        setRtoNumber(jobdata.blueCard.rto_number);
        setCardNumber(jobdata.blueCard.card_number);
      }
    }

  },[]);

  const handleSubmit = e=>{


      // if(name && rtoNumber && issueDate && cardNmber){
		if(!file){
			toast('warning', 'Please select a valid document copy!');
			return;
		}
		// if(issueDate=='Invalid Date'){
		// 	toast('warning', 'Please select a valid issue date');
		// 	return;
		// }
        let newCard = {
          // name,
          // rto_number: rtoNumber,
          file: file,
          // issue_date: new Date(issueDate).toISOString().split('T')[0],
          // card_number: cardNmber,
        }
//         setDob('');
        setName('');
        setCardNumber('');
        setRtoNumber('');
        setIssueDate('');
		    setFile('');
        handleBlueCard(newCard);
    //   }else{
		// setShowError(true);
    //     toast('warning', 'please fill all the fields!');
    //   }


  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }
  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }

  const handleSkip = ()=>{
	handleBlueCard({
            blueCardSkipped: 1
    })
  }


//  const addCard = e=>{
//    if(name && dob && rtoNumber && issueDate && cardNmber){
//      let newCards = [...cards, {
//        name,
//        dob: new Date(dob).toISOString().split('T')[0],
//        rto_number: rtoNumber,
//        issue_date: new Date(issueDate).toISOString().split('T')[0],
//        card_number: cardNmber,
//      }];
//      setCards(newCards);
//      setDob('');
//      setName('');
//      setCardNumber('');
//      setRtoNumber('');
//      setIssueDate('');
//    }else{
//      toast('warning', 'please fill all the fields!');
//    }
//
//  }

  return (
      <div >
            <Typography variant="h4" sx={{ mb: 3 }}>
             Add your White/Blue card
            </Typography>
            {
              cards.length>0?(
                <Typography sx={{ color: 'text.secondary', mb: 4 }}>
                  {cards.length} card added
                </Typography>
              ):null
            }

      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
              p: 2,
              minHeight: 240,
              width: 1,
              position: 'relative',
              border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
            }}
            style={{paddingRight: '40px'}}
          >
                <Box sx={{
                    position: 'absolute',
                    right: 1,
                    fontSize: 17,
                    textTransform: 'capitalize',
                    fontWeight: 'lighter',
                    background: '#510101',
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    pb: 1 ,
                    pt: 1 ,
                    pl: 1 ,
                    pr: 1 ,
                    color:'white',
                    }}
                    style={{borderRadius: '0px 7px 7px 0px', fontFamily: 'sansSerif'}}
                    >
                Q <br />
                u <br />
                e <br />
                e <br />
                n <br />
                s <br />
                l <br />
                a <br />
                n<br />
                d
            </Box>

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={5} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/QLD-logo-${isLight ? 'light' : 'dark'}.png`}

            />
          </Grid>

          <Grid item xs={7} >
             <Typography variant="subtitle2" sx={{ fontSize: 10, }}>OCCUPATIONAL HEALTH AND SAFETY</Typography>
             <Typography variant="subtitle1" sx={{ fontSize: 15, textAlign: 'center', marginTop: 1 }}>CONSTRUCTION <br />INDUCTION</Typography>
          </Grid>
        </Grid>

        {/* <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField fullWidth value={name} error={showError && !name} onChange={e=>setName(e.target.value)} id="b-card-name" label="Full Name" variant="outlined" />
            </Box>
          </Grid>
	
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={7} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField error={showError && !rtoNumber} value={rtoNumber} onChange={e=>setRtoNumber(e.target.value)} id="b-card-rto-number" label="RTO Number" variant="outlined"  />
            </Box>
          </Grid>

          <Grid item xs={5} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker inputFormat="dd-MM-yyyy"
                label="Issue Date"
         		openTo="day"
          		views={['day','month','year']}
				error={showError && !issueDate}
                value={issueDate}
                onChange={(newValue) => {
                  setIssueDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />

            </LocalizationProvider>

            </Grid>

        </Grid> */}
            {/* <TextField value={cardNmber} error={showError && !cardNmber} onChange={e=>setCardNumber(e.target.value)} id="b-card-no" label="Card No" variant="standard" size="small" /> */}
            <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />
        <Grid container spacing={1}>

            <Grid item xs={12} >
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                {
                    fileLoading?(
                      <LoadingPreview />
                    ):null
                  }
                  {
                    file?(
                      <DocPreview fileLoading={fileLoading} file={file} />
                    ):null
                  }
                <Button onClick={handleFile} fullWidth size="large" variant="outlined" sx={{mt:1}}>Upload White/Blue Card</Button>
              </Box>
            </Grid>

          </Grid>
            <Typography variant="subtitle2"> </Typography>
          </Paper>




      </Stack>

      {/* <Box sx={{ mt: 3 }}>
        {
        //<Button size="small" onClick={addCard} startIcon={<Icon icon={plusFill} />} >
        //  Save card
        //</Button>
        }
      </Box> */}
      <StepperBottomRow handleSkip={handleSkip} skipable={jobdata && jobdata.isEmployee? true: false} {...stepperHandlers} handleSubmit={handleSubmit}  />


    </div>
  );
}
