import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment, Alert,Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks

import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';

import { useParams } from 'react-router-dom';

// socket events
import { newUser, getInvitee, getIDandLogin } from '../../../socket';
import { checkIfMobile, userRoleNames } from '../../../constants/defaultValues';
// ----------------------------------------------------------------------
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useSettings from 'src/hooks/useSettings';

export default function RegisterForm() {
  const { register, Login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  let [loading, setLoading] = useState(false);
  let [invitee, setInvitee] = useState(null);
  let [role, setRole] = useState(2);
  const params = useParams(); // {token: 'kdfjlkfa'}
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [email, setEmail] = useState('');
  let [registerAs, setRegisterAs] = useState('Builder');
  const {themeMode} = useSettings();

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const processNewUser = res=>{
    setLoading(false);
    if(res.success){
      userLogin();
      enqueueSnackbar('Register success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }else{
      enqueueSnackbar(res.msg || 'something went wrong!', {
        variant: 'warning',
        autoHideDuration: 4000,
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }

  }
  const userLogin = async ()=>{

    setLoading(true);
    try {
      await Login(document.querySelector('input[name="email"]').value, document.querySelector('input[name="password"]').value);
      getIDandLogin();
      setLoading(false);
      enqueueSnackbar('Login success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      enqueueSnackbar(error.message || 'something went wrong!', {
        variant: 'warning',
        autoHideDuration: 4000,
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  }

  const processInvitee = res=>{
    console.log('invitee res: ',res);
    if(res.success){
      setInvitee(res.data);
      let user = res.data;
	  setRole(user.roleId);
      let names = user.name.split(' ');
      if(names[0]) setFirstName(names[0]);
      if(names[1]) setLastName(names[1]);
      if(user.email) setEmail(user.email);
    }else{
      enqueueSnackbar('invitation link is invalid or expired!', {
        variant: 'warning',
        autoHideDuration: 5000,
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  }

  useEffect(()=>{

    newUser(processNewUser);
    getInvitee(processInvitee);

    if(params && params.token && params.token.length>0){
      getInvitee({token: params.token});
      if(checkIfMobile() && localStorage[params.token]!=1){
        setTimeout(function () {
          window.location = `https://wesafe.com.au/auth/register/${params.token}`;
          // window.location = 'https://itunes.apple.com/appdir'
          localStorage.setItem(params.token, 1);
        }, 25);
        window.location = `wesafe://${params.token}`;
      }

    }

    if(params && params.role){
      setRole(params.role)
    }

  },[])

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      // console.log('invitee: ',invitee);
      // console.log('values: ' ,values);
      // console.log('role: ',role);
      // return;
      setLoading(true);
      if(invitee && invitee.id){
        // this user was invited so his phone number should be already verified
        var user = {...values, phone: invitee.phone, isInvited: true, invite: invitee };
        newUser(user);
      }else{
        newUser({...values, role});
      }
      // setLoading(true);
      // newUser(values);

      // setSubmitting(true);
      /*
      try {
        await register(values.email, values.password, values.first_name, values.last_name);
        enqueueSnackbar('Register success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }

      */
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          {
            !invitee?(
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  sx={{color: themeMode=='dark'?'white':'black'}}
                  label="Role"
                  onChange={e=>setRole(e.target.value)}
                >
                  <MenuItem value={2}>Builder</MenuItem>
                  <MenuItem value={3}>Subcontractor</MenuItem>
                  {/* <MenuItem value={4}>Employee</MenuItem> */}
                  {/* <MenuItem value={6}>Supervisor</MenuItem> */}
                </Select>
              </FormControl>
            ):null
          }
          

				<Typography sx={{ color: 'text.secondary' }}>Registration as {userRoleNames[role]}</Typography>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            Let's get WeSafe!
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
