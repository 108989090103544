import axios from 'axios';
import {serverBaseUrl} from '../constants/defaultValues';
// ----------------------------------------------------------------------
const axiosConfig = {
  baseURL: serverBaseUrl,
  timeout: 30000,
};
const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
