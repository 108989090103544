import dataSpew from 'socket.io-client';
// import {configureStore} from './redux/store';
import {socketServerUrl, stripePublicKey} from './constants/defaultValues';
import jwt_decode from 'jwt-decode';
import { SocketAddress } from 'net';
const socket = dataSpew(socketServerUrl);


///////////////////////////////////////////////////////////
////helper functions for server auth///////////////////////
let userStore;
// function getUser(){
// 	userStore = configureStore().getState().authUser.user;
// }

function getUser(){
	//console.log( "!!! getUser !!!" )
	// userStore = configureStore().getState().authUser.user;
	// console.log(userStore);
	if(localStorage.accessToken){
		const userData = jwt_decode(localStorage.accessToken);
		var userdata = {
		  token: localStorage.getItem('accessToken'),
		  user: userData
		}

		userStore=userdata;
	  }

}
function getIDandLogin(){
	getUser();
	// socket.emit('login', {token:userStore ? userStore.user.token : {user:{token:0}} });//immediate login
	socket.emit('login', {token:userStore ? {pass:userStore.user.password, email: userStore.user.email}: null });//immediate login
}
///////////////////////////////////////////////////////////
////automatic persistant server connection with auth///////
socket.on('welcome', data => {
	console.log(data.welcome)
	getIDandLogin();//re/connect login
});
socket.on('disconnect', data => {
	console.log("disconnected");
	// loggedin = {}

});
let reconn = 0;
let loggedin = {};
socket.on('login', data => {
	console.log("login response:",data)
	loggedin = data;
	if(data.remove){
		localStorage.removeItem('accessToken');
		window.location.href = '/';
	}else{
		if(!data.auth && reconn < 5){
			setTimeout(()=>{
				getIDandLogin();//re/connect login
				reconn++
			},1000);
		}else{
			reconn = 0;

			getNews(); // TODO move this to news page 'loginOn' listener
		}
	}


});
///////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////
////exportable functions for the API///////////////////////

function reply(data) {  // for server logging
  socket.emit('reply', data);
}
function messagelistener(callback) {
  socket.off('servermessage').on('servermessage', callback);//implicitly passes 'data' to callback
}
function loginOn(callback){
	if(typeof callback === "function"){
		socket.on('login',callback);
	} else {
		socket.emit('login');
	}
}
function login(cb,off) {
	// console.log('inside login method: ',cb,off);
	if(off)
		socket.off('login',cb);
	else{
		socket.on('login', cb); //same: data => cb(data)
	}
}
function loginOff(callback){
	socket.off('login',callback)
}
function loginOk(){
	return loggedin;
}

/////
// this function sets a new listener each time it is called - you will need to call the appropriate 'off' function in componentWillUnmount or you will end up with many listeners.
// it is suitable for multiple instances of the listener on the page.
function getNews(callback){
	if(typeof callback === "function"){
		socket/*.off('getNews')*/.on('getNews', callback); //implicitly passes 'data' to callback
	} else {
		socket.emit('getNews');
	}
}
// this is the appropriate 'off' function
function getNewsOff(callback){
	socket.off('getNews',callback);
}

/////
// this function unsets all listeners by this name & then sets a single new listener.
// it is suitable only for a single instance on the page.
function getAllContacts(callback){
	if(typeof callback === "function"){
		socket.off('getAllContacts').on('getAllContacts', callback); //implicitly passes 'data' to callback
	} else {
		socket.emit('getAllContacts');
	}
}

function getChats(callback){
	if(typeof callback === "function"){
		socket.off('getChats').on('getChats', callback); //implicitly passes 'data' to callback
	} else {
		socket.emit('getChats');
	}
}
function recentConversations(callback){
	if(typeof callback === "function"){
		socket.off('recentConversations').on('recentConversations', callback); //implicitly passes 'data' to callback
	} else {
		socket.emit('recentConversations');
	}
}
function rooms(callback){
	if(typeof callback === "function"){
		socket.off('rooms').on('rooms', callback); //implicitly passes 'data' to callback
	} else {
		socket.emit('rooms');
	}
}

function getChatHistory(payload){
	if(typeof payload === "function"){
		socket.off('getChatHistory').on('getChatHistory', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('getChatHistory',payload);
	}
}
function userLogin(payload){
	if(typeof payload === "function"){
		socket.off('userLogin').on('userLogin', payload); //implicitly passes 'data' to callback
	} else {
		console.log('calling userLogin api');
		socket.emit('userLogin',payload);
	}
}
function settings(cb,off) {
	// console.log('inside settings event with data as ',cb,off);
	if(off){
		//settings(namedfunc,"off")
		socket.off('settings',cb);
		console.log("settings off")
	}else{
		//settings(namedfunc)
		if(typeof cb === "function"){
			socket.on('settings', cb); //same: data => cb(data)
			console.log("settings callback")
		}
		//settings( {get:"punter|follower" | set:"column", data:"value"} )
		else if(typeof cb === "object"){
			socket.emit("settings",cb);
			console.log("settings request",cb)
		}
		//settings( "punter|follower" )
		else if(typeof cb === "string"){
			socket.emit("settings",{get:cb});
			console.log("settings get:",cb)
		}
		//
		else console.log("unknown 'settings' api request");
	}
}

function onlineUsers(payload){

	if(typeof payload === "function"){
		socket.on('onlineUsers', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('onlineUsers',payload);
	}
}

function sendMessage(payload, off){
	if(off){
		socket.off('sendMessage',payload);
	}else if(typeof payload === "function"){
		//console.trace("sendMessage")
		//console.log("sendMessage function");
		socket.on('sendMessage', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("sendMessage normal");
		socket.emit('sendMessage',payload);
	}
}

function getBuilders(payload, off){
	if(off){
		socket.off('getBuilders',payload);
	}else if(typeof payload === "function"){
		//console.trace("getBuilders")
		//console.log("getBuilders function");
		socket.on('getBuilders', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getBuilders normal");
		socket.emit('getBuilders',payload);
	}
}
function joinTeam(payload, off){
	if(off){
		socket.off('joinTeam',payload);
	}else if(typeof payload === "function"){
		//console.trace("joinTeam")
		//console.log("joinTeam function");
		socket.on('joinTeam', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("joinTeam normal");
		socket.emit('joinTeam',payload);
	}
}
function acceptJoinTeam(payload, off){
	if(off){
		socket.off('acceptJoinTeam',payload);
	}else if(typeof payload === "function"){
		//console.trace("acceptJoinTeam")
		//console.log("acceptJoinTeam function");
		socket.on('acceptJoinTeam', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("acceptJoinTeam normal");
		socket.emit('acceptJoinTeam',payload);
	}
}
function allBuilders(payload, off){
	if(off){
		socket.off('allBuilders',payload);
	}else if(typeof payload === "function"){
		//console.trace("allBuilders")
		//console.log("allBuilders function");
		socket.on('allBuilders', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("allBuilders normal");
		socket.emit('allBuilders',payload);
	}
}
function unreadMessagesCount(payload, off){
	if(off){
		socket.off('unreadMessagesCount',payload);
	}else if(typeof payload === "function"){
		//console.trace("unreadMessagesCount")
		//console.log("unreadMessagesCount function");
		socket.on('unreadMessagesCount', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("unreadMessagesCount normal");
		socket.emit('unreadMessagesCount',payload);
	}
}
function getSafetyDocs(payload, off){
	if(off){
		socket.off('getSafetyDocs',payload);
	}else if(typeof payload === "function"){
		//console.trace("getSafetyDocs")
		//console.log("getSafetyDocs function");
		socket.on('getSafetyDocs', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getSafetyDocs normal");
		socket.emit('getSafetyDocs',payload);
	}
}
function newSubstance(payload, off){
	if(off){
		socket.off('newSubstance',payload);
	}else if(typeof payload === "function"){
		//console.trace("newSubstance")
		//console.log("newSubstance function");
		socket.on('newSubstance', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("newSubstance normal");
		socket.emit('newSubstance',payload);
	}
}
function updateSafetyDoc(payload, off){
	if(off){
		socket.off('updateSafetyDoc',payload);
	}else if(typeof payload === "function"){
		//console.trace("updateSafetyDoc")
		//console.log("updateSafetyDoc function");
		socket.on('updateSafetyDoc', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("updateSafetyDoc normal");
		socket.emit('updateSafetyDoc',payload);
	}
}
function updateUserDocs(payload, off){
	if(off){
		socket.off('updateUserDocs',payload);
	}else if(typeof payload === "function"){
		//console.trace("updateUserDocs")
		//console.log("updateUserDocs function");
		socket.on('updateUserDocs', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("updateUserDocs normal");
		socket.emit('updateUserDocs',payload);
	}
}
function updateUserDoc(payload, off){
	if(off){
		socket.off('updateUserDoc',payload);
	}else if(typeof payload === "function"){
		//console.trace("updateUserDoc")
		//console.log("updateUserDoc function");
		socket.on('updateUserDoc', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("updateUserDoc normal");
		socket.emit('updateUserDoc',payload);
	}
}
function getInvites(payload, off){
	if(off){
		socket.off('getInvites',payload);
	}else if(typeof payload === "function"){
		//console.trace("getInvites")
		//console.log("getInvites function");
		socket.on('getInvites', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getInvites normal");
		socket.emit('getInvites',payload);
	}
}
function getDocument(payload, off){
	if(off){
		socket.off('getDocument',payload);
	}else if(typeof payload === "function"){
		//console.trace("getDocument")
		//console.log("getDocument function");
		socket.on('getDocument', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getDocument normal");
		socket.emit('getDocument',payload);
	}
}
function getAdminDoc(payload, off){
	if(off){
		socket.off('getAdminDoc',payload);
	}else if(typeof payload === "function"){
		//console.trace("getAdminDoc")
		//console.log("getAdminDoc function");
		socket.on('getAdminDoc', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getAdminDoc normal");
		socket.emit('getAdminDoc',payload);
	}
}
function getTerms(payload, off){
	if(off){
		socket.off('getTerms',payload);
	}else if(typeof payload === "function"){
		//console.trace("getTerms")
		//console.log("getTerms function");
		socket.on('getTerms', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getTerms normal");
		socket.emit('getTerms',payload);
	}
}
function getUnverifiedDocs(payload, off){
	if(off){
		socket.off('getUnverifiedDocs',payload);
	}else if(typeof payload === "function"){
		//console.trace("getUnverifiedDocs")
		//console.log("getUnverifiedDocs function");
		socket.on('getUnverifiedDocs', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getUnverifiedDocs normal");
		socket.emit('getUnverifiedDocs',payload);
	}
}
function verifyDoc(payload, off){
	if(off){
		socket.off('verifyDoc',payload);
	}else if(typeof payload === "function"){
		//console.trace("verifyDoc")
		//console.log("verifyDoc function");
		socket.on('verifyDoc', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("verifyDoc normal");
		socket.emit('verifyDoc',payload);
	}
}
function getDocumentSections(payload, off){
	if(off){
		socket.off('getDocumentSections',payload);
	}else if(typeof payload === "function"){
		//console.trace("getDocumentSections")
		//console.log("getDocumentSections function");
		socket.on('getDocumentSections', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getDocumentSections normal");
		socket.emit('getDocumentSections',payload);
	}
}
function addDocumentSection(payload, off){
	if(off){
		socket.off('addDocumentSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("addDocumentSection")
		//console.log("addDocumentSection function");
		socket.on('addDocumentSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("addDocumentSection normal");
		socket.emit('addDocumentSection',payload);
	}
}
function newDocSection(payload, off){
	if(off){
		socket.off('newDocSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("newDocSection")
		//console.log("newDocSection function");
		socket.on('newDocSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("newDocSection normal");
		socket.emit('newDocSection',payload);
	}
}
function addDocSection(payload, off){
	if(off){
		socket.off('addDocSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("addDocSection")
		//console.log("addDocSection function");
		socket.on('addDocSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("addDocSection normal");
		socket.emit('addDocSection',payload);
	}
}
function updateDocumentSection(payload, off){
	if(off){
		socket.off('updateDocumentSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("updateDocumentSection")
		//console.log("updateDocumentSection function");
		socket.on('updateDocumentSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("updateDocumentSection normal");
		socket.emit('updateDocumentSection',payload);
	}
}
function updateAdminDocSection(payload, off){
	if(off){
		socket.off('updateAdminDocSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("updateAdminDocSection")
		//console.log("updateAdminDocSection function");
		socket.on('updateAdminDocSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("updateAdminDocSection normal");
		socket.emit('updateAdminDocSection',payload);
	}
}
function updateAdminDocOrder(payload, off){
	if(off){
		socket.off('updateAdminDocOrder',payload);
	}else if(typeof payload === "function"){
		//console.trace("updateAdminDocOrder")
		//console.log("updateAdminDocOrder function");
		socket.on('updateAdminDocOrder', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("updateAdminDocOrder normal");
		socket.emit('updateAdminDocOrder',payload);
	}
}
function updateSectionSetting(payload, off){
	if(off){
		socket.off('updateSectionSetting',payload);
	}else if(typeof payload === "function"){
		//console.trace("updateSectionSetting")
		//console.log("updateSectionSetting function");
		socket.on('updateSectionSetting', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("updateSectionSetting normal");
		socket.emit('updateSectionSetting',payload);
	}
}
function getAdminDocsQuestions(payload, off){
	if(off){
		socket.off('getAdminDocsQuestions',payload);
	}else if(typeof payload === "function"){
		//console.trace("getAdminDocsQuestions")
		//console.log("getAdminDocsQuestions function");
		socket.on('getAdminDocsQuestions', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getAdminDocsQuestions normal");
		socket.emit('getAdminDocsQuestions',payload);
	}
}
function getBuilderSupervisors(payload, off){
	if(off){
		socket.off('getBuilderSupervisors',payload);
	}else if(typeof payload === "function"){
		//console.trace("getBuilderSupervisors")
		//console.log("getBuilderSupervisors function");
		socket.on('getBuilderSupervisors', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("getBuilderSupervisors normal");
		socket.emit('getBuilderSupervisors',payload);
	}
}
function deleteDocumentSection(payload, off){
	if(off){
		socket.off('deleteDocumentSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("deleteDocumentSection")
		//console.log("deleteDocumentSection function");
		socket.on('deleteDocumentSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("deleteDocumentSection normal");
		socket.emit('deleteDocumentSection',payload);
	}
}
function deleteAdminDocSection(payload, off){
	if(off){
		socket.off('deleteAdminDocSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("deleteAdminDocSection")
		//console.log("deleteAdminDocSection function");
		socket.on('deleteAdminDocSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("deleteAdminDocSection normal");
		socket.emit('deleteAdminDocSection',payload);
	}
}
function newProduct(payload, off){
	if(off){
		socket.off('newProduct',payload);
	}else if(typeof payload === "function"){
		//console.trace("newProduct")
		//console.log("newProduct function");
		socket.on('newProduct', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("newProduct normal");
		socket.emit('newProduct',payload);
	}
}
function claimProduct(payload, off){
	if(off){
		socket.off('claimProduct',payload);
	}else if(typeof payload === "function"){
		//console.trace("claimProduct")
		//console.log("claimProduct function");
		socket.on('claimProduct', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("claimProduct normal");
		socket.emit('claimProduct',payload);
	}
}
function randomUser(payload, off){
	if(off){
		socket.off('randomUser',payload);
	}else if(typeof payload === "function"){
		//console.trace("randomUser")
		//console.log("randomUser function");
		socket.on('randomUser', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("randomUser normal");
		socket.emit('randomUser',payload);
	}
}
function activateGiveaway(payload, off){
	if(off){
		socket.off('activateGiveaway',payload);
	}else if(typeof payload === "function"){
		//console.trace("activateGiveaway")
		//console.log("activateGiveaway function");
		socket.on('activateGiveaway', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("activateGiveaway normal");
		socket.emit('activateGiveaway',payload);
	}
}
function updateDocSection(payload, off){
	if(off){
		socket.off('updateDocSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("updateDocSection")
		//console.log("updateDocSection function");
		socket.on('updateDocSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("updateDocSection normal");
		socket.emit('updateDocSection',payload);
	}
}
function deleteDocSection(payload, off){
	if(off){
		socket.off('deleteDocSection',payload);
	}else if(typeof payload === "function"){
		//console.trace("deleteDocSection")
		//console.log("deleteDocSection function");
		socket.on('deleteDocSection', payload); //implicitly passes 'data' to callback
	} else {
		// console.log("deleteDocSection normal");
		socket.emit('deleteDocSection',payload);
	}
}
function unreadCount(payload){

	if(typeof payload === "function"){
		//console.trace("unreadCount")
		//console.log("unreadCount function");
		socket.off('unreadCount').on('unreadCount', payload); //implicitly passes 'data' to callback
	} else {
		console.log("unreadCount normal");
		socket.emit('unreadCount',payload);
	}
}

// this is the appropriate 'off' function
function notify(callback){
	socket.off('notify').on('notify', callback);

}



function getposts(payload){

	if(typeof payload === "function"){
		socket.off('getposts').on('getposts', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('getposts',payload);
	}
}

function getUserPosts(payload){

	if(typeof payload === "function"){
		socket.off('getUserPosts').on('getUserPosts', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('getUserPosts',payload);
	}
}






function createCommentLike(payload){
	if(typeof payload === "function"){
		socket.off('createCommentLike').on('createCommentLike', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('createCommentLike',payload);
	}
}


function censorComment(payload){
	if(typeof payload === "function"){
		socket.off('censorComment').on('censorComment', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('censorComment',payload);
	}
}
function censorPost(payload){
	if(typeof payload === "function"){
		socket.off('censorPost').on('censorPost', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('censorPost',payload);
	}
}

function updatePost(payload){
	if(typeof payload === "function"){
		socket.off('updatePost').on('updatePost', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('updatePost',payload);
	}
}

function getUsers(payload){
	if(typeof payload === "function"){
		socket.off('getUsers').on('getUsers', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('getUsers',payload);
	}
}
function notifications(payload){
	if(typeof payload === "function"){
		socket.off('notifications').on('notifications', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('notifications',payload);
	}
}

function updateNotification(payload){

	if(typeof payload === "function"){
		socket.off('updateNotification').on('updateNotification', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('updateNotification',payload);
	}
}
function markAsRead(payload){

	if(typeof payload === "function"){
		socket.off('markAsRead').on('markAsRead', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('markAsRead',payload);
	}
}

function markAllNotificationsRead(payload){

	if(typeof payload === "function"){
		socket.off('markAllNotificationsRead').on('markAllNotificationsRead', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('markAllNotificationsRead',payload);
	}
}

function updateNotificationCount(payload){

	if(typeof payload === "function"){
		socket.off('updateNotificationCount').on('updateNotificationCount', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('updateNotificationCount',payload);
	}
}

function updateComment(payload){

	if(typeof payload === "function"){
		socket.off('updateComment').on('updateComment', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('updateComment',payload);
	}
}
function test(payload){

	if(typeof payload === "function"){
		socket.off('test').on('test', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('test',payload);
	}
}
function singlePost(payload){

	if(typeof payload === "function"){
		socket.off('post').on('post', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('post',payload);
	}
}

function createNotification(payload){

	if(typeof payload === "function"){
		socket.off('createNotification').on('createNotification', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('createNotification',payload);
	}
}

function getUserSearchSocial(payload){

	if(typeof payload === "function"){
		socket.off('getUserSearchSocial').on('getUserSearchSocial', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('getUserSearchSocial',payload);
	}
}
function getPreview(payload){

	if(typeof payload === "function"){
		socket.on('getPreview', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('getPreview',payload);
	}
}
function blockedList(cb){
	if(typeof cb === "function"){
		socket.on('blockedList', data => cb(data));
	}
	else if(typeof cb == 'string'){
		socket.off('blockedList')
	}
	else {
		socket.emit('blockedList',cb);
	}
}
function blockUser(cb){
	if(typeof cb === "function"){
		socket.off('blockUser').on('blockUser', data => cb(data));
	}
	else if(typeof cb == 'string'){
		socket.off('blockUser');
	}
	else if (typeof cb == "object"){
		socket.emit('blockUser',cb);
	}else {
		socket.emit('blockUser');
	}
}
function unblockUser(cb){
	if(typeof cb === "function"){
		socket.on('unblockUser', data => cb(data));
	}
	else if(typeof cb == 'string'){
		socket.off('unblockUser');
	}
	else if (typeof cb == "object"){
		socket.emit('unblockUser',cb);
	}else {
		socket.emit('unblockUser');
	}
}

function getLastChattedContacts(payload){

	if(typeof payload === "function"){
		socket.off('getLastChattedContacts').on('getLastChattedContacts', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('getLastChattedContacts');
	}
}

function createCommentReply(payload){

	if(typeof payload === "function"){
		socket.off('createCommentReply').on('createCommentReply', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('createCommentReply', payload);
	}
}

function deleteCommentReply(payload){

	if(typeof payload === "function"){
		socket.off('deleteCommentReply').on('deleteCommentReply', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('deleteCommentReply', payload);
	}
}


function sendSms(cb){
	if(typeof cb === "function"){
		socket.off('sendSms').on('sendSms', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('sendSms',cb);
	}else {
		socket.emit('sendSms');
	}
}
function roomMessages(cb){
	if(typeof cb === "function"){
		socket.off('roomMessages').on('roomMessages', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('roomMessages',cb);
	}else {
		socket.emit('roomMessages');
	}
}
function updateUser(cb){
	if(typeof cb === "function"){
		socket.off('updateUser').on('updateUser', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateUser',cb);
	}else {
		socket.emit('updateUser');
	}
}
function updateUserPhone(cb){
	if(typeof cb === "function"){
		socket.off('updateUserPhone').on('updateUserPhone', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateUserPhone',cb);
	}else {
		socket.emit('updateUserPhone');
	}
}
function updateUserEmail(cb){
	if(typeof cb === "function"){
		socket.off('updateUserEmail').on('updateUserEmail', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateUserEmail',cb);
	}else {
		socket.emit('updateUserEmail');
	}
}
function updateUserData(cb){
	if(typeof cb === "function"){
		socket.off('updateUserData').on('updateUserData', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateUserData',cb);
	}else {
		socket.emit('updateUserData');
	}
}
function updateBuilder(cb){
	if(typeof cb === "function"){
		socket.off('updateBuilder').on('updateBuilder', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateBuilder',cb);
	}else {
		socket.emit('updateBuilder');
	}
}
function updateSupervisor(cb){
	if(typeof cb === "function"){
		socket.off('updateSupervisor').on('updateSupervisor', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateSupervisor',cb);
	}else {
		socket.emit('updateSupervisor');
	}
}
function updateEmployee(cb){
	if(typeof cb === "function"){
		socket.off('updateEmployee').on('updateEmployee', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateEmployee',cb);
	}else {
		socket.emit('updateEmployee');
	}
}
function updateSubcontractor(cb){
	if(typeof cb === "function"){
		socket.off('updateSubcontractor').on('updateSubcontractor', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateSubcontractor',cb);
	}else {
		socket.emit('updateSubcontractor');
	}
}
function userData(cb){
	if(typeof cb === "function"){
		socket.off('userData').on('userData', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('userData',cb);
	}else if(cb == 'off'){
		socket.off('userData');
	}else {
		socket.emit('userData');
	}
}
function getInvitee(cb){
	if(typeof cb === "function"){
		socket.off('getInvitee').on('getInvitee', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('getInvitee',cb);
	}else {
		socket.emit('getInvitee');
	}
}
function userJobData(cb){
	if(typeof cb === "function"){
		socket.off('userJobData').on('userJobData', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('userJobData',cb);
	}else if(cb == 'off'){
		socket.off('userJobData');
	}else {
		socket.emit('userJobData');
	}
}
function teamData(cb){
	if(typeof cb === "function"){
		socket.off('teamData').on('teamData', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('teamData',cb);
	}else if(cb == 'off'){
		socket.off('teamData');
	}else {
		socket.emit('teamData');
	}
}
function userSearch(cb){
	if(typeof cb === "function"){
		socket.off('userSearch').on('userSearch', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('userSearch',cb);
	}else if(cb == 'off'){
		socket.off('userSearch');
	}else {
		socket.emit('userSearch');
	}
}
function addUserToTeam(cb){
	if(typeof cb === "function"){
		socket.off('addUserToTeam').on('addUserToTeam', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('addUserToTeam',cb);
	}else if(cb == 'off'){
		socket.off('addUserToTeam');
	}else {
		socket.emit('addUserToTeam');
	}
}
function getTeamEmployees(cb){
	if(typeof cb === "function"){
		socket.off('getTeamEmployees').on('getTeamEmployees', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('getTeamEmployees',cb);
	}else if(cb == 'off'){
		socket.off('getTeamEmployees');
	}else {
		socket.emit('getTeamEmployees');
	}
}
function teamEmployee(cb){
	if(typeof cb === "function"){
		socket.off('teamEmployee').on('teamEmployee', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('teamEmployee',cb);
	}else if(cb == 'off'){
		socket.off('teamEmployee');
	}else {
		socket.emit('teamEmployee');
	}
}
function getEmployees(cb){
	if(typeof cb === "function"){
		socket.off('getEmployees').on('getEmployees', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('getEmployees',cb);
	}else if(cb == 'off'){
		socket.off('getEmployees');
	}else {
		socket.emit('getEmployees');
	}
}
function userProfileData(cb){
	if(typeof cb === "function"){
		socket.off('userProfileData').on('userProfileData', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('userProfileData',cb);
	}else if(cb == 'off'){
		socket.off('userProfileData');
	}else {
		socket.emit('userProfileData');
	}
}
function newBuilder(cb){
	if(typeof cb === "function"){
		socket.off('newBuilder').on('newBuilder', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newBuilder',cb);
	}else {
		socket.emit('newBuilder');
	}
}
function newEmployee(cb){
	if(typeof cb === "function"){
		socket.off('newEmployee').on('newEmployee', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newEmployee',cb);
	}else {
		socket.emit('newEmployee');
	}
}
function newSubcontractor(cb){
	if(typeof cb === "function"){
		socket.off('newSubcontractor').on('newSubcontractor', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newSubcontractor',cb);
	}else {
		socket.emit('newSubcontractor');
	}
}
function newInvite(cb){
	if(typeof cb === "function"){
		socket.off('newInvite').on('newInvite', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newInvite',cb);
	}else {
		socket.emit('newInvite');
	}
}
function newJobsiteInvites(cb){
	if(typeof cb === "function"){
		socket.off('newJobsiteInvites').on('newJobsiteInvites', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newJobsiteInvites',cb);
	}else {
		socket.emit('newJobsiteInvites');
	}
}
function jobsites(cb){
	if(typeof cb === "function"){
		socket.on('jobsites', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('jobsites',cb);
	}else if(cb == 'off'){
		socket.off('jobsites');
	}else {
		socket.emit('jobsites');
	}
}
function jobsiteStats(cb, off){
	if(off){
		socket.off('jobsiteStats', cb);
	}else if(typeof cb === "function"){
		socket.off('jobsiteStats').on('jobsiteStats', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('jobsiteStats',cb);
	}else {
		socket.emit('jobsiteStats');
	}
}
function getPosts(cb, off){
	if(off){
		socket.off('getPosts', cb);
	}else if(typeof cb === "function"){
		socket.off('getPosts').on('getPosts', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('getPosts',cb);
	}else {
		socket.emit('getPosts');
	}
}
function getPreBuilders(cb, off){
	if(off){
		socket.off('getPreBuilders', cb);
	}else if(typeof cb === "function"){
		socket.off('getPreBuilders').on('getPreBuilders', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('getPreBuilders',cb);
	}else {
		socket.emit('getPreBuilders');
	}
}
function createPost(cb, off){
	if(off){
		socket.off('createPost', cb);
	}else if(typeof cb === "function"){
		socket.off('createPost').on('createPost', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('createPost',cb);
	}else {
		socket.emit('createPost');
	}
}
function deleteComment(cb, off){
	if(off){
		socket.off('deleteComment', cb);
	}else if(typeof cb === "function"){
		socket.off('deleteComment').on('deleteComment', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('deleteComment',cb);
	}else {
		socket.emit('deleteComment');
	}
}
function deletePost(cb, off){
	if(off){
		socket.off('deletePost', cb);
	}else if(typeof cb === "function"){
		socket.off('deletePost').on('deletePost', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('deletePost',cb);
	}else {
		socket.emit('deletePost');
	}
}
function createComment(cb, off){
	if(off){
		socket.off('createComment', cb);
	}else if(typeof cb === "function"){
		socket.off('createComment').on('createComment', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('createComment',cb);
	}else {
		socket.emit('createComment');
	}
}
function likePost(cb, off){
	if(off){
		socket.off('likePost', cb);
	}else if(typeof cb === "function"){
		socket.off('likePost').on('likePost', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('likePost',cb);
	}else {
		socket.emit('likePost');
	}
}
function unlikePost(cb, off){
	if(off){
		socket.off('unlikePost', cb);
	}else if(typeof cb === "function"){
		socket.off('unlikePost').on('unlikePost', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('unlikePost',cb);
	}else {
		socket.emit('unlikePost');
	}
}
function getSettings(cb, off){
	if(off){
		socket.off('getSettings', cb);
	}else if(typeof cb === "function"){
		socket.off('getSettings').on('getSettings', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('getSettings',cb);
	}else {
		socket.emit('getSettings');
	}
}
function setSettings(cb, off){
	if(off){
		socket.off('setSettings', cb);
	}else if(typeof cb === "function"){
		socket.off('setSettings').on('setSettings', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('setSettings',cb);
	}else {
		socket.emit('setSettings');
	}
}
function newUserDocs(cb, off){
	if(off){
		socket.off('newUserDocs');
	}else if(typeof cb === "function"){
		socket.off('newUserDocs').on('newUserDocs', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('newUserDocs',cb);
	}else {
		socket.emit('newUserDocs');
	}
}
function deleteProfession(cb, off){
	if(off){
		socket.off('deleteProfession');
	}else if(typeof cb === "function"){
		socket.off('deleteProfession').on('deleteProfession', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('deleteProfession',cb);
	}else {
		socket.emit('deleteProfession');
	}
}
function updateProfessionPage(cb, off){
	if(off){
		socket.off('updateProfessionPage');
	}else if(typeof cb === "function"){
		socket.off('updateProfessionPage').on('updateProfessionPage', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('updateProfessionPage',cb);
	}else {
		socket.emit('updateProfessionPage');
	}
}
function deleteProfessionPage(cb, off){
	if(off){
		socket.off('deleteProfessionPage');
	}else if(typeof cb === "function"){
		socket.off('deleteProfessionPage').on('deleteProfessionPage', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('deleteProfessionPage',cb);
	}else {
		socket.emit('deleteProfessionPage');
	}
}
function activeSubscriptions(cb, off){
	if(off){
		socket.off('activeSubscriptions');
	}else if(typeof cb === "function"){
		socket.off('activeSubscriptions').on('activeSubscriptions', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('activeSubscriptions',cb);
	}else {
		socket.emit('activeSubscriptions');
	}
}
function totalUsers(cb, off){
	if(off){
		socket.off('totalUsers');
	}else if(typeof cb === "function"){
		socket.off('totalUsers').on('totalUsers', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('totalUsers',cb);
	}else {
		socket.emit('totalUsers');
	}
}
function newProfessionPage(cb, off){
	if(off){
		socket.off('newProfessionPage');
	}else if(typeof cb === "function"){
		socket.off('newProfessionPage').on('newProfessionPage', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('newProfessionPage',cb);
	}else {
		socket.emit('newProfessionPage');
	}
}
function newUserDoc(cb, off){
	if(off){
		socket.off('newUserDoc');
	}else if(typeof cb === "function"){
		socket.off('newUserDoc').on('newUserDoc', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('newUserDoc',cb);
	}else {
		socket.emit('newUserDoc');
	}
}
function deleteUserDocs(cb, off){
	if(off){
		socket.off('deleteUserDocs');
	}else if(typeof cb === "function"){
		socket.off('deleteUserDocs').on('deleteUserDocs', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('deleteUserDocs',cb);
	}else {
		socket.emit('deleteUserDocs');
	}
}
function createDefaultUserDocs(cb, off){
	if(off){
		socket.off('createDefaultUserDocs');
	}else if(typeof cb === "function"){
		socket.off('createDefaultUserDocs').on('createDefaultUserDocs', cb); //implicitly passes 'data' to callback
	}else if(typeof cb == 'object'){
		socket.emit('createDefaultUserDocs',cb);
	}else {
		socket.emit('createDefaultUserDocs');
	}
}
function newNoticeboardActivity(cb, off){
	if(off){
		socket.off('newNoticeboardActivity', cb);
	}else if(typeof cb === "function"){
		socket.off('newNoticeboardActivity').on('newNoticeboardActivity', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newNoticeboardActivity',cb);
	}else {
		socket.emit('newNoticeboardActivity');
	}
}
function userActivities(cb, off){
	if(off){
		socket.off('userActivities', cb);
	}else if(typeof cb === "function"){
		socket.off('userActivities').on('userActivities', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('userActivities',cb);
	}else {
		socket.emit('userActivities');
	}
}
function passwordRecoveryEmail(cb, off){
	if(off){
		socket.off('passwordRecoveryEmail', cb);
	}else if(typeof cb === "function"){
		socket.off('passwordRecoveryEmail').on('passwordRecoveryEmail', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('passwordRecoveryEmail',cb);
	}else {
		socket.emit('passwordRecoveryEmail');
	}
}
function sendEmail(cb, off){
	if(off){
		socket.off('sendEmail', cb);
	}else if(typeof cb === "function"){
		socket.off('sendEmail').on('sendEmail', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('sendEmail',cb);
	}else {
		socket.emit('sendEmail');
	}
}
function verifyRecoveryToken(cb, off){
	if(off){
		socket.off('verifyRecoveryToken', cb);
	}else if(typeof cb === "function"){
		socket.off('verifyRecoveryToken').on('verifyRecoveryToken', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('verifyRecoveryToken',cb);
	}else {
		socket.emit('verifyRecoveryToken');
	}
}
function newProfession(cb, off){
	if(off){
		socket.off('newProfession', cb);
	}else if(typeof cb === "function"){
		socket.off('newProfession').on('newProfession', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newProfession',cb);
	}else {
		socket.emit('newProfession');
	}
}
function updatePassword(cb, off){
	if(off){
		socket.off('updatePassword', cb);
	}else if(typeof cb === "function"){
		socket.off('updatePassword').on('updatePassword', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updatePassword',cb);
	}else {
		socket.emit('updatePassword');
	}
}
function stripeCustomer(cb, off){
	// console.log('calling stripe customer with cb: ',cb);
	if(off){
		socket.off('stripeCustomer', cb);
	}else if(typeof cb === "function"){
		socket.off('stripeCustomer').on('stripeCustomer', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('stripeCustomer',cb);
	}else {
		socket.emit('stripeCustomer');
	}
}
function products(cb, off){
	// console.log('calling stripe customer with cb: ',cb);
	if(off){
		socket.off('products', cb);
	}else if(typeof cb === "function"){
		socket.off('products').on('products', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('products',cb);
	}else {
		socket.emit('products');
	}
}
function jobsiteDetail(cb, off){
	if(off){
		socket.off('jobsiteDetail', cb);
	}else if(typeof cb === "function"){
		socket.off('jobsiteDetail').on('jobsiteDetail', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('jobsiteDetail',cb);
	}else {
		socket.emit('jobsiteDetail');
	}
}
function newUserNote(cb, off){
	if(off){
		socket.off('newUserNote', cb);
	}else if(typeof cb === "function"){
		socket.off('newUserNote').on('newUserNote', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newUserNote',cb);
	}else {
		socket.emit('newUserNote');
	}
}

function declineCard(cb, off){
	if(off){
		socket.off('declineCard', cb);
	}else if(typeof cb === "function"){
		socket.off('declineCard').on('declineCard', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('declineCard',cb);
	}else {
		socket.emit('declineCard');
	}
}
function getSafetyDoc(cb, off){
	if(off){
		socket.off('getSafetyDoc', cb);
	}else if(typeof cb === "function"){
		socket.off('getSafetyDoc').on('getSafetyDoc', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('getSafetyDoc',cb);
	}else {
		socket.emit('getSafetyDoc');
	}
}
function completeRequest(cb, off){
	if(off){
		socket.off('completeRequest', cb);
	}else if(typeof cb === "function"){
		socket.off('completeRequest').on('completeRequest', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('completeRequest',cb);
	}else {
		socket.emit('completeRequest');
	}
}
function userNotes(cb, off){
	if(off){
		socket.off('userNotes', cb);
	}else if(typeof cb === "function"){
		socket.off('userNotes').on('userNotes', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('userNotes',cb);
	}else {
		socket.emit('userNotes');
	}
}
function offDeleteMember(){
	socket.off('deleteMember');
}
function deleteMember(cb, off){
	if(off){
		socket.off('deleteMember', cb);
	}else if(typeof cb === "function"){
		socket.on('deleteMember', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('deleteMember',cb);
	}else {
		socket.emit('deleteMember');
	}
}
function myEmployees(cb, off){
	if(off){
		socket.off('myEmployees', cb);
	}else if(typeof cb === "function"){
		socket.off('myEmployees').on('myEmployees', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('myEmployees',cb);
	}else {
		socket.emit('myEmployees');
	}
}
function message(cb, off){
	if(off){
		socket.off('message', cb);
	}else if(typeof cb === "function"){
		socket.off('message').on('message', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('message',cb);
	}else {
		socket.emit('message');
	}
}
function jobsiteDocs(cb, off){
	if(off){
		socket.off('jobsiteDocs', cb);
	}else if(typeof cb === "function"){
		socket.off('jobsiteDocs').on('jobsiteDocs', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('jobsiteDocs',cb);
	}else {
		socket.emit('jobsiteDocs');
	}
}
function updateSupervisorDocAccept(cb, off){
	if(off){
		socket.off('updateSupervisorDocAccept', cb);
	}else if(typeof cb === "function"){
		socket.off('updateSupervisorDocAccept').on('updateSupervisorDocAccept', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateSupervisorDocAccept',cb);
	}else {
		socket.emit('updateSupervisorDocAccept');
	}
}
function acceptJobsiteDocs(cb, off){
	if(off){
		socket.off('acceptJobsiteDocs', cb);
	}else if(typeof cb === "function"){
		socket.off('acceptJobsiteDocs').on('acceptJobsiteDocs', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('acceptJobsiteDocs',cb);
	}else {
		socket.emit('acceptJobsiteDocs');
	}
}
function acceptEmployerSwms(cb, off){
	if(off){
		socket.off('acceptEmployerSwms', cb);
	}else if(typeof cb === "function"){
		socket.off('acceptEmployerSwms').on('acceptEmployerSwms', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('acceptEmployerSwms',cb);
	}else {
		socket.emit('acceptEmployerSwms');
	}
}
function getJobsiteUserProfessions(cb, off){
	if(off){
		socket.off('getJobsiteUserProfessions', cb);
	}else if(typeof cb === "function"){
		socket.off('getJobsiteUserProfessions').on('getJobsiteUserProfessions', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('getJobsiteUserProfessions',cb);
	}else {
		socket.emit('getJobsiteUserProfessions');
	}
}
function updateJobsite(cb, off){
	if(off){
		socket.off('updateJobsite', cb);
	}else if(typeof cb === "function"){
		socket.off('updateJobsite').on('updateJobsite', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateJobsite',cb);
	}else {
		socket.emit('updateJobsite');
	}
}
function deleteJobsite(cb, off){
	if(off){
		socket.off('deleteJobsite', cb);
	}else if(typeof cb === "function"){
		socket.off('deleteJobsite').on('deleteJobsite', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('deleteJobsite',cb);
	}else {
		socket.emit('deleteJobsite');
	}
}
function changePassword(cb, off){
	if(off){
		socket.off('changePassword', cb);
	}else if(typeof cb === "function"){
		socket.off('changePassword').on('changePassword', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('changePassword',cb);
	}else {
		socket.emit('changePassword');
	}
}
function sendMyProfile(cb, off){
	if(off){
		socket.off('sendMyProfile', cb);
	}else if(typeof cb === "function"){
		socket.off('sendMyProfile').on('sendMyProfile', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('sendMyProfile',cb);
	}else {
		socket.emit('sendMyProfile');
	}
}
function sendBuilderDocs(cb, off){
	if(off){
		socket.off('sendBuilderDocs');
	}else if(typeof cb === "function"){
		socket.off('sendBuilderDocs').on('sendBuilderDocs', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('sendBuilderDocs',cb);
	}else {
		socket.emit('sendBuilderDocs');
	}
}
function acceptPtc(cb, off){
	if(off){
		socket.off('acceptPtc');
	}else if(typeof cb === "function"){
		socket.off('acceptPtc').on('acceptPtc', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('acceptPtc',cb);
	}else {
		socket.emit('acceptPtc');
	}
}
function sendJobsiteDocs(cb, off){
	if(off){
		socket.off('sendJobsiteDocs', cb);
	}else if(typeof cb === "function"){
		socket.off('sendJobsiteDocs').on('sendJobsiteDocs', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('sendJobsiteDocs',cb);
	}else {
		socket.emit('sendJobsiteDocs');
	}
}
function resendInvite(cb, off){
	if(off){
		socket.off('resendInvite', cb);
	}else if(typeof cb === "function"){
		socket.off('resendInvite').on('resendInvite', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('resendInvite',cb);
	}else {
		socket.emit('resendInvite');
	}
}
function deleteInvite(cb, off){
	if(off){
		socket.off('deleteInvite', cb);
	}else if(typeof cb === "function"){
		socket.off('deleteInvite').on('deleteInvite', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('deleteInvite',cb);
	}else {
		socket.emit('deleteInvite');
	}
}
function getUserDocsData(cb, off){
	if(off){
		socket.off('getUserDocsData', cb);
	}else if(typeof cb === "function"){
		socket.off('getUserDocsData').on('getUserDocsData', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('getUserDocsData',cb);
	}else {
		socket.emit('getUserDocsData');
	}
}
function getUserDocs(cb, off){
	if(off){
		socket.off('getUserDocs', cb);
	}else if(typeof cb === "function"){
		socket.off('getUserDocs').on('getUserDocs', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('getUserDocs',cb);
	}else {
		socket.emit('getUserDocs');
	}
}
function userProfessions(cb, off){
	if(off){
		socket.off('userProfessions', cb);
	}else if(typeof cb === "function"){
		socket.off('userProfessions').on('userProfessions', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('userProfessions',cb);
	}else {
		socket.emit('userProfessions');
	}
}
function jobsiteActivity(cb, off){
	if(off){
		socket.off('jobsiteActivity', cb);
	}else if(typeof cb === "function"){
		socket.off('jobsiteActivity').on('jobsiteActivity', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('jobsiteActivity',cb);
	}else {
		socket.emit('jobsiteActivity');
	}
}
function startMembership(cb, off){
	if(off){
		socket.off('startMembership', cb);
	}else if(typeof cb === "function"){
		socket.off('startMembership').on('startMembership', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('startMembership',cb);
	}else {
		socket.emit('startMembership');
	}
}
function newCustomSwms(cb, off){
	if(off){
		socket.off('newCustomSwms', cb);
	}else if(typeof cb === "function"){
		socket.off('newCustomSwms').on('newCustomSwms', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newCustomSwms',cb);
	}else {
		socket.emit('newCustomSwms');
	}
}
function createRoom(cb, off){
	if(off){
		socket.off('createRoom', cb);
	}else if(typeof cb === "function"){
		socket.off('createRoom').on('createRoom', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('createRoom',cb);
	}else {
		socket.emit('createRoom');
	}
}
function checklist(cb, off){
	if(off){
		socket.off('checklist', cb);
	}else if(typeof cb === "function"){
		socket.off('checklist').on('checklist', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('checklist',cb);
	}else {
		socket.emit('checklist');
	}
}
function stripeCard(cb, off){
	if(off){
		socket.off('stripeCard', cb);
	}else if(typeof cb === "function"){
		socket.off('stripeCard').on('stripeCard', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('stripeCard',cb);
	}else {
		socket.emit('stripeCard');
	}
}
function getJobdata(cb, off){
	if(off){
		socket.off('getJobdata', cb);
	}else if(typeof cb === "function"){
		socket.off('getJobdata').on('getJobdata', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('getJobdata',cb);
	}else {
		socket.emit('getJobdata');
	}
}
function newUserInvite(cb){
	if(typeof cb === "function"){
		socket.off('newUserInvite').on('newUserInvite', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newUserInvite',cb);
	}else {
		socket.emit('newUserInvite');
	}
}
function newJobsite(cb){
	if(typeof cb === "function"){
		socket.off('newJobsite').on('newJobsite', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newJobsite',cb);
	}else {
		socket.emit('newJobsite');
	}
}
function newProfessions(cb){
	if(typeof cb === "function"){
		socket.off('newProfessions').on('newProfessions', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newProfessions',cb);
	}else {
		socket.emit('newProfessions');
	}
}

function professionList(cb){
	if(typeof cb === "function"){
		socket.off('professionList').on('professionList', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('professionList',cb);
	}else {
		socket.emit('professionList');
	}
}
function newSubcontractorHazards(cb){
	if(typeof cb === "function"){
		socket.off('newSubcontractorHazards').on('newSubcontractorHazards', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newSubcontractorHazards',cb);
	}else {
		socket.emit('newSubcontractorHazards');
	}
}
function checkAbn(cb){
	if(typeof cb === "function"){
		socket.off('checkAbn').on('checkAbn', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('checkAbn',cb);
	}else {
		socket.emit('checkAbn');
	}
}
function updateProfessions(cb, off){
	if(off){
		socket.off('updateProfessions',cb);
	}else{
		if(typeof cb === "function"){
			socket.off('updateProfessions').on('updateProfessions', data => cb(data));
		}else if(typeof cb == 'object'){
			socket.emit('updateProfessions',cb);
		}else {
			socket.emit('updateProfessions');
		}
	}

}
function newBlueCard(cb){
	if(typeof cb === "function"){
		socket.off('newBlueCard').on('newBlueCard', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newBlueCard',cb);
	}else {
		socket.emit('newBlueCard');
	}
}
function newInsurance(cb){
	if(typeof cb === "function"){
		socket.off('newInsurance').on('newInsurance', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newInsurance',cb);
	}else {
		socket.emit('newInsurance');
	}
}
function newWorkCover(cb){
	if(typeof cb === "function"){
		socket.off('newWorkCover').on('newWorkCover', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newWorkCover',cb);
	}else {
		socket.emit('newWorkCover');
	}
}
function newTesttag(cb, off){
	if(off){
		socket.off('newTesttag');
	}else if(typeof cb === "function"){
		socket.off('newTesttag').on('newTesttag', cb);
	}else if(typeof cb == 'object'){
		socket.emit('newTesttag',cb);
	}else {
		socket.emit('newTesttag');
	}
}
function jobsiteSwms(cb, off){
	if(off){
		socket.off('jobsiteSwms');
	}else if(typeof cb === "function"){
		socket.off('jobsiteSwms').on('jobsiteSwms', cb);
	}else if(typeof cb == 'object'){
		socket.emit('jobsiteSwms',cb);
	}else {
		socket.emit('jobsiteSwms');
	}
}
function newJobsiteSwms(cb, off){
	if(off){
		socket.off('newJobsiteSwms');
	}else if(typeof cb === "function"){
		socket.off('newJobsiteSwms').on('newJobsiteSwms', cb);
	}else if(typeof cb == 'object'){
		socket.emit('newJobsiteSwms',cb);
	}else {
		socket.emit('newJobsiteSwms');
	}
}
function updateSwmsPrice(cb, off){
	if(off){
		socket.off('updateSwmsPrice');
	}else if(typeof cb === "function"){
		socket.off('updateSwmsPrice').on('updateSwmsPrice', cb);
	}else if(typeof cb == 'object'){
		socket.emit('updateSwmsPrice',cb);
	}else {
		socket.emit('updateSwmsPrice');
	}
}
function newTradeContract(cb){
	if(typeof cb === "function"){
		socket.off('newTradeContract').on('newTradeContract', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newTradeContract',cb);
	}else {
		socket.emit('newTradeContract');
	}
}
function newWhsPlan(cb){
	if(typeof cb === "function"){
		socket.off('newWhsPlan').on('newWhsPlan', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newWhsPlan',cb);
	}else {
		socket.emit('newWhsPlan');
	}
}
function newWirPlan(cb){
	if(typeof cb === "function"){
		socket.off('newWirPlan').on('newWirPlan', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newWirPlan',cb);
	}else {
		socket.emit('newWirPlan');
	}
}
function newEWPCard(cb){
	if(typeof cb === "function"){
		socket.off('newEWPCard').on('newEWPCard', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newEWPCard',cb);
	}else {
		socket.emit('newEWPCard');
	}
}
function newWorkingHeightsCard(cb){
	if(typeof cb === "function"){
		socket.off('newWorkingHeightsCard').on('newWorkingHeightsCard', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newWorkingHeightsCard',cb);
	}else {
		socket.emit('newWorkingHeightsCard');
	}
}
function newLicense(cb){
	if(typeof cb === "function"){
		socket.off('newLicense').on('newLicense', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newLicense',cb);
	}else {
		socket.emit('newLicense');
	}
}
function updateToken(cb){
	if(typeof cb === "function"){
		socket.off('updateToken').on('updateToken', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('updateToken',cb);
	}else {
		socket.emit('updateToken');
	}
}
function newUser(cb){
	if(typeof cb === "function"){
		socket.off('newUser').on('newUser', data => cb(data));
	}else if(typeof cb == 'object'){
		socket.emit('newUser',cb);
	}else {
		socket.emit('newUser');
	}
}

function typingMessage(payload){

	if(typeof payload === "function"){
		socket.off('typingMessage').on('typingMessage', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('typingMessage', payload);
	}
}
function confirmCode(payload){

	if(typeof payload === "function"){
		socket.off('confirmCode').on('confirmCode', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('confirmCode', payload);
	}
}
function confirmEmailCode(payload){

	if(typeof payload === "function"){
		socket.off('confirmEmailCode').on('confirmEmailCode', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('confirmEmailCode', payload);
	}
}
function uploadFile(payload){

	if(typeof payload === "function"){
		socket.off('uploadFile').on('uploadFile', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('uploadFile', payload);
	}
}
function startUpload(payload){

	if(typeof payload === "function"){
		socket.off('startUpload').on('startUpload', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('startUpload', payload);
	}
}

function uploadDone(payload){

	if(typeof payload === "function"){
		socket.off('uploadDone').on('uploadDone', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('uploadDone', payload);
	}
}
function uploadMoreData(payload){

	if(typeof payload === "function"){
		socket.off('uploadMoreData').on('uploadMoreData', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('uploadMoreData', payload);
	}
}
function builderJobsites(payload){

	if(typeof payload === "function"){
		socket.off('builderJobsites').on('builderJobsites', payload); //implicitly passes 'data' to callback
	} else {
		socket.emit('builderJobsites', payload);
	}
}


function fileUpload(file, callback){
	socket.off('fileUpload').on('fileUpload', res=>{
		callback(res);
	});
	socket.emit('fileUpload', {file});
}

export {
	reply,messagelistener,loginOn,loginOff,login,loginOk, getChats,getChatHistory,userLogin, sendMessage,settings,sendSms,newUser,
	getPosts, getposts,createPost,likePost,createComment, createCommentLike, deletePost,updatePost,deleteComment, censorComment,censorPost,
	getUsers, getUserPosts,singlePost,createCommentReply,deleteCommentReply,getPreBuilders,getUserDocs,activeSubscriptions,totalUsers,
	notify, notifications, updateNotification,createNotification,markAllNotificationsRead,updateNotificationCount, updateComment,
	getNews, getNewsOff,test,unreadCount,unlikePost,getSettings, setSettings,checklist,sendBuilderDocs,acceptPtc,newProfessionPage,deleteProfession,updateProfessionPage,
	getAllContacts, onlineUsers, getIDandLogin, getUserSearchSocial, getLastChattedContacts, typingMessage,newUserDocs,newUserDoc,deleteUserDocs,createDefaultUserDocs,
	blockUser, unblockUser, blockedList,userSearch,addUserToTeam,declineCard,getUserDocsData,fileUpload,joinTeam,acceptJoinTeam, deleteProfessionPage,
	getPreview,markAsRead,deleteMember, message,verifyDoc,getSafetyDoc, completeRequest,myEmployees,offDeleteMember,allBuilders,
	confirmCode,userData,updateUser,getInvitee, updateUserData,verifyRecoveryToken,updatePassword,changePassword,newProfession,
	userJobData, updateBuilder, updateSubcontractor, updateEmployee,updateSupervisor,sendMyProfile,sendJobsiteDocs,sendEmail,acceptEmployerSwms,
	newBuilder,newSubcontractor, newEmployee,userProfileData,userActivities,passwordRecoveryEmail,updateSupervisorDocAccept,acceptJobsiteDocs,getJobsiteUserProfessions,
	newInvite, newBlueCard, newLicense,newInsurance,newWorkCover,newTesttag, newProfessions, newSubcontractorHazards, updateProfessions,professionList, userProfessions,
	updateToken,newTradeContract,newEWPCard,newWorkingHeightsCard, newWhsPlan, newWirPlan,teamEmployee,updateJobsite,deleteJobsite,
	uploadDone, uploadMoreData, uploadFile, startUpload,checkAbn,newUserInvite,newJobsite,newSubstance,resendInvite, deleteInvite,
	teamData,getTeamEmployees, getEmployees,jobsiteDetail, jobsiteDocs, createRoom,addDocSection,getInvites, getBuilders,newUserNote,userNotes,
	newJobsiteInvites, jobsites, getDocument,addDocumentSection,updateDocumentSection,deleteDocumentSection,getDocumentSections,getUnverifiedDocs,
	stripeCard, stripeCustomer,recentConversations, rooms, roomMessages,jobsiteStats,getSafetyDocs,updateSafetyDoc, updateUserDocs,updateUserDoc,
	updateDocSection, deleteDocSection,builderJobsites,newNoticeboardActivity,unreadMessagesCount,jobsiteActivity,startMembership,newCustomSwms,
	getAdminDoc,newDocSection,deleteAdminDocSection, updateAdminDocSection,updateAdminDocOrder,updateSectionSetting,getAdminDocsQuestions,getBuilderSupervisors,
	newProduct,products,claimProduct, randomUser,activateGiveaway, updateUserPhone, updateUserEmail, confirmEmailCode,
	getTerms, jobsiteSwms, newJobsiteSwms,updateSwmsPrice,
	getJobdata,
	socket

};

