// material
import { Container, Grid, Stack, Button, Dialog, DialogTitle, DialogContent, Box, Card, TextField } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { userRoles } from '../../constants/defaultValues';
import useSettings from '../../hooks/useSettings';
import DraftEditor from '../../components/editor/draft';
import SunEditor from '../../components/editor/suneditor';
import SaveIcon from '@mui/icons-material/Save';
import Paper from '@mui/material/Paper';
import { ptc } from '../../constants/contractTemplates/ptc';
import draftToHtml from 'draftjs-to-html';
import draftToMarkdown from 'draftjs-to-markdown';
import Logo from '../../components/LogoDoc';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { alpha, styled } from '@material-ui/core/styles';
import './TableStyle.css'
import Modal from '@mui/material/Modal';
import { useSnackbar } from 'notistack5';
import { MIconButton } from '../../components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

// components
import Page from '../../components/Page';
import {  getDocument, login, loginOk, loginOff, updateDocumentSection, deleteDocumentSection, getDocumentSections, addDocSection, updateDocSection, deleteDocSection, updateSwmsPrice } from '../../socket';
import { EditorState, convertToRaw } from 'draft-js';
import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------


const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '40px', 
    position: 'relative', 
    marginBottom: '15px', 
    borderRadius: '0px',
    maxWidth: '794px',
    minHeight: '1123px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.neutral
  }));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    zIndex: 999
  };
  
const paperStyle = {
    
    // background: theme.palette.primary.main,
                    
 };

 const ContentStyle = styled('div')(({ theme }) => ({
    'table': {
        //border: '1px solid gray',
       
    },
    'td':{
        padding:'2px 20px',
    },
    'tr':{
        //border: '1px solid gray',
    },
    'ul':{
        marginLeft: '30px',
    }
  }));
const footer = {
    fontSize: '10px', 
    fontWeight: 'bold',
    textAlign:'center',
    bottom: '10px',
    position: 'absolute',
    width: '100%',
};

const documentsStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '650px',
}
const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2, 2),
    marginBottom: 7,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  }));
  var currentDocument = null;
export default function Swms() {
  const { themeStretch } = useSettings();
//   var [content, setContent] = useState(ptc)
  var [content, setContent] = useState('')
  var [documents, setDocuments] = useState([])
  let [editorModalOpen, toggleEditorModal] = useState(false);
  let [docModalOpen, toggleDocModal] = useState(false);
  let [swmsPriceModal, toggleSwmsPriceModal] = useState(false);
  var [currentSection, setCurrentSection] = useState(null);
  var [currentDoc, setCurrentDoc] = useState(null);
  let [docType, setDocType] = useState('professionList');
  let [loading, setLoading] = useState(false);
  let [currentSwmsAmount, setCurrentSwmsAmount] = useState(null);
//   let [showEditor, setShowEditor] = useState(false);
  let [hovered, setHovered] = useState({});
  const { user } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const toast = (type, message)=>{
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }
  

  useEffect(() => {
    if(user && user.id && user.roleId!=userRoles.admin){
      navigate('/');
    }
    login(processLogin)
    processLogin(loginOk());
    getDocument(processDocument);
    updateSwmsPrice(processUpdateSwmsPrice);
    addDocSection(processDocumentSection);
    getDocumentSections(processDocumentSections);
    updateDocSection(processUpdateDocumentSection);
    deleteDocSection(processDeleteDocSection);
    return ()=>{
        updateSwmsPrice(processUpdateSwmsPrice, 'off');
        getDocument(processDocument, 'off');
        loginOff(processLogin);
    }
  }, []);

  const processLogin = (login)=>{
    console.log("login",login)
    if(login.auth){
        getDocument({type: docType})
    }
}
//   useEffect(() => {
//     // console.log('gettin document for: ',type);
//     setLoading(false);
//     setDocuments([]);
//     toggleEditorModal(false);
//     setDocType(type);

//     getDocument({type});
//   }, [type]);


  const processUpdateSwmsPrice = res=>{
    setLoading(false);
    if(res.success){
        toast('success', 'Updated successfully');
        toggleSwmsPriceModal(false);
        toggleDocModal(false);
        setCurrentDoc(null);
    }
  }

  const processDocumentSection = res=>{
      console.log('addDocumentSection res: ',res);
      if(res.success) {
          setLoading(false);
          toggleEditorModal(false);
          setContent('');
      }
  }

  const processDocumentSections = data=>{
      console.log('got sections: ',data);
      console.log('current document: ',currentDocument);

      if(currentDocument && currentDocument.id==data.documentId){
        currentDocument = {...currentDocument, sections: data.sections}
        setCurrentDoc({...currentDocument});
      }
  }
  const processUpdateDocumentSection = res=>{
      console.log('updateDocumentSection res: ',res);
      if(res.success) {
          setLoading(false);
          toggleEditorModal(false);
          setContent('');
          setCurrentSection(null);
      }
  }

  const processDeleteDocSection = res=>{

  }

  const processDocument = data=>{
    //   console.log('got doc data: ',data);
      setDocuments(data);
      updatePageBorders();
  }

  const handleContentChange = con=>{
      console.log('content: ',con);
      setContent(con);
  }

  const updatePageBorders = ()=>{
      setTimeout(()=>{
        var pages = window.document.querySelectorAll('.editorPage');
        if(pages && pages.length>0){
            for(let i=0; i<pages.length; i++){
                if(pages[i]){
                    if(pages[i].childNodes && pages[i].childNodes[1]){
                        pages[i].childNodes[1].style.border = '0px';
                    }
                }
            }
        }
      },80)
      
  }

  const handleSaveDocumentSection = ()=>{
      // save current page to db and clear the editor it will show the user that its a new page
      if(content && content.length>0 && currentDocument && currentDocument.id){
        if(currentSection && currentSection.id){
            // here call the update section api
            updateDocSection({
                type: 'documentSections',
                content: JSON.stringify(content),
                id: currentSection.id,
                documentId: currentDocument.id,
            });
        }else{
              console.log('content: ',content);
            addDocSection({
                documentId: currentDocument.id,
                content: JSON.stringify(content)
            });
        }
        setLoading(true);
      }
      
      
    
  }
  const handleNewPage = ()=>{
      setCurrentSection(null);
      toggleEditorModal(true);
  }
  const handleEdit = docSection=>{
    // console.log('so you want to edit: ',docSection);
    setCurrentSection({...docSection});
    setContent(JSON.parse(docSection.content));
    toggleEditorModal(true);
  }

  const handleDelete = docSection=>{
    console.log('page to delete: ',docSection);
    if(currentDocument && currentDocument)
        deleteDocSection({
            type: 'documentSections',
            id: docSection.id,
            documentId: currentDocument.id
        });
  }

  const toggleEditButton = (index, show)=>{
    let hov = {...hovered};
    if(show){
        hov[index] = true;
    }else{
        delete hov[index];
    }
    setHovered({...hov});
  }

  const handleDocClick = doc=>{
    setCurrentDoc(doc);
    currentDocument = doc;
    getDocumentSections({documentId: doc.id})
    toggleDocModal(true);
  }

  const handleUpdateSwmsAmount = ()=>{
    let amount = currentSwmsAmount;
    if(amount && amount>0){
        updateSwmsPrice({
            id: currentDoc.id,
            amount
        });
        setLoading(true);
    }else{
        toast('warning', 'Please enter a valid amount!');
    }
  }

 
//   console.log('hovered: ',hovered);

//   console.log('contetn: ',draftToHtml(ptc));
    // updatePageBorders();
    // console.log('current doc: ',currentDoc);
  return (
    <Page title="WeSafe - Dashboard Admin">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <div className="documents" style={documentsStyle}> 
            <h2>Professions</h2>
            <br/>
            {
                documents.map(doc=>{

                    return (
                        <StyledCard >
                            <p onClick={()=>handleDocClick(doc)}>{doc.title}</p>
                            <Box sx={{display: 'flex'}}>
                                <p style={{marginRight: 10}}>
                                    Price: <span>${doc?.amount}</span>
                                </p>
                                <Button size="small" onClick={()=>{ setCurrentDoc(doc); setCurrentSwmsAmount(doc.amount); toggleSwmsPriceModal(true)}} variant="outlined">Update Price</Button>
                            </Box>
                        </StyledCard>
                    )
                })
                
            }
           
            {/* {
                documents.length>0?(
                    documents.map((doc, index)=>{
                        let con = doc.content? JSON.parse(doc.content): '';
                        return (
                            <div>
                                <Paper 
                                    onMouseEnter={()=>toggleEditButton(index, true)} 
                                    onMouseLeave={()=>toggleEditButton(index, false)} 
                                    elevation={3} style={paperStyle}
                                    className="editorPage"
                                    
                                >
                                       <Logo /> 
                                    
                                    <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: con }} />
                                                                
                                    {
                                        hovered[index]? (
                                            <div className="editDocButton" style={{position:'absolute'}}>
                                                <IconButton onClick={()=>handleEdit(doc)} size="small" variant="contained" >
                                                    <EditIcon />
                                                </IconButton>
                                                
                                                <IconButton onClick={()=>handleDelete(doc)} aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton>

                                            </div>
                                            
                                        ):null
                                    }
                                    <div style={footer}>Copyright © 2022 WeSafe Pty Ltd document generated at: WeSafe.com.au</div>
                                </Paper>
                            </div>
                        )
                    })
                ):null
            } */}
        </div>
        <br/>
        <Dialog fullWidth={true} maxWidth={'lg'}  open={editorModalOpen} onClose={()=> toggleEditorModal(false)} >
            <DialogTitle>{currentSection? "Update Section":"New Section"}</DialogTitle>
            <DialogContent>
                {
                    editorModalOpen?(
                        <div className="editor">
                            {/* <DraftEditor 
                                initialContentState={content}
                                onContentStateChange={handleContentChange} 
                            /> */}
                            <SunEditor setContents={content} onChange={(content)=>setContent(content)} />
                            <br/>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                onClick={handleSaveDocumentSection}
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                >
                                    {
                                        currentSection?"Update":"Save"
                                    }
                            </LoadingButton>
                        </div>
                    ):null
                }       
            </DialogContent>
            
            {/* <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Send Invite</Button>
            </DialogActions> */}
        </Dialog> 
        <Dialog fullWidth={true}  maxWidth={'lg'}  open={docModalOpen} onClose={()=> toggleDocModal(false)} >
            <DialogTitle sx={{mb: 2}}>{currentDoc? currentDoc.title: ''}</DialogTitle>
            <DialogContent>
                
                {
                    currentDoc?(
                        <div className="pages" style={{minHeight: '100vh'}} >
                            
                            {
                                currentDoc.sections && currentDoc.sections.map((section, index)=>{
                                    let con = section.content? JSON.parse(section.content): '';
                                    return (
                                        <div>
                                            <StyledPaper 
                                                onMouseEnter={()=>toggleEditButton(index, true)} 
                                                onMouseLeave={()=>toggleEditButton(index, false)} 
                                                elevation={3} 
                                                className="editorPage"
                                                
                                            >
                                                   <Logo /> 

                                                   <ContentStyle>
                                                        <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: con }} />
                                                    </ContentStyle>
                                                
                                                {/* <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: con }} /> */}
                                                                            
                                                {
                                                    hovered[index]? (
                                                        <div className="editDocButton" style={{position:'absolute'}}>
                                                            <IconButton onClick={()=>handleEdit(section)} size="small" variant="contained" >
                                                                <EditIcon />
                                                            </IconButton>
                                                            
                                                            <IconButton onClick={()=>handleDelete(section)} aria-label="delete">
                                                                <DeleteIcon />
                                                            </IconButton>
            
                                                        </div>
                                                        
                                                    ):null
                                                }
                                                <div style={footer}>Copyright © 2022 WeSafe Pty Ltd document generated at: WeSafe.com.au</div>
                                            </StyledPaper>
                                        </div>
                                    )
                                })
                            }

                            <Button onClick={handleNewPage}>New Section</Button> 
                            
                        </div>
                    ):null
                }       
            </DialogContent>
            
            {/* <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Send Invite</Button>
            </DialogActions> */}
        </Dialog> 

        <Modal
            open={swmsPriceModal}
            onClose={e=>toggleSwmsPriceModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
            <h4>{currentDoc && currentDoc?.title}</h4>
            <h6>update the price for the swms </h6>

            <TextField sx={{my: 2}} fullWidth value={currentSwmsAmount} onChange={e=>setCurrentSwmsAmount(e.target.value)} label="Price" />
            
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <LoadingButton sx={{my:2, mr:2}} variant="outlined" onClick={()=> toggleSwmsPriceModal(false)} type="button" color="primary" >
                    Cancel
                </LoadingButton>
                <LoadingButton sx={{my:2}} variant="contained" onClick={handleUpdateSwmsAmount} type="button" color="primary" loading={loading} >
                    Update
                </LoadingButton>
            </Box>
            
            
            </Box>
        </Modal>

        {/* <Button onClick={handleNewPage}>new page</Button> */}

      </Container>
    </Page>
  );
}
