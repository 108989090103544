
export const userAvatar = (url)=>{
    if(url && url.includes('s3')){
        let imageName = url.slice(url.lastIndexOf('/')+1, url.length);
        let imageUrl = 'https://d2zxqy2dinp2ov.cloudfront.net/fit-in/40x40/images/'+imageName;
        return imageUrl;
    }else{
        // set default image
        return '/static/mock-images/avatars/avatar_1.jpg';
    }
}
export const userAvatar100 = (url)=>{
    if(url && url.includes('s3')){
        let imageName = url.slice(url.lastIndexOf('/')+1, url.length);
        let imageUrl = 'https://d2zxqy2dinp2ov.cloudfront.net/fit-in/100x100/images/'+imageName;
        return imageUrl;
    }else{
        // set default image
        return '/static/mock-images/avatars/avatar_1.jpg';
    }
}

export const imgae200 = (url)=>{
    if(url && url.includes('s3')){
        let imageName = url.slice(url.lastIndexOf('/')+1, url.length);
        let imageUrl = 'https://d2zxqy2dinp2ov.cloudfront.net/fit-in/200x200/images/'+imageName;
        return imageUrl;
    }else{
        // set default image
        return null;
    }
}
export const doc300 = (url)=>{
    if(url && url.includes('s3')){
        let imageName = url.slice(url.lastIndexOf('/')+1, url.length);
        let imageUrl = 'https://d2zxqy2dinp2ov.cloudfront.net/fit-in/300x300/documents/'+imageName;
        return imageUrl;
    }else{
        // set default image
        return null;
    }
}
export const doc400 = (url)=>{
    if(url && url.includes('s3')){
        let imageName = url.slice(url.lastIndexOf('/')+1, url.length);
        let imageUrl = 'https://d2zxqy2dinp2ov.cloudfront.net/fit-in/400x400/documents/'+imageName;
        return imageUrl;
    }else{
        // set default image
        return null;
    }
}
export const doc600 = (url)=>{
    if(url && url.includes('s3')){
        let imageName = url.slice(url.lastIndexOf('/')+1, url.length);
        let imageUrl = 'https://d2zxqy2dinp2ov.cloudfront.net/fit-in/600x600/documents/'+imageName;
        return imageUrl;
    }else{
        // set default image
        return null;
    }
}
export const image400 = (url)=>{
    if(url && url.includes('s3')){
        let imageName = url.slice(url.lastIndexOf('/')+1, url.length);
        let imageUrl = 'https://d2zxqy2dinp2ov.cloudfront.net/fit-in/400x400/images/'+imageName;
        return imageUrl;
    }else{
        // set default image
        return null;
    }
}
export const image600 = (url)=>{
    if(url && url.includes('s3')){
        let imageName = url.slice(url.lastIndexOf('/')+1, url.length);
        let imageUrl = 'https://d2zxqy2dinp2ov.cloudfront.net/fit-in/600x600/images/'+imageName;
        return imageUrl;
    }else{
        // set default image
        return null;
    }
}