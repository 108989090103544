import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';

import { useEffect, useState, useRef } from 'react';

import Page from '../../../../../components/Page';
import LightboxModal from '../../../../../components/LightboxModal';
import { doc400 } from '../../../../userImage';
import DocPreview, { LoadingPreview } from './DocPreview';
import { fileUpload } from '../../../../../socket';
import { FDate } from 'src/constants/defaultValues';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({doc, isMobile, toast, editable, handleAddDoc, handleUpdateDoc,loading}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = useRef(null);

  let [provider, setProvider] = useState('');
  let [expiryDate, setExpiryDate] = useState('');
  let [policyNumber, setPolicyNumber] = useState('');
  let [file, setFile] = useState(null);
  let   [currentFile, setCurrentFile] = useState(null);
  let   [fileOpen, setFileOpen] = useState(false);
  let [fileLoading, setFileLoading] = useState(false);

  // let [cards, setCards] = useState([]);



  useEffect(()=>{
    if(doc && doc.id){
      if(doc.provider) setProvider(doc.provider)
      if(doc.expiry_date) setExpiryDate(doc.expiry_date)
      if(doc.policy_number) setPolicyNumber(doc.policy_number)
    }

  },[doc]);

  // const handleSubmit = e=>{

  //   if(cards.length>0){
  //     handleLicenses(cards);
  //   }else{
  //     toast('warning', 'Please add at least one card!')
  //   }
  // }

  const handleSubmit = e=>{

    if(expiryDate){
		if(expiryDate=='Invalid Date'){
			toast('warning', 'Please select a valid expiry date');
			return;
		}
      let insurance = {
          // provider,
          expiry_date: new Date(expiryDate).toISOString().split('T')[0],
          // policy_number: policyNumber
      };
      if(file){
        insurance.file = file;
      }

      if(doc && doc.id){
        handleUpdateDoc(insurance);
      }else{
        if(!insurance.file){
          toast('warning','Please upload the copy!');
          return;
        }
        handleAddDoc(insurance);
      }


    }else{
    toast('warning', 'Please fill all the fields!')
    }
  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }

  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }

  const handleViewDoc = doc=>{
    setCurrentFile(doc.file);
    setFileOpen(true);
  }

  const stackStyle = {};
  if(isMobile){
    stackStyle.maxHeight = '90vh';
    stackStyle.overflowY = 'auto';
  }

  return (
    <div style={stackStyle}>

      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
              p: 2,
              width: 1,
              position: 'relative',
              
            }}
            
          >

        <Grid container spacing={3} sx={{ mb: 1 }}>
          <Grid item xs={3} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/PLI-${isLight ? 'light' : 'dark'}.png`}

            />
          </Grid>
      
          <Grid item xs={9} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
             <Typography>Public Liability Insurance</Typography>
            {
                editable?(
                    <Box sx={{ mt:2 }} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker inputFormat="dd-MM-yyyy" 
                        label="Policy Expiry Date"
                        openTo="day"
                            views={['day','month','year']}
                        value={expiryDate}
                        onChange={(newValue) => {
                            setExpiryDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    </Box>
                ):(
                    <Typography>Expires: <span style={{ color: '#fda92d' }}>{FDate(expiryDate)}</span></Typography>
                )
            }
            </Box>
          </Grid>
        </Grid>


        {
          editable?(
            <Grid container spacing={1}>
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={handleSelededFile}
                // accept="image/*,video/*"
                name="profilePic"
                ref={fileInputRef}
                required
                style={{display:'none'}}
              />

              <Grid item xs={12} >
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  {
                    fileLoading?(
                      <LoadingPreview />
                    ):null
                  }
                  {
                    file?(
                      <DocPreview fileLoading={fileLoading} file={file} />
                    ):null
                  }

                  <Button onClick={handleFile} fullWidth size="large" sx={{ mt:2 }} variant="outlined">Upload a Photo of Your Policy</Button>
                </Box>
              </Grid>

            </Grid>
          ):null
        }



            
          </Paper>

      </Stack>
      {
            editable?(
                <LoadingButton fullWidth variant="contained" loading={loading} sx={{mt:2}} onClick={handleSubmit}> {handleUpdateDoc? 'Finished':'Add'} </LoadingButton>
            ):<DocPreview file={doc.file} />

      }


    </div>
  );
}
