// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import { imgae200, userAvatar } from './userImage';

// ----------------------------------------------------------------------

export default function MyAvatar({ user: userData, ...other }) {
  const { user: currentUser } = useAuth();
  
  let data = userData? userData:currentUser;
  return (
    <MAvatar
      src={data?.picture? imgae200(data.picture) : '/static/mock-images/avatars/avatar_1.jpg'}
      alt={data?.displayName}
      color={data?.photoURL ? 'default' : createAvatar(data?.displayName).color}
      {...other}
    >
      {createAvatar(data?.first_name+' '+data?.last_name).name}
    </MAvatar>
  );
}
