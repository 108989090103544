import { Icon } from '@iconify/react';

import LoadingButton from '@mui/lab/LoadingButton';

import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField, Grid, useMediaQuery, Modal } from '@material-ui/core';

// components
import Page from '../../components/Page';
import { useEffect, useState, useRef } from 'react';
import {arrangeDocIndexes, documentStyle} from '../../constants/defaultValues';
import StepperBottomRow from '../onboard/StepperBottomRow';
import useSettings from '../../hooks/useSettings';
import Paper from '@mui/material/Paper';
import SunEditor from '../../components/editor/suneditor';

import Logo from '../../components/LogoDoc';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {
  Page as DocPage,
  Text,
  View,
  Image,
  Document,
  usePDF,
  Font,
  StyleSheet
} from "@react-pdf/renderer";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));
const hoverButtonStyle = {
  position: 'absolute',
  paddingLeft: '7px',
  paddingRight: '7px',
  backgroundColor: 'lightgrey',
  borderRadius: '40px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  borderBottomLeftRadius: '0px',
  top:'-20px',
  right: 0
}

const modalStyleDesktop = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height:'100%',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  maxHeight: '100vh',
  overflowY: 'scroll',
  // p: 4,
  padding: '25px',
  paddingLeft: '30px',
  paddingRight: '30px',

};

const modalStyleMobile = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height:'100%',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  maxHeight: '100vh',
  overflowY: 'scroll',
  // p: 4,
  padding: '25px',
  paddingLeft: '30px',
  paddingRight: '30px',

};
const sectionStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height:'auto',
  maxHeight: '100vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '5px',
  // p: 4,
  padding: '25px',
  paddingLeft: '30px',
  paddingRight: '30px',

};
const paperStyleDesktop = {
  padding: '40px',
  position: 'relative',
  marginBottom: '15px',
  borderRadius: '0px',
  maxWidth: '794px',
  minHeight: '1123px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'justify',
  textJustify: 'inter-word'

};
const paperStyleMobile = {
  padding: '40px',
  position: 'relative',
  marginBottom: '15px',
  borderRadius: '0px',
  maxWidth: '794px',
  minHeight: '1123px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'justify',
  textJustify: 'inter-word'

};

// ----------------------------------------------------------------------

const footer = {
  fontSize: '10px',
  fontWeight: 'bold',
  textAlign:'center',
  bottom: '10px',
  position: 'absolute',
  width: '100%',

};


export default function VerifyCode({handleWirPlan: handleDoc, toast, jobdata, handleNext, loading, user, stepperHandlers}) {
  let [isOpen, setOpen] = useState(false);
  let [sectionOpen, setSectionOpen] = useState(false);
  const handleClose = e=> setOpen(false);
  const handleSectionClose = e=> setSectionOpen(false);
  var [currentSection, setCurrentSection] = useState(null);
  var [sections, setSections] = useState([]);
  let [hovered, setHovered] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [content, setContent] = useState('')
	const [title, setTitle] = useState('')

  var editor = null;
  const { themeMode } = useSettings();
	const config = {
		// readonly: false // all options from https://xdsoft.net/jodit/doc/
    style: {
      color: 'black',
      height: 'auto',
    }
	}

  const handleSectionOpen = e=>{
    setSectionOpen(true);
  }
  const handleOpen = e=>{
    if(jobdata && jobdata.id && jobdata.wirPlan && jobdata.wirPlan.length>0){
      handleNext();
    }else{
      setOpen(true);
    }
  }

  useEffect(()=>{

    if(jobdata && jobdata.id && jobdata.wirPlan && jobdata.wirPlan.length>0){
      setSections(jobdata.wirPlan);
    }else{
      if(jobdata && jobdata.id && jobdata.wir.length>0){
        setSections(jobdata.wir);
      }
    }

  },[]);


  const handleSubmit = e=>{

    if(sections.length>0){
      handleDoc({
        sections
      })
    }else{
      toast('warning','please enter at least one page to proceed!')
    }

  }
  const handleUpdateSection = e=>{
    let secs = [...sections];
    secs.map(sec=>{
      if(sec.id==currentSection.id){
        // sec.content = JSON.stringify(con);
        sec.content = content;
        sec.title = title;
      }
    });
    setSections([...secs]);
    setContent('');
    setTitle('');
    setCurrentSection(null);
    handleSectionClose();


  }
  const toggleEditButton = (index, show)=>{
    let hov = {...hovered};
    if(show){
        hov[index] = true;
    }else{
        delete hov[index];
    }
    setHovered({...hov});
  }

  const handleEdit = doc=>{
    console.log('edting doc: ',doc);
    setCurrentSection({...doc});
    handleSectionOpen();
    // setContent(JSON.parse(doc.content));
    setContent(doc.content);
    setTitle(doc.title);
  }

  return (
    <div>
       <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
         Your builder's "Workplace Induction Record"
       </Typography>
       <Typography sx={{ color: 'text.secondary', mb: 4 }}>
         WeSafe has generated a WIR plan for you please view it or edit it how
         you see fit then save it.
       </Typography>

        <Modal
          keepMounted
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={isMobile? modalStyleMobile: modalStyleDesktop}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Document
          </Typography>
          <div>
          <Document>
          {
            sections.length>0?(
                <DocPage size="A4" style={documentStyle.body} wrap>
                    <Paper
                    elevation={3} style={isMobile? paperStyleMobile: paperStyleDesktop}
                    className="editorPage"
                    >
                        {
                            arrangeDocIndexes(sections).map((doc, index)=>{
                                // let con = JSON.parse(doc.content);
                                // console.log('here: ',content);
                                return (
                                    <div
                                    style={{position: 'relative'}}
                                    onMouseEnter={()=>toggleEditButton(index, true)}
                                    onMouseLeave={()=>toggleEditButton(index, false)}
                                    >
                                      {
                                          doc.title && doc.title.length>0?(
                                            <Text style={documentStyle.subtitle}>
                                                {doc?.index} . {doc?.title}
                                            </Text>
                                          ):null
                                      }
                                        <Text style={documentStyle.text}>
                                            <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: doc?.content }} />
                                        </Text>

                                        {
                                          hovered[index]? (
                                              <div className="editDocButton" style={hoverButtonStyle}>
                                                  <IconButton onClick={()=>handleEdit(doc)} size="small" variant="contained" >
                                                      <EditIcon />
                                                  </IconButton>

                                              </div>

                                          ):null
                                        }
                                    </div>

                                )

                            })
                        }

                    </Paper>
                </DocPage>
            ):null
          }
          </Document>
            {/*
              sections.map((section,index)=>{
                // let con = jobdata.wirPlan.length>0? section.content: JSON.parse(section.content);
                let con = section.content;
                return (
                  <div>
                      <Paper
                          onMouseEnter={()=>toggleEditButton(index, true)}
                          onMouseLeave={()=>toggleEditButton(index, false)}
                          elevation={3} style={isMobile? paperStyleMobile: paperStyleDesktop}
                      >
                      <Logo />

                      <div className="safetyDoc" dangerouslySetInnerHTML={{ __html: con }} />

                      {
                          hovered[index]? (
                              <div className="editDocButton" style={{position:'absolute'}}>
                                  <IconButton onClick={()=>handleEdit(section)} size="small" variant="contained" >
                                      <EditIcon />
                                  </IconButton>
                              </div>

                          ):null
                      }
                      <div style={footer}>Copyright © 2022 WeSafe Pty Ltd document generated at: WeSafe.com.au</div>
                      </Paper>
                  </div>
                )
              })
            */}


          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end', width: '73%'}}>
            <LoadingButton
                    onClick={handleClose}
                    variant="outlined"
                    size="medium"
                    sx={{mt:2, mr: 2}}
                >
                    Cancel
                </LoadingButton>
            <LoadingButton
                    onClick={handleSubmit}
                    loading={loading}
                    variant="contained"
                    size="medium"
                    sx={{mt:2}}
                >
                    Accept Contract
                </LoadingButton>
          </div>
        </Box>

        {/* //////////////////////////////////// section update modal ////////////////////// */}
        </Modal>

        <Modal
          keepMounted
          open={sectionOpen}
          onClose={handleSectionClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={sectionStyle}>
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Page
            </Typography>
            <div>

              {
                  <div>
                      {
                          currentSection && sectionOpen?(
                            <>
                             <TextField
                                fullWidth
                                sx={{my:2}}
                                value={title}
                                onChange={e=> setTitle(e.target.value) }
                                label="Title"
                            />
                            <SunEditor setContents={content} onChange={(content)=>setContent(content)} />
                            </>

                          ):null
                      }
                  </div>
              }


            </div>

            <Button size="medium" onClick={handleUpdateSection}
              variant="contained" sx={{ mt: 2 }}>
                Update Section</Button>
          </div>

        </Box>

        </Modal>



        <StepperBottomRow {...stepperHandlers} handleSubmit={handleOpen} />
    </div>
  );
}