import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid  } from '@material-ui/core';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useEffect, useState, useRef } from 'react';
import LightboxModal from '../../../../../components/LightboxModal';


import Page from '../../../../../components/Page';
import { doc400 } from '../../../../userImage';
import DocPreview, { LoadingPreview } from './DocPreview';
import { fileUpload } from '../../../../../socket';
import { FDate } from 'src/constants/defaultValues';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));


// ----------------------------------------------------------------------

export default function VerifyCode({handleUpdateDoc, isMobile, toast, doc, editable, closeModal, handleAddDoc, loading}) {
    
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = useRef(null);

//   let [provider, setProvider] = useState('');
  let [expiryDate, setExpiryDate] = useState('');
//   let [policyNumber, setPolicyNumber] = useState('');
  let [file, setFile] = useState(null);
  let   [currentFile, setCurrentFile] = useState(null);
  let   [fileOpen, setFileOpen] = useState(false);
  let [fileLoading, setFileLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  // let [cards, setCards] = useState([]);
  
  

  useEffect(()=>{
    if(doc && doc.id){
      if(doc.expiry_date) setExpiryDate(doc.expiry_date)
    }
  },[]);

  // const handleSubmit = e=>{
    
  //   if(cards.length>0){
  //     handleLicenses(cards);
  //   }else{
  //     toast('warning', 'Please add at least one card!')
  //   }
  // }
  const handleViewDoc = doc=>{
    setCurrentFile(doc.file);
    setFileOpen(true);
  }

  const handleSubmit = e=>{
    
      if(expiryDate){
        
        if(!file){
          toast('warning', 'Please select a valid document copy!')
          return;
        }
        if(expiryDate=='Invalid Date'){
          toast('warning', 'Please select a valid expiry date');
          return;
        }

        let today = new Date() / 1000;
        let expDate = new Date(expiryDate) / 1000;

        if(expDate<=today){
          toast('warning', 'Invalid expiry date!');
          return;
        }

        let testtag = {
        //   provider, 
          expiry_date: new Date(expiryDate).toISOString().split('T')[0],
        //   policy_number: policyNumber, 
          updated: new Date(new Date().toUTCString()).toISOString().slice(0, 19).replace('T', ' '),
          expired: 0,
          file: file,
        };
        if(doc && doc.id){
          handleUpdateDoc(testtag);
        }else{
          handleAddDoc(testtag);
        }
        
        
      }
      else{
        toast('warning', 'Please fill all the fields!')
        // closeModal();
      }

  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }

  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }
  const stackStyle = {};
  if(isMobile){
    stackStyle.maxHeight = '90vh';
    stackStyle.overflowY = 'auto';
  }
    
  return (
    <div style={stackStyle}>
            <Typography variant="h4" sx={{ mb: 3 }}>
             {
               editable? "Add your Test & Tag document":"Test & Tag document"
             }
            </Typography>
      <Stack  direction={{ xs: 'column', md: 'row' }}>
          {/* <Paper
            sx={{
              p: 2,
              width: 1,
              position: 'relative',
              border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
            }}
            style={{paddingRight: '40px'}}
          > */}
        
        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          onChange={handleSelededFile}
          // accept="image/*,video/*"
          name="profilePic"
          ref={fileInputRef}
          required
          style={{display:'none'}}
        />
        {
          editable?(
            <Grid container spacing={1}>
              <Grid item xs={12} >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker inputFormat="dd-MM-yyyy"
                    label="Expiry Date"
                    openTo="day"
                    views={['day','month','year']}
                    value={expiryDate}
                    error={showError && !expiryDate}
                    onChange={(newValue) => {
                      setExpiryDate(newValue);
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </LocalizationProvider>

              </Grid>
            
              <Grid item xs={12} >
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  {
                      fileLoading?(
                        <LoadingPreview />
                      ):null
                    }
                    {
                      file?(
                        <DocPreview fileLoading={fileLoading} file={file} />
                      ):null
                    }
                  <Button onClick={handleFile} fullWidth size="large" variant="outlined">Upload Document</Button>
                </Box>
              </Grid>
  
          </Grid>
          ):(
            <Typography>Expires: <span style={{ color: '#fda92d' }}>{FDate(expiryDate)}</span></Typography>
          )
        }
           
            
         
          
       
      </Stack>
        {
          editable?(
            <LoadingButton fullWidth variant="contained" loading={loading} sx={{mt:2}}
              loadingPosition="start"
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
              >
            {handleUpdateDoc? 'Update': 'Add'}
          </LoadingButton>
          ):<DocPreview file={doc.file} />
        }

    </div>
  );
}
