import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import LightboxModal from '../../../../../components/LightboxModal';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

// components
import Page from '../../../../../components/Page';
import DocPreview, { LoadingPreview } from './DocPreview';
import { doc400 } from '../../../../userImage';
import { fileUpload } from '../../../../../socket';
import { FDate } from 'src/constants/defaultValues';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({doc, toast, isMobile, editable, handleUpdateDoc, handleAddDoc, loading}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const fileInputRef = React.useRef(null);

  let [type, setType] = useState('');
  let [expiryDate, setExpiryDate] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [licenseNumber, setLicenseNumber] = useState('');
  let [licenseClass, setLicenseClass] = useState('');

  let   [currentFile, setCurrentFile] = useState(null);
  let   [fileOpen, setFileOpen] = useState(false);
  let [file, setFile] = useState(null);
  let [fileLoading, setFileLoading] = useState(false);

  useEffect(()=>{

    if(doc && doc.id){
        setType(doc.type);
        setExpiryDate(doc.expiry_date);
        setCompanyName(doc.company_name);
        setLicenseNumber(doc.license_number);
        setLicenseClass(doc.license_class);
    }

  },[doc]);

  const handleSubmit = e=>{

      // if(type && expiryDate && companyName && licenseNumber && licenseClass){
        if(!expiryDate){
          toast('warning', 'Please choose a valid expiery date!');
          return;
        }
        if(expiryDate=='Invalid Date'){
          toast('warning', 'Please select a valid expiry date');
          return;
        }
        let newCard = {
          // type,
          expiry_date: new Date(expiryDate).toISOString().split('T')[0],
          // company_name: companyName,
          // license_number: licenseNumber,
          // license_class: licenseClass,
        }

        if(file){
          newCard.file = file;
        }

        if(doc && doc.id){
          handleUpdateDoc(newCard);
        }else{
          if(!newCard.file){
            toast('warning',"Please upload the copy of the card!");
            return;
          }
          handleAddDoc(newCard);
        }

        // setExpiryDate('');
        // setType('');
        // setLicenseClass('');
        // setCompanyName('');
        // setLicenseNumber('');
      // }else{
      //   toast('warning', 'Please fill all the fields!')
      // }
  }

  const handleSelededFile = e=>{
    let file=e.target.files[0];
    if(file  && file.type && (file.type.includes('image') || file.type.includes('pdf'))){
      setFileLoading(true);
      fileUpload(file, res=>{
        setFileLoading(false);
        setFile(res.location);
      })
    } else
      toast('warning', 'Invalid file!');
  }
  const handleFile=e=>{
    // console.log('refs: ',fileInputRef.current);
    fileInputRef.current.click();
  }


  const handleViewDoc = doc=>{
    setCurrentFile(doc.file);
    setFileOpen(true);
  }

  const stackStyle = {};
  if(isMobile){
    stackStyle.maxHeight = '90vh';
    stackStyle.overflowY = 'auto';
  }

  return (
    <div style={stackStyle}>

      <Stack spacing={2}  direction={{ xs: 'column', md: 'row' }}>
          <Paper
            sx={{
              p: 2,
              width: 1,
              position: 'relative',
            }}

          >

        <Grid container spacing={3} sx={{ mb: 1 }}>
          <Grid item xs={3} >
            <Box
                component="img"
                alt="icon"
                src={`/static/icons/QBCC-logo-${isLight ? 'light' : 'dark'}.png`}
            />
          </Grid>
          <Grid item xs={9} >
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
            </Box>
            <Typography> QBCC Licence</Typography>
            {
                editable?(
                    <Box sx={{ mt:2 }} >
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <DatePicker inputFormat="dd-MM-yyyy" 
                            label="Expiry Date"
                            openTo="day"
                            views={['day','month','year']}
                            value={expiryDate}
                            onChange={(newValue) => {
                            setExpiryDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                           
                        />
                    </LocalizationProvider>
                    </Box>
                ):(
                    <Typography>Expires: <span style={{ color: '#fda92d' }}> {FDate(expiryDate)}</span></Typography>
                )
            }
          </Grid>
        </Grid>


        <input
            type="file"
            className="custom-file-input"
            id="customFile"
            onChange={handleSelededFile}
            // accept="image/*,video/*"
            name="profilePic"
            ref={fileInputRef}
            required
            style={{display:'none'}}
          />
          {
            editable?(
              <Grid container spacing={1}>

              <Grid item xs={12} >
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  {
                    fileLoading?(
                      <LoadingPreview />
                    ):null
                  }
                  {
                    file?(
                      <DocPreview fileLoading={fileLoading} file={file} />
                    ):null
                  }
                  <Button onClick={handleFile} fullWidth size="large" sx={{mt:1}} variant="outlined">Upload PDF or Photo</Button>
                </Box>
              </Grid>

            </Grid>
            ):null
          }
          </Paper>


      </Stack>
      {
            editable?(
              <LoadingButton fullWidth variant="contained" loading={loading} sx={{mt:2}} onClick={handleSubmit}> {handleUpdateDoc? 'Finished':'Add'} </LoadingButton>
            ):<DocPreview file={doc.file} />
      }



    </div>
  );
}
