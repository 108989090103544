import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField, Select, InputLabel, FormControl, MenuItem, Tooltip, Grid } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import { useEffect, useState } from 'react';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@mui/icons-material/Help';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------
const RollHelpText = `
Use this option to select what role the user has in your company.
`;
export default function VerifyCode({handleNewInvite, handleEmployeeInvite, jobdata, toast, handleNext, updateToken}) {
  let [name, setName] = useState('');
  let [phone, setPhone] = useState('');

  // let [users, setUsers] = useState([]);

const [role, setRole] = useState('');

  const handleChange = (event) => {
    console.log(role);
    setRole(event.target.value);
  };

  useEffect(()=>{
    // console.log('got job data: ',jobdata);

  },[]);

  const handleSubmit = e=>{
    if(jobdata.isBuilder){
      if(jobdata.invites.length>=2){
        console.log('good to go!');
        handleNext();
      }else{
        toast('warning', 'Please add at least two employees!')
      }
    }else if(jobdata.isSubcontractor){
      // maybe optional
      handleNext();
      updateToken({
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor
      });
    }else{
      // skip this part...
      handleNext();
    }

    // let street = Street.trim();
    // let state = State.trim();
    // let city = City.trim();
    // let post_code = PostCode.trim();
    // if(street && state && city && post_code){
    //   handleNewInvite({street, state, city, post_code});
    // }
  }

  const addUser = e=>{
    let newUser = {
      name,
      phone: '+61'+ phone,
      roleId: role
    };
    handleNewInvite(newUser);
    // setUsers(newUsers);
    setPhone('');
    setName('');
    // if(jobdata.isBuilder){
    //   handleNewInvite(newUser);
    //   // setUsers(newUsers);
    //   setPhone('');
    //   setName('');
    // }else if(jobdata.isSubcontractor){
    //   newUser.roleId = 4;
    //   handleNewInvite(newUser);
    //   // setUsers(newUsers);
    //   setPhone('');
    //   setName('');
    // }else{
    //   // do nothing
    // }


  }
  return (
    <div>
            <Typography variant="h4" gutterBottom>
             Let's add your Employees
            </Typography>
            {
              jobdata.isBuilder?(
                <Typography sx={{ color: 'text.secondary', mb: 4 }}>Enter at least two employee below to proceed.</Typography>
              ):(null)
            }

            {
              jobdata && jobdata.id && jobdata.invites.length>0?(
                <Typography sx={{ color: 'text.secondary', mb: 4 }}>
                  {jobdata.invites.length} employee added
                </Typography>
              ):null
            }

            <TextField sx={{ mb: 2 }}
              fullWidth
              autoComplete="name"
              value={name}
              onChange={e=>setName(e.target.value)}
              type="text"
              label="Full Name"
            />


            <TextField sx={{ mb: 2 }}
              fullWidth
              autoComplete="phone"
              type="number"
              value={phone}
              onChange={e=>setPhone(e.target.value)}
              label="Mobile Number"
            />


        {
          jobdata && jobdata.isBuilder?(
            <Grid container spacing={1} >
              <Grid item xs={10} >
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={role}
                      label="Role"
                      onChange={handleChange}
                    >
                      <MenuItem value={5}>Office Admin</MenuItem>
                      {/* <MenuItem value={3}>Subcontractor</MenuItem> */}
                      <MenuItem value={6}>Supervisor</MenuItem>
                      {/* <MenuItem value={4}>Employee</MenuItem> */}
                    </Select>
                  </FormControl>

              </Grid>

              <Grid item xs={2} >
                  <Tooltip title={RollHelpText} placement="top-end" TransitionComponent={Zoom}>
                      <Box style={{ paddingTop:'13px', textAlign:'center' }}>
                        <HelpIcon />
                      </Box>
                    </Tooltip>
              </Grid>
            </Grid>
          ):(
            jobdata && jobdata.isSubcontractor?(
              <Grid container spacing={1} >
                <Grid item xs={10} >
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="demo-simple-select-label">Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Role"
                        onChange={handleChange}
                      >
                        <MenuItem value={5}>Office Admin</MenuItem>
                        <MenuItem value={4}>Employee</MenuItem>
                      </Select>
                    </FormControl>

                </Grid>

                <Grid item xs={2} >
                    <Tooltip title={RollHelpText} placement="top-end" TransitionComponent={Zoom}>
                        <Box style={{ paddingTop:'13px', textAlign:'center' }}>
                          <HelpIcon />
                        </Box>
                      </Tooltip>
                </Grid>
              </Grid>
            ):null

          )
        }




          {
            name && phone && role?(
              <Button size="small" onClick={addUser} variant="contained" sx={{ mt: 1 }}>Send Invite</Button>
            ):null
          }

          <Button fullWidth size="large" onClick={handleSubmit} variant="contained" sx={{ mt: 5 }}>Next</Button>
    </div>
  );
}
