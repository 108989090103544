import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import options2Fill from '@iconify/icons-eva/options-2-fill';
import { useSnackbar } from 'notistack5';

// material
import { Box, Backdrop, Paper, Tooltip, Divider, Modal, Button, Typography, Stack } from '@material-ui/core';
//
import { MIconButton } from '../@material-extend';
import StripeCardModal from './cardModal';
import MembershipModal from './membershipModal';
import { isTrialing, userRoles } from '../../constants/defaultValues';
import useAuth from '../../hooks/useAuth';
import { login, loginOk, loginOff, stripeCustomer, stripeCard, builderJobsites, jobsiteActivity, jobsiteDocs, startMembership } from '../../socket';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius:1,
  boxShadow: 24,
  p: 4,
};

var userData = null;

export default function StripeSetup() {
    const { user } = useAuth();
    let [cardModalOpen, toggleCardModal] = useState(false);
    let [membershipModalOpen, toggleMembershipModal] = useState(false);
    let [customer, setCustomer] = useState(null);
    let [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [checklistModalOpen, toggleChecklistModal] = useState(false);
    const [checked, setChecked] = useState(false);

    const toast = (type, message)=>{
      enqueueSnackbar(message, {
        variant: type,
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }



    const handleCardToggle = value=>{
      if(!value){
        if(customer && customer.sources.data.length>0){
          toggleCardModal(value);
        }
      }else{
        toggleCardModal(value);
      }

    }
    const handleMemberhipToggle = value=>{
      if(!value){
        if(customer && customer.subscriptions.data.length>0){
          toggleMembershipModal(value);
        }
      }else{
        toggleMembershipModal(value);
      }

    }
    const handleToken = token=>{
      console.log('customer: ',customer);
      console.log('token: ',token);
      // return;
      stripeCard(processStripeCard);
      stripeCard({token, startMembership: true});
    }
    // useEffect(() => {


    // }, [])
    useEffect(()=>{
      stripeCustomer(processStripeCustomer);
      // jobsiteDocs(processJobsiteDocs);
      // jobsiteActivity(processJobsiteActivity);
      startMembership(processStartMembership);
      login(processLogin);
      processLogin(loginOk());

      return ()=>{
        loginOff(processLogin);
        startMembership(processStartMembership, 'off');
        stripeCustomer(processStripeCustomer, 'off');
      }

    },[])

    useEffect(() => {

        userData = user;
        // console.log('got user: ',user);
        // if(user)
        // console.log('days passed till now for this user: ',daysPassTillNow(user.created));




    }, [user]);

    const processJobsites = res=>{
      console.log('got builder job sites: ',res);
    }
    const processJobsiteDocs = res=>{
      console.log('got job site docs: ',res);
    }
    const processJobsiteActivity = res=>{
      console.log('jobsiteActivity res: ',res);
    }

    const processLogin = (login)=>{
          if(login.auth){
            // jobsiteDocs({jobsiteId: 51});
          //  console.log('user here: ',userData);
          // if(userData && userData.id==339){
          //   jobsiteActivity({
          //     jobsiteId: 122,type: 'in'
          //   })
          // }
          
          if(userData && userData.id && userData.roleId!=userRoles.normal){
            let hideLoginModal = localStorage.getItem('hideLoginModal');
            let loginModalShown = localStorage.getItem('loginModalShown');
            if(loginModalShown!=1){
              if(!hideLoginModal || hideLoginModal==0){
                setChecked(true);
                toggleChecklistModal(true);
                localStorage.setItem('loginModalShown', 1);
              }
            }
          } 
            
            

            if(userData && userData.id && (userData.roleId==userRoles.builder || userData.roleId==userRoles.subcontractor)){
              console.log('calling customer');
              // stripeCustomer();
            }

          }
      }


    const processStartMembership = res=>{
      console.log('start membership res: ',res);
      setLoading(false);
      if(res.success){
        toggleMembershipModal(false);
        toast('success', 'Membership started successfully!');
      }else{
        toast('warning', res.msg || "Could not start your membership!");
      }
    }
    const processStripeCustomer = res=>{
      console.log('got customer: ',res);
      // console.log('user here: ',userData);
      // var trialing = isTrialing(userData.created);
      // trialing = false;
      var trialing = res.trialing;
      var cust = res.data;
      if(!trialing){
        setCustomer(cust);
        if(cust && cust.sources.data.length>0){
          // all good
          // console.log('im here1');
          toggleCardModal(false);
          // check for subscription
          // console.log('im here');
          let sub = cust?.subscriptions?.data[0];
          // sub.status = 'past_due';
          if(sub && sub.status=='active'){
            toggleMembershipModal(false);
          }else{
            console.log('opening the modal')
            toggleMembershipModal(true);
          }

        }else{
          if(userData && userData.roleId == userRoles.builder || userData.roleId == userRoles.subcontractor){
            toggleCardModal(true);
          }
        }
      }

    }

    const handleStartMembership = ()=>{
      console.log('starting membership');
      setLoading(true);
      startMembership();
    }


    const processStripeCard = res=>{
      // console.log('got card: ',res);
      setLoading(false);
      if(res.success){
        toast('success', 'Card added successfully');
      }else{
        toast('warning', res.msg);
      }
      // var customer = res.data;
      // setCustomer(customer);
      // if(customer && customer.sources && customer.sources.data && customer.sources.data.length>0){
      //   // all good
      //   toggleCardModal(false);
      // }else{
      //   toggleCardModal(true);
      // }
    }

    const handleCheckbox = (e)=>{
      setChecked(e.target.checked);
      if(e.target.checked){
        localStorage.setItem('hideLoginModal', 0);
      }else{
        localStorage.setItem('hideLoginModal', 1);
      }
    }

  return (
    <>
        <StripeCardModal  handleToken={handleToken} open={cardModalOpen} toggleModal={handleCardToggle} />
        <MembershipModal loading={loading} open={membershipModalOpen} handleStartMembership={handleStartMembership} toggleModal={handleMemberhipToggle} />
        <Modal
          open={checklistModalOpen}
          onClose={()=> toggleChecklistModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Important Notice!
            </Typography>
            
            <Typography sx={{my:2}}>
              WeSafe will add tasks to your to do list whenever there is something to be done. If you ignore your to do list WeSafe will send you reminder SMS’s and Emails every 2 days until the task has been complete. Please complete all tasks as they are added. 
            </Typography>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <FormGroup>
                <FormControlLabel control={<Checkbox onChange={handleCheckbox} checked={checked} />} label="Open every time on login" />
              </FormGroup>
              <Button sx={{ml:2}} variant="contained" onClick={()=> toggleChecklistModal(false)} type="button" color="primary" >
                I Understand
              </Button>
            </Box>
            
          </Box>
        </Modal>
    </>
  );
}
