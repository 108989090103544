import { styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const StripeFormStyle = styled('div')(({ theme }) => {
  const isLight = theme.palette.mode === 'light';
  const isRTL = theme.direction === 'rtl';

  return {
   

  };
});

export default StripeFormStyle;
