import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import StepperBottomRow from '../onboard/StepperBottomRow';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  // alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleConfirmCode, loading, stepperHandlers}) {
  return (
    <RootStyle>
      <VerifyCodeForm handleConfirmCode={handleConfirmCode} loading={loading} />
      <StepperBottomRow {...stepperHandlers} handleSubmit={()=>{}} />
    </RootStyle>
  );
}
