import { Icon } from '@iconify/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Stack, Card, Button, Collapse, TextField, IconButton, Typography, Grid,  } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import FormControlLabel from "@material-ui/core/FormControlLabel";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { Checkbox } from '@mui/material';

import { useEffect, useState } from 'react';

// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import EWPLicense from './EWP_License';
import WorkingHeightsLicense from './WorkingHeightsLicense';
import StepperBottomRow from '../onboard/StepperBottomRow';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleCards, toast, jobdata, handleExtras, handleNext, handleEWPCard, updateToken, handleWorkingHeightsCard, stepperHandlers}) {

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  let [currentSlide, setCurrentSlide] = useState(0);
  let [name, setName] = useState('');
  let [dob, setDob] = useState('');
  let [rtoNumber, setRtoNumber] = useState('');
  let [issueDate, setIssueDate] = useState('');
  let [cardNmber, setCardNumber] = useState('');

  let [cards, setCards] = useState([]);

  let [ewChecked, setew] = useState(false);
  let [heightsChecked, setHeightsChecked] = useState(false);


  useEffect(()=>{
    // if(jobdata && jobdata.id){
    //   setCards(jobdata.cards);
    // }

  },[]);

  const nextSlide = ()=>{
    setCurrentSlide(currentSlide+1);
  }

  const jumpToSelectedCard = ()=>{
    // let newCards = [];
    if(ewChecked){
      setCurrentSlide(currentSlide+1);
      return;
    }
    else if(heightsChecked){
      setCurrentSlide(currentSlide+2);
      return;
    }else{
		toast('warning', 'please select a card!');
	}

    // setCards(newCards);
    // setTimeout(()=>{
      // nextSlide();
    // },100)


  }

  const getAddedCardMessage = ()=>{
    if(jobdata.ewpCards.length>0 && jobdata.workingHeightsCards.length>0){
      return <Typography sx={{ color: 'text.secondary', mb: 2 }}>{jobdata.ewpCards.length} EWP and {jobdata.workingHeightsCards.length} WAH card already added.</Typography>
    }else if(jobdata.ewpCards.length>0){
      return <Typography sx={{ color: 'text.secondary', mb: 2 }}>{jobdata.ewpCards.length} EWP card already added.</Typography>
    }else if(jobdata.workingHeightsCards.length>0){
      return <Typography sx={{ color: 'text.secondary', mb: 2 }}>{jobdata.workingHeightsCards.length} WAH card already added.</Typography>
    }else{
      return null
    }

  }

  const handleFinishProcess = ()=>{

    if(jobdata.isEmployee || jobdata.isSupervisor){
      handleNext();
      // for employee its the last slide so just update the role id for employee
      updateToken({
        isBuilder: jobdata.isBuilder,
        isSubcontractor: jobdata.isSubcontractor,
        isEmployee: jobdata.isEmployee,
        isSupervisor: jobdata.isSupervisor
      });
    }

    // if(jobdata.isSubcontractor){
//       handleExtras()
//     }


  }
  // const handleSubmit = e=>{

  //   if(cards.length>0){
  //     handleCards(cards);
  //   }else{
  //     toast('warning', 'Please add at least one card!')
  //   }
  // }

  const addCard = e=>{
    if(name && dob && rtoNumber && issueDate && cardNmber){
      let newCards = [...cards, {
        name,
        dob: new Date(dob).toISOString().split('T')[0],
        rto_number: rtoNumber,
        issue_date: new Date(issueDate).toISOString().split('T')[0],
        card_number: cardNmber,
      }];
      setCards(newCards);
      setDob('');
      setName('');
      setCardNumber('');
      setRtoNumber('');
      setIssueDate('');
    }

  }
  // console.log('cards: ',cards);
  // console.log('currentslide: ',currentSlide)

  return (
    <div>
            {/* <Typography variant="h4" sx={{ mb: 3 }}>
             Add your white/blue card
            </Typography>
            {
              cards.length>0?(
                <Typography sx={{ color: 'text.secondary', mb: 4 }}>
                  {cards.length} card added
                </Typography>
              ):null
            } */}
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={230}
                totalSlides={5}
                currentSlide={currentSlide}
                // slide={1}
                touchEnabled={false}
                dragEnabled={false}
                >
                <Slider>

                  <Slide index={0}>
                    <Typography variant="h4" sx={{ mb: 3 }}>
                     Do you have any other the licenses to add?
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 2 }}>Click on the the licenses you wish to add or just click "Next" if your finished.</Typography>
                    {
                      getAddedCardMessage()
                    }

                    <Grid item xs={12} >
                      <FormControlLabel
                        style={{paddingLeft: '10px', width:'100%'}}
                        control={<Checkbox checked={ewChecked} onChange={e=>setew(e.target.checked)} />}
                        label="Elevated Work Platform (EWP)"
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <FormControlLabel
                        style={{paddingLeft: '10px',width: '100%'}}
                        control={<Checkbox checked={heightsChecked} onChange={e=>setHeightsChecked(e.target.checked)} />}
                        label="Working At Heights (WAH)"
                      />
                    </Grid>

                    <StepperBottomRow {...stepperHandlers} skipable={true} handleSkip={handleFinishProcess}  handleSubmit={jumpToSelectedCard} />
                    {/* <Box sx={{ pl: 2 , pr: 2}}>
                    {
                      ewChecked || heightsChecked?(
                        <Button style={{width: '100%'}} size="large" onClick={()=>{
                          jumpToSelectedCard();
                        }} variant="contained" sx={{ mt: 6 }}>Add Cards</Button>
                      ):(
                        <Button style={{width: '100%'}} size="large" onClick={handleFinishProcess} variant="contained" sx={{ mt: 6 }}>Next</Button>
                      )
                    }
                    </Box> */}


                  </Slide>

                  <Slide index={1}>
                    <EWPLicense toast={toast}

                    handleEWPCard={handleEWPCard}
                    jobdata={jobdata}
                    handleExtras={handleExtras}
                    updateToken={updateToken}
                    nextSlide={nextSlide}
                    handleNext={handleNext}
                    heightsChecked={heightsChecked} />
                  </Slide>
                  <Slide index={2}>
                    <WorkingHeightsLicense toast={toast}
                     jobdata={jobdata}
                     handleExtras={handleExtras}
                     nextSlide={nextSlide}
                     updateToken={updateToken}
                     handleWorkingHeightsCard={handleWorkingHeightsCard}
                     handleNext={handleNext} />
                  </Slide>
                  {/* {
                    cards.map((card,index)=>{
                      // console.log('index is: ',index);
                      <Slide index={index+1}>
                        {card({
                          nextSlide, jobdata,toast
                        })}
                      </Slide>
                    })
                  } */}
                </Slider>
            </CarouselProvider>





    </div>
  );
}
