// material
import { Container, Grid, Stack, Button, Dialog, DialogTitle, DialogContent, Box, Card, TextField } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { userRoles } from '../../constants/defaultValues';
import useSettings from '../../hooks/useSettings';
import DraftEditor from '../../components/editor/draft';
import SunEditor from '../../components/editor/suneditor';
import SaveIcon from '@mui/icons-material/Save';
import Paper from '@mui/material/Paper';
import { ptc } from '../../constants/contractTemplates/ptc';
import draftToHtml from 'draftjs-to-html';
import draftToMarkdown from 'draftjs-to-markdown';
import Logo from '../../components/LogoDoc';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { alpha, styled } from '@material-ui/core/styles';

// components
import Page from '../../components/Page';
import { addDocumentSection, getDocument, login, loginOk, loginOff, updateDocumentSection, deleteDocumentSection } from '../../socket';
import { EditorState, convertToRaw } from 'draft-js';

// ----------------------------------------------------------------------

const documentsStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '650px',
}

 const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2, 2),
    marginBottom: 7,
    cursor: 'pointer'
}));
const footer = {
    fontSize: '10px', 
    fontWeight: 'bold',
    textAlign:'center',
    bottom: '10px',
    position: 'absolute',
    width: '100%',
                    
 };

 const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '40px', 
    position: 'relative', 
    marginBottom: '15px', 
    borderRadius: '0px',
    maxWidth: '794px',
    minHeight: '1123px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.neutral
  }));
export default function HazardousSubstances({type}) {
  const { themeStretch } = useSettings();
//   var [content, setContent] = useState(ptc)
  var [content, setContent] = useState('')
  var [document, setDocument] = useState([])
  let [editorModalOpen, toggleEditorModal] = useState(false);
  var [currentSection, setCurrentSection] = useState(null);
  let [docType, setDocType] = useState('hazards');
  let [loading, setLoading] = useState(false);
//   let [showEditor, setShowEditor] = useState(false);
  let [hovered, setHovered] = useState({});
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(user && user.id && user.roleId!=userRoles.admin){
      navigate('/');
    }
    login(processLogin)
    processLogin(loginOk());
    getDocument(processDocument);
    addDocumentSection(processDocumentSection);
    updateDocumentSection(processUpdateDocumentSection);
    deleteDocumentSection(processDeleteDocSection);
    return ()=>{
        getDocument(processDocument, 'off');
        loginOff(processLogin);
    }
  }, []);

  const processLogin = (login)=>{
    //console.log("login",login)
    if(login.auth){
        getDocument({type: docType})
    }
}
//   useEffect(() => {
//     // console.log('gettin document for: ',type);
//     setLoading(false);
//     setDocument([]);
//     toggleEditorModal(false);
//     setDocType(type);

//     getDocument({type});
//   }, [type]);

  const processDocumentSection = res=>{
      console.log('addDocumentSection res: ',res);
      if(res.success) {
          setLoading(false);
          toggleEditorModal(false);
          setContent('');
      }
  }
  const processUpdateDocumentSection = res=>{
      console.log('updateDocumentSection res: ',res);
      if(res.success) {
          setLoading(false);
          toggleEditorModal(false);
          setContent('');
          setCurrentSection(null);
      }
  }

  const processDeleteDocSection = res=>{

  }

  const processDocument = data=>{
      console.log('got doc data: ',data);
      setDocument(data);
      updatePageBorders();
  }

  const handleContentChange = con=>{
      console.log('content: ',con);
      setContent(con);
  }

  const updatePageBorders = ()=>{
      setTimeout(()=>{
        var pages = window.document.querySelectorAll('.editorPage');
        if(pages && pages.length>0){
            for(let i=0; i<pages.length; i++){
                if(pages[i]){
                    if(pages[i].childNodes && pages[i].childNodes[1]){
                        pages[i].childNodes[1].style.border = '0px';
                    }
                }
            }
        }
      },80)
      
  }

  const handleSaveDocumentSection = ()=>{
      // save current page to db and clear the editor it will show the user that its a new page
      if(content && content.length>0){
        if(currentSection && currentSection.id){
            // here call the update section api
            updateDocumentSection({
                content,
                type: docType,
                id: currentSection.id
            })
        }else{
              console.log('content: ',content);
            addDocumentSection({
                content: JSON.stringify(content),
                type: docType
            });
        }
        setLoading(true);
      }
      
      
    
  }
  const handleNewPage = ()=>{
      toggleEditorModal(true);
  }
  const handleEdit = docSection=>{
    // console.log('so you want to edit: ',docSection);
    setCurrentSection({...docSection});
    setContent(JSON.parse(docSection.content));
    toggleEditorModal(true);
  }

  const handleDelete = docSection=>{
    // console.log('page to delete: ',docSection);
    deleteDocumentSection({
        type: docType,
        id: docSection.id
    });
  }

  const toggleEditButton = (index, show)=>{
    let hov = {...hovered};
    if(show){
        hov[index] = true;
    }else{
        delete hov[index];
    }
    setHovered({...hov});
  }

  const handleDocClick = doc=>{
    setCurrentSection(doc);
    setContent(doc.content);
    toggleEditorModal(true);
  }

 
//   console.log('hovered: ',hovered);

//   console.log('contetn: ',draftToHtml(ptc));
    // updatePageBorders();
  return (
    <Page title="WeSafe - Dashboard Admin">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <div className="pages" style={documentsStyle}> 
            <h2>Hazardous Substances</h2>
            <br/>
            {
                document && document.map(doc=>{

                    return (
                        <StyledCard onClick={()=>handleDocClick(doc)}>
                            <p>{doc.title}</p>
                        </StyledCard>
                    )
                })
                
            }
        </div>
        <br/>
        <Dialog fullWidth={true} maxWidth={'lg'}  open={editorModalOpen} onClose={()=> toggleEditorModal(false)} >
            <DialogTitle sx={{mb: 3}}>{currentSection? "Update Hazard":"New Hazard"}</DialogTitle>
            <DialogContent>
                {
                    editorModalOpen?(
                        <div className="editor">
                            <TextField sx={{mb: 1}} fullWidth id="outlined-basic" label="link" value={content} onChange={e=>setContent(e.target.value)} variant="outlined" />

                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                onClick={handleSaveDocumentSection}
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                >
                                    {
                                        currentSection?"Update":"Save"
                                    }
                            </LoadingButton>
                        </div>
                    ):null
                }       
            </DialogContent>
            
            {/* <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Send Invite</Button>
            </DialogActions> */}
        </Dialog> 

        {/* <Button onClick={handleNewPage}>new page</Button> */}

      </Container>
    </Page>
  );
}
