import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material

import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography, TextField, Grid, useMediaQuery } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import { useEffect, useState } from 'react';
import StepperBottomRow from '../onboard/StepperBottomRow';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode({handleAddress, jobdata, toast, stepperHandlers}) {
  let [Street, setStreet] = useState('');
  let [State, setState] = useState('');
  let [City, setCity] = useState('');
  let [PostCode, setPostCode] = useState('');
   const [showError, setShowError] = useState(false);
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(()=>{
    // console.log('got job data: ',jobdata);
    if(jobdata && jobdata.id){
      if(jobdata.street) setStreet(jobdata.street);
      if(jobdata.state) setState(jobdata.state);
      if(jobdata.city) setCity(jobdata.city);
      if(jobdata.post_code) setPostCode(jobdata.post_code);
    }
  },[jobdata]);

  const handleSubmit = e=>{
    let street = Street.trim();
    let state = State.trim();
    let city = City.trim();
    let post_code = PostCode.trim();
    if(street && state && city && post_code){
      handleAddress({street, state, city, post_code});
    }else{
		setShowError(true);
		toast('warning', 'Please fill all the required fields!');
	}
  }


  return (
    <div>
            <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
             What is your organisation's Address?
            </Typography>
      <Grid container spacing={upMd ? 1 : 1} sx={{ mb: 1 }}>
      <Grid item xs={8} md={8}>
            <TextField
              fullWidth
              autoComplete="pocname"
		      error={showError && !Street}
              type="text"
              value={Street}
              onChange={e=>setStreet(e.target.value)}
              label="Street"
            />
      </Grid>
      <Grid item xs={4} md={4}>
            <TextField
              fullWidth
              autoComplete="pocnumber"
              value={State}
			  error={showError && !State}
              onChange={e=>setState(e.target.value)}
              type="text"
              label="State"
            />
      </Grid>
      </Grid>
      <Grid container spacing={upMd ? 1 : 1} sx={{ mb: 2 }}>
      <Grid item xs={8} md={8}>
            <TextField
              fullWidth
              autoComplete="pocnumber"
              type="text"
			  error={showError && !City}
              value={City}
              onChange={e=>setCity(e.target.value)}
              label="City"
            />
      </Grid>
      <Grid item xs={4} md={4}>
            <TextField
              fullWidth
              value={PostCode}
			  error={showError && !PostCode}
              onChange={e=>setPostCode(e.target.value)}
              autoComplete="pocnumber"
              type="text"
              label="Post Code"
            />
      </Grid>
      </Grid>
      <StepperBottomRow {...stepperHandlers} handleSubmit={handleSubmit} />
    </div>
  );
}
