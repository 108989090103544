import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import { Container, Grid, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack5';
import { MIconButton } from '../../components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import StripeFormStyle from './formStyle';
import { stripePublicKey } from '../../constants/defaultValues';
let settings = localStorage.getItem('settings');

let themeSetting = settings? JSON.parse(settings) : {};

// let themeSetting = JSON.parse(localStorage.getItem('settings'));
const cardStyle = {
    base: {
      iconColor: '#c4f0ff',
      color: themeSetting && themeSetting.themeMode=='light'? 'black': 'white',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87BBFD',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
}


const CheckoutForm = ({handleToken, hideButton}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let [loading, setLoading] = useState(false);

  const toast = (type, message)=>{
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (elements == null) {
      return;
    }

    // console.log('number: ',elements.getElement(CardNumberElement));
    // return;
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
        // card: {
        //     number: elements.getElement(CardNumberElement),
        //     exp_month: 11,
        //     exp_year: 2022,
        //     cvc: elements.getElement(CardCvcElement),
        // }
    });
    

    if(error){
        toast('warning', error.message);
        setLoading(false);
    }else{
        handleToken(paymentMethod.id);
        setTimeout(() => {
          setLoading(false);
        }, 4000);
        // console.log('good to go : ',paymentMethod);
    }

    

    // console.log('error: ',error);
    // console.log('payment method: ', paymentMethod);

  };

  return (
    <form onSubmit={handleSubmit}>
      <StripeFormStyle>
        <CardElement options={{style: cardStyle}} />
      </StripeFormStyle>
      
      <br/>
      {/* <CardNumberElement />
      <CardExpiryElement />
      <CardCvcElement /> */}
      <Button type="submit" className="addCardButton" style={{display: hideButton?'none':'block'}} disabled={(!stripe || !elements) || loading}>
        Submit
      </Button>
    </form>
  );
};

const stripePromise = loadStripe(stripePublicKey);

const CardSection = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default CardSection;